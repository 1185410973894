import React, { Component } from 'react';

interface Props {
    full?: boolean;
    innerContent: string;
}

interface State {}

export default class Table extends Component<Props, State> {
    private constructor(props: Props) {
        super(props);
        this.state = {};
    }

    public render(): JSX.Element {
        const { full, innerContent }: Props = this.props;

        let openingClasses = '';

        if (full) {
            openingClasses = 'column medium-20 small-full';
        } else {
            openingClasses = 'column large-12 medium-16 small-full';
        }

        return (
            <div className="row center">
                <div className={openingClasses}>
                    <div dangerouslySetInnerHTML={{ __html: innerContent }} />
                </div>
            </div>
        );
    }
}
