import { useEffect, useState } from 'react';
import axios, { AxiosResponse } from 'axios';

const baseURL = process.env.REACT_APP_API_URL;

interface I18nProperty {
    rendered: string;
}

interface FeaturedImage {
    url: string | null;
    width: number | null;
    height: number | null;
    alt: string;
}

export interface CategoryName {
    name: string;
    slug: string;
}

export interface SparsePost {
    id: number;
    title: I18nProperty;
    date: string;
    slug: string;
    excerpt: I18nProperty;
    categories: number[];
    featured_image_large: FeaturedImage;
    category_name: CategoryName[];
}

function getUrl(language?: string, topic?: number): string {
    const params: URLSearchParams = new URLSearchParams();

    params.append('per_page', '42');
    params.append('_fields', 'id,title,date,slug,excerpt,categories,category_name,featured_image_large');
    if (typeof topic !== 'undefined') {
        params.append('topic', `${topic}`);
    }

    return `${baseURL}/wp/v2/posts?${params}&lang=${language}`;
}

export default function usePosts(language?: string, topic?: number): SparsePost[] {
    const [posts, setPosts]: [SparsePost[], Function] = useState([]);

    useEffect(
        function fetchPosts(): void {
            axios.get(getUrl(language, topic)).then(function parseResults({ data }: AxiosResponse): void {
                setPosts(data as SparsePost[]);
            });
        },
        [topic, language],
    );

    return posts;
}
