import React, { Component, Fragment } from 'react';
import { NavLink, Link } from 'react-router-dom';
import axios from 'axios';
import { FormattedMessage } from 'react-intl';
import * as Sentry from '@sentry/browser';
import HamburgerMenu from './HamburgerMenu';
import Search from './Search/Search';
import { refactorUrl } from '../utils';
import { fbq } from 'react-facebook-pixel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown } from '@fortawesome/free-solid-svg-icons';
import MoveLogo from '../Assets/images/move-logo.svg';
//Interfaces
import { Menu } from '../types/menu';
// Top notification
// import TopNotification from './TopNotification';

interface Props {
    menu: any[];
    size?: string;
    language: string;
    updateLanguage: Function;
    type?: string;
    page?: {
        title: {
            rendered: string;
        };
        id: number;
        link: string;
        template?: string;
        translations?: any;
    };
}

interface State {
    translation: string;
    parent: string;
    hasHash: boolean;
}

export default class Header extends Component<Props, State> {
    private constructor(props: Props, context: any) {
        super(props, context);
        this.state = {
            translation: '',
            parent: '',
            hasHash: false,
        };
    }

    private getTranslation = async (): Promise<void> => {
        const { page, language, type } = this.props;

        const baseURL = process.env.REACT_APP_API_URL;
        if (page) {
            if (page.translations) {
                const translationID = language !== 'en' ? page.translations.en : page.translations.nl;
                if (translationID) {
                    let apiURL;
                    if (type === 'page') {
                        apiURL = `${baseURL}/wp/v2/pages/${translationID}`;
                    } else {
                        apiURL = `${baseURL}/wp/v2/posts/${translationID}`;
                    }
                    try {
                        let res = await axios.get(apiURL);
                        let { data } = await res;
                        const newsTranslations = language === 'en' ? 'nieuws' : 'news';

                        this.setState({
                            translation: data.slug,
                            parent: (type === 'post' ? newsTranslations : '') + data.parent_page_slug,
                        });
                    } catch (error) {
                        Sentry.setExtra('errorData', error);
                        Sentry.captureException(error);
                    }
                }
            }
        }
    };

    private setMenuClassOnScroll = (): void => {
        const body = document.body;
        let lastScroll = 0;

        window.addEventListener('scroll', (): void => {
            const currentScroll = window.pageYOffset;
            if (currentScroll <= 0) {
                body.classList.remove('scroll-down');
                return;
            }

            if (currentScroll > lastScroll && !body.classList.contains('scroll-down')) {
                // down
                body.classList.add('scroll-down');
            }
            lastScroll = currentScroll;
        });
    };

    private updateDimensions = (): void => {
        let isTabletOrMobile = null;
        window.innerWidth <= 990 ? (isTabletOrMobile = true) : (isTabletOrMobile = false);
        const navigation: HTMLElement | null = document.querySelector('.navigation');
        const banner: HTMLElement | null = document.querySelector('.top-notification');
        const contentPageElement: HTMLElement | null = document.querySelector('.content-page');
        let headerHeight = 0;
        let isVideoPage = null;
        window.location.pathname.indexOf('fast') > -1 || window.location.pathname.indexOf('mobility') > -1
            ? (isVideoPage = true)
            : (isVideoPage = false);

        if (navigation !== null && banner !== null && !isVideoPage) {
            headerHeight = navigation.offsetHeight + banner.offsetHeight;
            if (headerHeight !== null && contentPageElement !== null) {
                contentPageElement.style.setProperty('padding-top', `${headerHeight + (isTabletOrMobile ? 25 : 50)}px`);
            }
        } else if (navigation !== null && !isVideoPage) {
            headerHeight = navigation.offsetHeight;
            if (headerHeight !== null && contentPageElement !== null) {
                contentPageElement.style.setProperty('padding-top', `${headerHeight + (isTabletOrMobile ? 25 : 50)}px`);
            }
        }
    };

    private setVhVar(): void {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    public componentDidMount = async (): Promise<void> => {
        this.getTranslation();
        this.setMenuClassOnScroll();

        this.setVhVar();
        this.updateDimensions();

        window.addEventListener('resize', (): void => {
            this.setVhVar();
            this.updateDimensions();
        });
    };

    public handleJumpScroll = (event: any): any => {
        const { hasHash } = this.state;
        const link = event.target.href;

        if (link.indexOf('#') > -1) {
            this.setState({ hasHash: true });
        }
        if (hasHash) {
            event.preventDefault();
            window.location = link.replace('#', '#/');
        }
    };

    public render(): JSX.Element {
        const { parent } = this.state;
        const { menu } = this.props;

        if (!menu) {
            return <></>;
        }

        return (
            <div className={`header-container`}>
                <header id="header">
                    <Fragment>
                        <nav className={'navigation'} id="navigation">
                            <Link className="logo" to="/">
                                <img src={MoveLogo} alt="Logo Move" width="105" />
                            </Link>
                            <ul className="hide-for-medium-down navigation_dropdown_container">
                                {menu.map(
                                    (item: Menu): JSX.Element => {
                                        return (
                                            <Fragment key={item.id}>
                                                {item.children === undefined && (
                                                    <li>
                                                        <NavLink to={refactorUrl(item.url)}>{`${item.title}`}</NavLink>
                                                    </li>
                                                )}

                                                {item.children !== undefined && (
                                                    <li className="submenu">
                                                        <span>
                                                            <NavLink to={refactorUrl(item.url)}>
                                                                {`${item.title}`}
                                                                <FontAwesomeIcon icon={faSortDown} />
                                                            </NavLink>
                                                        </span>
                                                        <ul>
                                                            {item.children.map(
                                                                (child: Menu): JSX.Element => {
                                                                    return (
                                                                        <li key={child.id}>
                                                                            <NavLink
                                                                                to={refactorUrl(child.url)}
                                                                                onClick={this.handleJumpScroll}
                                                                            >{`${child.title}`}</NavLink>
                                                                        </li>
                                                                    );
                                                                },
                                                            )}
                                                        </ul>
                                                    </li>
                                                )}
                                            </Fragment>
                                        );
                                    },
                                )}
                            </ul>

                            <div id="language-switch-header" className="language-switch">
                                {this.props.language === 'nl' ? (
                                    <ul className="language-switch-list">
                                        <li>
                                            <a
                                                href={window.location.href}
                                                className="active"
                                                onClick={(): void => {
                                                    this.props.updateLanguage('nl');
                                                }}
                                            >
                                                NL
                                            </a>
                                        </li>
                                        <li>/</li>
                                        <li>
                                            <Link
                                                id="language-symbol"
                                                to={`/en/${parent ? parent + '/' : ''}${
                                                    this.state.translation ? this.state.translation + '/' : ''
                                                }`}
                                                onClick={(): void => {
                                                    this.props.updateLanguage(
                                                        'en',
                                                        `/en/${parent ? parent + '/' : ''}${
                                                            this.state.translation ? this.state.translation + '/' : ''
                                                        }`,
                                                    );
                                                }}
                                            >
                                                EN
                                            </Link>
                                        </li>
                                    </ul>
                                ) : (
                                    <ul className="language-switch-list">
                                        <li>
                                            <Link
                                                to={`/${parent ? parent + '/' : ''}${
                                                    this.state.translation ? this.state.translation + '/' : ''
                                                }`}
                                                className={this.props.language === 'nl' ? 'active' : ''}
                                                onClick={(): void => {
                                                    this.props.updateLanguage(
                                                        'nl',
                                                        `/${parent ? parent + '/' : ''}${
                                                            this.state.translation ? this.state.translation + '/' : ''
                                                        }`,
                                                    );
                                                }}
                                            >
                                                NL
                                            </Link>
                                        </li>
                                        <li>/</li>
                                        <li>
                                            <a
                                                id="language-symbol"
                                                href={window.location.href}
                                                className="active"
                                                onClick={(): void => {
                                                    this.props.updateLanguage('en');
                                                }}
                                            >
                                                EN
                                            </a>
                                        </li>
                                    </ul>
                                )}
                                <Search language={this.props.language} location="header" />
                                {this.props.language === 'nl' ? (
                                    <a
                                        className="button hide-for-medium-down mobility__btn"
                                        href="https://tickets.move.amsterdam/nl/tickets"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        onClick={(): void => {
                                            fbq('track', 'ButtonBoekTickets');  
                                        }}
                                    >
                                        <FormattedMessage id="Header.OrderTickets" defaultMessage="Order Tickets" />
                                    </a>
                                ) : (
                                    <a
                                        className="button hide-for-medium-down mobility__btn"
                                        href="https://tickets.move.amsterdam/en/tickets"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        onClick={(): void => {
                                            fbq('track', 'ButtonBoekTickets');  
                                        }}
                                    >
                                        <FormattedMessage id="Header.OrderTickets" defaultMessage="Order Tickets" />
                                    </a>
                                )}
                            </div>
                        </nav>
                    </Fragment>
                </header>
                <HamburgerMenu
                    language={this.props.language}
                    updateLanguage={this.props.updateLanguage}
                    menu={menu}
                    stateLanguage={this.state.translation}
                    parent={parent}
                />
                {/* <TopNotification /> */}
            </div>
        );
    }
}
