import { useEffect, useState } from 'react';
import axios from 'axios';

const baseURL = process.env.REACT_APP_API_URL;

interface I18nProperty {
    rendered: string;
}

export interface Hotspot {
    location: string;
    icon: boolean;
    title: string;
    description: string;
    link: string;
    image: string;
    show_hotspot: boolean;
    show_hotspot_mobile: boolean;
}

export interface Slide {
    slider_image: string;
    slider_link: string;
    slider_subtitle: string;
    slider_subtitle2: string;
    slider_title: string;
}

export interface VideoSlide {
    title: string;
    video: string;
}

export interface Highlight {
    highlight_category: string;
    highlight_image: string;
    highlight_link: string;
    highlight_title: string;
}

export interface FrontPage {
    id: number;
    title: I18nProperty;
    date: string;
    slug: string;
    translations: {
        [index: string]: number;
    };
    acf: {
        featured_image: string;
        whats_next: {
            title: string;
            description: string;
        };
        hotspots: Hotspot[];
        slider: Slide[];
        practical_information: {
            image_1: string;
            image_2: string;
            textblock_1: string;
            textblock_2: string;
            title_1: string;
            title_2: string;
        };
        instagram: {
            description: string;
            title: string;
        };
        inside_move: {
            highlights: {
                highlight: Highlight[];
            };
            link_url: string;
            textblock_1: string;
            textblock_2: string;
            title: string;
        };
    };
}

const baseUrl = `${baseURL}/wp/v2/frontpage`;
let cache: FrontPage | null = null;

async function fetchTranslatedHomepage(language: string): Promise<FrontPage> {
    const { data: basePage }: { data: FrontPage } = await axios.get(baseUrl);
    if (basePage.id !== basePage.translations[language]) {
        const translationUrl = `${baseURL}/wp/v2/pages/${basePage.translations[language]}`;
        const { data: translatedPage }: { data: FrontPage } = await axios.get(translationUrl);
        return translatedPage;
    }
    return basePage;
}

export default function useFrontPage(language: string): FrontPage | null {
    const [page, setPage] = useState<FrontPage | null>(cache); //Show stale result, then fetch fresh content

    useEffect(
        function fetchPage(): void {
            fetchTranslatedHomepage(language)
                .then((result: FrontPage): FrontPage => (cache = result))
                .then(setPage);
        },
        [language],
    );

    return page;
}
