import React, { Fragment, Component } from 'react';
import axios from 'axios';
import * as Sentry from '@sentry/browser';
import { gsap } from 'gsap';
import scrollToPlugin from 'gsap/ScrollToPlugin';
require('dotenv').config();

gsap.registerPlugin(scrollToPlugin);

interface Props {
    language: string;
}

interface State {
    categories: any[];
    posts: any[];
    menu: string;
    prevMenu: string;
}

export default class Faq extends Component<Props, State> {
    private constructor(props: Props) {
        super(props);
        this.state = {
            categories: [],
            posts: [],
            menu: '',
            prevMenu: '',
        };
    }

    private getFaqCategories = async (): Promise<void> => {
        const baseURL = process.env.REACT_APP_API_URL;
        const apiURL = `${baseURL}/wp/v2/move-faq-categories?lang=${this.props.language}&orderby=name&order=asc`;
        try {
            let res = await axios.get(apiURL);
            let { data } = await res;

            // Add each category to array
            this.setState({
                categories: data,
            });
        } catch (error) {
            Sentry.setExtra('errorData', error);
            Sentry.captureException(error);
        }
    };

    private getFaqPosts = async (): Promise<void> => {
        const $this = this;
        const categories = this.state.categories;
        let dataCollection = [];
        for (let i = 0; i < categories.length; i++) {
            const baseURL = process.env.REACT_APP_API_URL;
            const apiURL = `${baseURL}/wp/v2/move-faq?move-faq-categories=${categories[i].id}&lang=${$this.props.language}&orderby=menu_order&per_page=100`;

            try {
                let res = await axios.get(apiURL);
                let { data } = await res;

                let collection = {
                    category: categories[i].name,
                    posts: data,
                };

                dataCollection.push(collection);
            } catch (error) {
                Sentry.setExtra('errorData', error);
                Sentry.captureException(error);
            }

            this.setState({
                posts: dataCollection,
            });
        }
    };

    public componentDidMount = async (): Promise<void> => {
        await this.getFaqCategories();
        await this.getFaqPosts();
    };

    public componentDidUpdate = (): void => {
        if (this.state.menu !== this.state.prevMenu) {
            gsap.timeline({onComplete: () => {}})
                .to(`${this.state.prevMenu} .faq-content`, { duration: 0.5, autoAlpha: 0, maxHeight: 0 })
                .to(`${this.state.menu} .faq-content`, { duration: 0.5, autoAlpha: 1, maxHeight: 1500 });
        } else if (this.state.menu === this.state.prevMenu) {
            gsap.timeline({onComplete: () => {}})
                .to(`${this.state.menu} .faq-content`, { duration: 0.5, autoAlpha: 0, maxHeight: 0 });
        }
    };

    public toggleVisible = (event: any): void => {
        this.setState({ prevMenu: this.state.menu });
        this.setState({ menu: `#${event.currentTarget.parentElement.id}` });
        if (this.state.menu === this.state.prevMenu) {
            this.setState({ prevMenu: '' });
        }
    };

    private createFaq(): JSX.Element {
        return (
            <Fragment>
                <div className="row center">
                    <div className="column medium-20 small-full">
                        <div className="faq-container">
                            {this.state.posts.map(
                                (item: any, index: number): JSX.Element => (
                                    <Fragment key={index}>
                                        <h4>{item.category}</h4>
                                        <ul>
                                            {item.posts.map(
                                                (c: any, i: number): JSX.Element => (
                                                    <li id={c.slug} className="faq-inner" key={i}>
                                                        <i
                                                            className={`toggle-btn ${
                                                                this.state.prevMenu !== this.state.menu &&
                                                                this.state.menu === `#${c.slug}`
                                                                    ? 'open'
                                                                    : ''
                                                            }`}
                                                            onClick={this.toggleVisible}
                                                        ></i>
                                                        <h2 dangerouslySetInnerHTML={{ __html: c.title.rendered }} />
                                                        <div
                                                            className="faq-content"
                                                            dangerouslySetInnerHTML={{ __html: c.content.rendered }}
                                                        />
                                                    </li>
                                                ),
                                            )}
                                        </ul>
                                    </Fragment>
                                ),
                            )}
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }

    public render(): JSX.Element {
        const faq = this.createFaq();
        return faq;
    }
}
