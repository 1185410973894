import React from 'react';
import GutenbergBlocks from '../GutenbergBlocks';

interface Props {
    innerContent: any;
    language: string;
    animImgs?: any;
}

export default function Group(props: Props): JSX.Element {
    const { innerContent, language, animImgs } = props;

    const bgColor = innerContent.attrs.style ? innerContent.attrs.style.color.background : 'transparent';
    const textColor = innerContent.attrs.textColor;
    const className = innerContent.attrs.className ? innerContent.attrs.className : '';
    const classes = `gutenberg-group ${className}`;

    return (
        <div className={classes} style={{ backgroundColor: bgColor, color: textColor }}>
            <GutenbergBlocks animImgs={animImgs} language={language} blocks={innerContent.innerBlocks} full={true} />
        </div>
    );
}
