import React from 'react';
import GutenbergBlocks from '../Content/GutenbergBlocks';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import SliderMobility from '../SliderMobility';

interface MobilityPageProps {
    title: string;
    excerpt: string;
    featuredImage: any;
    blocks: any;
    content: any;
    acf?: any;
    language: string;
    id: string;
}

export default function MobilityPage(props: MobilityPageProps): JSX.Element {
    const { blocks, acf, language } = props;

    return (
        <>
            <div className="row width-100 center">
                <div className="mobility__header">
                    <div className="mobility_header-content">
                        <img className="mobility__logo" src={acf.heading_image} alt="Logo" />
                        <h2 className="mobility__heading">{acf.heading_text}</h2>
                        <a href={acf.heading_btn_url} className="wp-block-button__link mobility__btn">
                            {acf.heading_btn_text}
                        </a>
                    </div>

                    <div className="vimeo-wrapper">
                        <iframe
                            src={`https://player.vimeo.com/video/${acf.heading_background}?background=1&autoplay=1&loop=1&byline=0&title=0&muted=1`}
                            frameBorder="0"
                            allowFullScreen
                            title={acf.heading_background}
                        />
                    </div>
                </div>
            </div>

            {/* <div className="row  width-100 center mobility__subtitle__wrap">
                <div className="column large-18 medium-20 small-full">
                    <div className="row center">
                        <div className="column small-full page">
                            {acf.subtitle1 && (
                                <p className="exhibition__subtitle mobility__subtitle">{acf.subtitle1}</p>
                            )}
                            <br />
                            {acf.subtitle2 && (
                                <p className="exhibition__subtitle mobility__subtitle">{acf.subtitle2}</p>
                            )}{' '}
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="row width-100 center">
                <div className="column medium-20 small-full">
                    <div className="row center">
                        <div className="column small-full page">
                            <GutenbergBlocks
                                language={language}
                                blocks={blocks}
                                full={true}
                                animImgs={acf.animation_images}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="row width-100 center mobility__videos">
                <div className="column medium-20 small-full">
                    <div className="row center">
                        <div className="column small-full page">
                            <h2 dangerouslySetInnerHTML={{ __html: acf.video_heading }} />
                            <SliderMobility content={acf.videos} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
