import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

interface ParallaxProps {
    image: string;
    alt: string;
    className: string;
}

export default function NewParallax(props: ParallaxProps): JSX.Element {
    const { image, alt, className } = props;

    return (
        <div className={`parallax__wrapper ${className}`}>
            <LazyLoadImage alt={alt} effect="opacity" src={image} />
        </div>
    );
}
