import React from 'react';
import Lottie from 'react-lottie';

interface Props {
    innerContent: string;
}

export default function Html(props: Props): JSX.Element {
    const { innerContent } = props;

    return (
        <div className="lottie">
            <Lottie
                options={{
                    animationData: JSON.parse(innerContent),
                    loop: true,
                    autoplay: true,
                }}
                height={180}
            />
        </div>
    );
}
