import React, { Fragment, Component } from 'react';
import 'babel-polyfill';
import axios from 'axios';
import { IntlProvider, addLocaleData } from 'react-intl';
import nl from 'react-intl/locale-data/nl';
import { withCookies, Cookies } from 'react-cookie';
import * as Sentry from '@sentry/browser';

//Router
import { Router, Route, Switch, RouteComponentProps, Redirect } from 'react-router-dom';

import { Helmet } from 'react-helmet';

// Pages
import ContentPage from './Components/ContentPage';
import ContentPost from './Components/ContentPost';
import HomePage from './Components/HomePage';
import NoMatchPage from './Components/NoMatchPage';
import SearchResults from './Components/Search/SearchResults';

//Sitemap
import SiteMap from './Components/SiteMap';

//Interfaces
import { Page } from './types/page';

//History
import history from './History';
import { Menu } from './types/menu';

//Internalization
import localeData from './translations/data.json';
addLocaleData([...nl]);

require('dotenv').config();

interface AppProps {
    cookies: Cookies;
}

interface State {
    pages: Page[];
    posts: Page[];
    ip: string;
    cookiesModalVisible: boolean;
    cookiesValue: any;
    language: string;
    menu: Menu[];
    acceptStatisticsCookies: boolean;
    acceptMarketingCookies: boolean;
}

class App extends Component<AppProps, State> {
    public constructor(props: AppProps) {
        super(props);

        // Set the current language.
        const pathArray = window.location.pathname.split('/');
        let language = 'nl';
        if (pathArray[1] === 'en') {
            language = 'en';
        }

        const hasSetCookiePreferences = this.props.cookies.get('hasSetCookiePreferences');
        let showCookieModal = false;

        let cookiesValue = '';

        if (typeof hasSetCookiePreferences === 'undefined') {
            showCookieModal = true;
        }

        if (window.location.search === '?cookie') {
            if (language === 'en') {
                history.push(`/en/#cookie`);
            } else {
                history.push(`/#cookie`);
            }
        }

        this.state = {
            pages: [],
            posts: [],
            ip: '',
            cookiesModalVisible: showCookieModal,
            cookiesValue: cookiesValue,
            language: language,
            menu: [],
            acceptStatisticsCookies: true,
            acceptMarketingCookies: false,
        };
    }

    private getMenu = async (): Promise<void> => {
        const baseURL = process.env.REACT_APP_API_URL;
        const menuID = this.state.language === 'nl' ? 27 : 28;

        const apiURL = `${baseURL}/wp-api-menus/v2/menus/${menuID}`;
        try {
            let res = await axios.get(apiURL);
            let { data } = await res;
            this.setState({ menu: data.items });
        } catch (error) {
            Sentry.setExtra('errorData', error);
            Sentry.captureException(error);
        }
    };

    private getAllPages = async (): Promise<void> => {
        const baseURL = process.env.REACT_APP_API_URL;
        const apiURL = `${baseURL}/wp/v2/pages?per_page=60&_fields=id,slug,parent_page_slug,link,title,template,translations`;
        try {
            let res = await axios.get(apiURL);
            const pages = parseInt(res.headers['x-wp-totalpages']);
            const totalCount = parseInt(res.headers['x-wp-total']);

            this.loadAll('pages', apiURL, pages, totalCount);
        } catch (error) {
            Sentry.setExtra('errorData', error);
            Sentry.captureException(error);
        }
    };

    private getAllPosts = async (): Promise<void> => {
        const baseURL = process.env.REACT_APP_API_URL;
        const apiURL = `${baseURL}/wp/v2/posts?per_page=50&_fields=id,slug,link,title,template,translations`;
        try {
            let res = await axios.get(apiURL);
            const posts = parseInt(res.headers['x-wp-totalpages']);
            const totalCount = parseInt(res.headers['x-wp-total']);

            this.loadAll('posts', apiURL, posts, totalCount);
        } catch (error) {
            Sentry.setExtra('errorData', error);
            Sentry.captureException(error);
        }
    };

    private loadAll = async (type: string, apiURL: string, totalPages: number, totalCount: number): Promise<void> => {
        const $this = this;
        const content = [];

        for (let i = 1; i <= totalPages; i++) {
            let res = await axios.get(apiURL + '&page=' + i);
            let { data } = await res;

            content.push(...data);

            if (content.length === totalCount) {
                if (type === 'pages') {
                    $this.setState({ pages: content });
                } else {
                    $this.setState({ posts: content });
                }
            }
        }
    };

    private setIp = async (): Promise<void> => {
        const baseURL = process.env.REACT_APP_API_URL;
        const apiURL = `${baseURL}/remoteaddress/v1/remoteaddress`;
        try {
            let res = await axios.get(apiURL);
            let { data } = await res;
            this.setState({ ip: data.ip });
        } catch (error) {
            Sentry.setExtra('errorData', error);
            Sentry.captureException(error);
        }
    };

    public componentDidMount = async (): Promise<void> => {
        // Redirect for Autoweek campaign.
        if (window.location.pathname === '/autoweek/' || window.location.pathname === '/autoweek') {
            window.location.href =
                'https://move-amsterdam.briqbookings.com/nl-NL/deal/autoweek?utm_medium=referral&utm_source=autoweek&utm_campaign=autoweek&utm_content=autoweek';
        }

        await this.setIp();
        await this.getAllPages();
        await this.getAllPosts();
        await this.getMenu();

        if (window.location.hash === '#cookie') {
            this.setState({
                cookiesModalVisible: true,
            });
        }
    };

    private handleCookiesModal = (statistics: boolean, marketing: boolean): void => {
        const { cookies } = this.props;

        if (typeof statistics === 'undefined') {
            statistics = this.props.cookies.get('acceptStatisticsCookies')
                ? this.props.cookies.get('acceptStatisticsCookies')
                : this.state.acceptStatisticsCookies;
        }
        if (typeof marketing === 'undefined') {
            marketing = this.props.cookies.get('acceptMarketingCookies')
                ? this.props.cookies.get('acceptMarketingCookies')
                : this.state.acceptMarketingCookies;
        }

        // Set state to prevent overwrite from constructor
        this.setState({
            acceptStatisticsCookies: statistics,
            acceptMarketingCookies: marketing,
            cookiesModalVisible: !this.state.cookiesModalVisible,
        });

        let expireDate = new Date();
        expireDate.setDate(expireDate.getDate() + 30);

        cookies.set('acceptEssentialCookies', true, {
            path: '/',
            domain: process.env.REACT_APP_COOKIE_DOMAIN,
            expires: expireDate,
        });
        cookies.set('acceptStatisticsCookies', statistics, {
            path: '/',
            domain: process.env.REACT_APP_COOKIE_DOMAIN,
            expires: expireDate,
        });
        cookies.set('acceptMarketingCookies', marketing, {
            path: '/',
            domain: process.env.REACT_APP_COOKIE_DOMAIN,
            expires: expireDate,
        });

        cookies.set('hasSetCookiePreferences', true, {
            path: '/',
            domain: process.env.REACT_APP_COOKIE_DOMAIN,
            expires: expireDate,
        });

        let cookieValuesObj = [
            {
                essential: true,
                statistics: statistics,
                marketing: marketing,
            },
        ];

        this.setState({
            cookiesValue: cookieValuesObj,
        });
    };

    // FUNCTION IS CALLED WHEN BUTTON IS CLICKED WITHOUT VIEWING THE OPTIONS
    public setCookiesValue = (): void => {
        const { cookies } = this.props;

        let expireDate = new Date();
        expireDate.setDate(expireDate.getDate() + 30);
        // Set state to prevent overwrite from constructor
        this.setState({
            acceptStatisticsCookies: true,
            acceptMarketingCookies: true,
            cookiesModalVisible: !this.state.cookiesModalVisible,
        });

        cookies.set('acceptEssentialCookies', true, {
            path: '/',
            domain: process.env.REACT_APP_COOKIE_DOMAIN,
            expires: expireDate,
        });
        cookies.set('acceptMarketingCookies', true, {
            path: '/',
            domain: process.env.REACT_APP_COOKIE_DOMAIN,
            expires: expireDate,
        });
        cookies.set('hasSetCookiePreferences', true, {
            path: '/',
            domain: process.env.REACT_APP_COOKIE_DOMAIN,
            expires: expireDate,
        });

        let cookieValuesObj = [
            {
                essential: true,
                statistics: true,
                marketing: true,
            },
        ];

        this.setState({
            cookiesValue: cookieValuesObj,
        });
    };

    public updateLanguage = (locale: string): void => {
        this.setState({ language: locale }, (): Promise<void> => this.getMenu());
    };

    public render(): JSX.Element {
        const { pages, posts, ip, cookiesModalVisible, language } = this.state;

        // Internalization
        // @ts-ignore
        const messages = localeData[language];

        let path = '';
        if (language === 'en') {
            path = '/en';
        }

        const newsTranslations = language === 'en' ? 'news' : 'nieuws';

        const structuredDataLocalBusiness = `{
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "Move",
            "image": "https://move.amsterdam/assets/images/move.png",
            "@id": "https://move.amsterdam/",
            "url": "https://move.amsterdam/",
            "telephone": "telefoonnummer",
            "priceRange": "0",
            "address": {
                "@type": "PostalAddress",
                "streetAddress": "Stadionplein 26C",
                "addressLocality": "Amsterdam",
                "postalCode": "Postcode",
                "addressCountry": "NL"
            },
            "geo": {
                "@type": "GeoCoordinates",
                "latitude": 52.343340,
                "longitude": 4.856090
            },
            "openingHoursSpecification": {
                "@type": "OpeningHoursSpecification",
                "dayOfWeek": [
                    "Tuesday",
                    "Wednesday",
                    "Thursday",
                    "Friday",
                    "Saturday",
                    "Sunday"
                ],
                "opens": "09:00",
                "closes": "17:00"
            }
        }`;

        // 1 = Functional cookies and 2 = Analytics cookies in the allow_cookies DataLayer.
        return (
            <IntlProvider locale={language} messages={messages}>
                <Router history={history}>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Move Amsterdam</title>
                        {language === 'en' && <link rel="canonical" href="https://move.amsterdam/en/" />}
                        {language === 'en' && <meta property="og:locale" content="en_US" />}
                        {language === 'en' && <meta property="og:locale:alternate" content="nl_NL" />}
                        {language !== 'en' && <link rel="canonical" href="https://move.amsterdam/" />}
                        {language !== 'en' && <meta property="og:locale" content="nl_NL" />}
                        {language !== 'en' && <meta property="og:locale:alternate" content="en_US" />}
                        <meta property="og:type" content="website" />
                        <meta name="google-site-verification" content="7mq-VW-ZtWtSL7pt-XkpMzIqbeFqFF4OthHaJgZUAeE" />
                        <noscript>{`<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PNSJD4C" height="0" width="0" style="display:none;visibility:hidden"></iframe>`}</noscript>
                        <script>
                            {`
                        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                        })(window,document,'script','dataLayer','GTM-PNSJD4C');
                        window.dataLayer = window.dataLayer || [];
                        window.dataLayer.push({
                            'event': 'checkCookieconsent',
                            'remote_ip': '${ip}',
                            'allow_cookies': ${JSON.stringify(this.state.cookiesValue)}
                        })
                        `}
                        </script>
                        <script type="application/ld+json">{structuredDataLocalBusiness}</script>
                    </Helmet>
                    <Fragment>
                        <Switch>
                            <Redirect exact from="/en/building/" to="/en/mobility-experience-eng/" />
                            <Redirect from="/en/move-the-city" to="/en/move-the-city-exhibition" />
                            <Redirect from="/en/building/huub-mobility-hub/" to="/en/mobility-experience-eng/" />
                            <Redirect from="/en/building/bens-garage/" to="/" />
                            <Redirect from="/en/building/porsche/" to="/" />
                            <Redirect from="/en/building/bentley/" to="/" />
                            <Redirect from="/en/building/lamborghini/" to="/" />
                            <Redirect from="/en/about-move/" to="/" />

                            <Redirect exact from="/gebouw/" to="/mobility-experience" />
                            <Redirect from="/gebouw/huub-mobility-hub/" to="/mobility-experience" />
                            <Redirect from="/gebouw/bens-garage/" to="/" />
                            <Redirect from="/gebouw/porsche/" to="/" />
                            <Redirect from="/gebouw/bentley/" to="/" />
                            <Redirect from="/gebouw/lamborghini/" to="/" />
                            <Redirect from="/over-move/" to="/" />
                            <Redirect from="/algemene-voorwaarden/" to="/bezoekers-voorwaarden" />
                            <Redirect
                                from="/kaart/"
                                to="/?utm_medium=referral&utm_source=ansichtkaart&utm_campaign=kaarten"
                            />
                            <Route
                                exact
                                strict
                                path="/:url*"
                                render={(props: RouteComponentProps): any => (
                                    <Redirect to={`${props.location.pathname}/`} />
                                )}
                            />
                            {pages.map(
                                (page: Page, i: number): JSX.Element => {
                                    // @todo 'cookies' and 'privacyverklaring' are constants
                                    if (
                                        page.slug === 'cookies' ||
                                        page.slug === 'cookie-policy' ||
                                        page.slug === 'privacyverklaring' ||
                                        page.slug === 'privacy-statement'
                                    ) {
                                        return (
                                            <Route
                                                key={i}
                                                exact
                                                path={`${path}/${page.slug}`}
                                                render={(props: RouteComponentProps): JSX.Element => (
                                                    <ContentPage
                                                        {...props}
                                                        page={page}
                                                        menuData={this.state.menu}
                                                        cookies={this.props.cookies}
                                                        handleCookiesModal={this.handleCookiesModal}
                                                        cookiesModalVisible={false}
                                                        setCookiesValue={this.setCookiesValue}
                                                        language={language}
                                                        updateLanguage={this.updateLanguage}
                                                    />
                                                )}
                                            />
                                        );
                                    } else {
                                        // Get the parent of the page and put it before the slug (if parent is specified)
                                        let pageParent = page.parent_page_slug;
                                        return (
                                            <Route
                                                key={i}
                                                exact
                                                path={`${path}${pageParent ? '/' + pageParent : ''}/${page.slug}`}
                                                render={(props: RouteComponentProps): JSX.Element => (
                                                    <ContentPage
                                                        {...props}
                                                        page={page}
                                                        menuData={this.state.menu}
                                                        cookies={this.props.cookies}
                                                        handleCookiesModal={this.handleCookiesModal}
                                                        cookiesModalVisible={cookiesModalVisible}
                                                        setCookiesValue={this.setCookiesValue}
                                                        language={language}
                                                        updateLanguage={this.updateLanguage}
                                                    />
                                                )}
                                            />
                                        );
                                    }
                                },
                            )}
                            {posts.map(
                                (page: Page, i: number): JSX.Element => (
                                    <Route
                                        key={i}
                                        path={`${path}/${newsTranslations}/${page.slug}`}
                                        render={(props: RouteComponentProps): JSX.Element => (
                                            <ContentPost
                                                {...props}
                                                page={page}
                                                menuData={this.state.menu}
                                                cookies={this.props.cookies}
                                                handleCookiesModal={this.handleCookiesModal}
                                                cookiesModalVisible={cookiesModalVisible}
                                                setCookiesValue={this.setCookiesValue}
                                                language={language}
                                                updateLanguage={this.updateLanguage}
                                            />
                                        )}
                                    />
                                ),
                            )}
                            {/* Default Page */}
                            <Route
                                exact
                                path="/"
                                render={(props: RouteComponentProps): JSX.Element => (
                                    <HomePage
                                        {...props}
                                        menuData={this.state.menu}
                                        cookies={this.props.cookies}
                                        handleCookiesModal={this.handleCookiesModal}
                                        cookiesModalVisible={cookiesModalVisible}
                                        setCookiesValue={this.setCookiesValue}
                                        language={language}
                                        updateLanguage={this.updateLanguage}
                                    />
                                )}
                            />
                            <Route
                                exact
                                path="/en"
                                render={(props: RouteComponentProps): JSX.Element => (
                                    <HomePage
                                        {...props}
                                        menuData={this.state.menu}
                                        cookies={this.props.cookies}
                                        handleCookiesModal={this.handleCookiesModal}
                                        cookiesModalVisible={cookiesModalVisible}
                                        setCookiesValue={this.setCookiesValue}
                                        language={language}
                                        updateLanguage={this.updateLanguage}
                                    />
                                )}
                            />
                            {/* Sitemap */}
                            <Route exact path="/sitemap2.xml" render={(): JSX.Element => <SiteMap />} />
                            {/* Search */}
                            <Route
                                exact
                                path={`${path}/s`}
                                render={(props: RouteComponentProps): JSX.Element => (
                                    <SearchResults
                                        {...props}
                                        menuData={this.state.menu}
                                        cookies={this.props.cookies}
                                        handleCookiesModal={this.handleCookiesModal}
                                        cookiesModalVisible={cookiesModalVisible}
                                        setCookiesValue={this.setCookiesValue}
                                        language={language}
                                        updateLanguage={this.updateLanguage}
                                    />
                                )}
                            />
                            {pages.length > 0 && (
                                <Route
                                    render={(props: RouteComponentProps): JSX.Element => (
                                        <NoMatchPage
                                            {...props}
                                            menuData={this.state.menu}
                                            cookies={this.props.cookies}
                                            handleCookiesModal={this.handleCookiesModal}
                                            cookiesModalVisible={cookiesModalVisible}
                                            setCookiesValue={this.setCookiesValue}
                                            language={language}
                                            updateLanguage={this.updateLanguage}
                                        />
                                    )}
                                />
                            )}
                        </Switch>
                    </Fragment>
                </Router>
            </IntlProvider>
        );
    }
}
export default withCookies(App);
