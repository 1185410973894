import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import MainLayout from './Layout/MainLayout';
import { FormattedMessage } from 'react-intl';

interface Props {
    menuData: any;
    cookies: any;
    cookiesModalVisible: boolean;
    handleCookiesModal: Function;
    setCookiesValue: Function;
    language: string;
    updateLanguage: Function;
}

export default function NoMatchPage(props: Props): JSX.Element {
    return (
        <MainLayout type="page" {...props}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>404 Page</title>
                <link rel="canonical" href="https://move.amsterdam/404/" />
            </Helmet>

            <div className="content-page error-page">
                <div className="row center">
                    <div className="column large-12 medium-16 small-full">
                        <h1>404</h1>

                        <div className="subtitle">
                            <FormattedMessage id="Error.subtitle" defaultMessage="Oops, something went wrong." />
                        </div>

                        <Link id="error-page-btn" to="/">
                            Move me back!
                        </Link>
                    </div>
                </div>
            </div>
        </MainLayout>
    );
}
