import React from 'react';
import Heading from '../Content/Gutenberg/Heading';
import Paragraph from '../Content/Gutenberg/Paragraph';
import Slideshow from '../Content/Gutenberg/Slideshow';
import ImageToggle from '../Content/Gutenberg/ImageToggle';
import Button from '../Content/Gutenberg/Button';
import Image from '../Content/Gutenberg/Image';
import Spacer from '../Content/Gutenberg/Spacer';
import Youtube from '../Content/Gutenberg/Youtube';
import ImageCta from '../Content/Gutenberg/ImageCta';
import Columns from '../Content/Gutenberg/Columns';
import Group from '../Content/Gutenberg/Group';
import PartnerPanels from '../Content/Gutenberg/PartnerPanels';
import Maps from '../Content/Gutenberg/Maps';
import Table from '../Content/Gutenberg/Table';
import TickerBlock from '../Content/Gutenberg/TickerBlock';
import ExhibitionDetails from '../Content/Gutenberg/ExhibitionDetails';

interface Props {
    blocks: any;
    full: boolean;
    language: string;
    animImgs?: any;
}

export default function GutenbergBlocks(props: Props): JSX.Element {
    let { blocks, full, language, animImgs } = props;

    return (
        <div className={`gutenberg-blocks row center width-100`}>
            <div className="column medium-full">
                {Object.keys(blocks).map(function(key: string): any {
                    if (blocks[key].blockName === 'core/heading') {
                        return (
                            <Heading
                                key={key}
                                innerContent={blocks[key].innerContent}
                                full={full}
                                attrs={blocks[key].attrs}
                            />
                        );
                    } else if (blocks[key].blockName === 'core/paragraph') {
                        return <Paragraph key={key} innerContent={blocks[key].innerContent} full={full} />;
                    } else if (blocks[key].blockName === 'core/list') {
                        return <Paragraph key={key} innerContent={blocks[key].innerContent} full={full} />;
                    } else if (blocks[key].blockName === 'core/columns') {
                        return (
                            <Columns
                                key={key}
                                innerContent={blocks[key]}
                                attrs={blocks[key].attrs}
                                animImgs={animImgs}
                            />
                        );
                    } else if (blocks[key].blockName === 'core/group') {
                        return <Group key={key} innerContent={blocks[key]} language={language} animImgs={animImgs} />;
                    } else if (blocks[key].blockName === 'core/buttons') {
                        return <Button key={key} innerContent={blocks[key]} full={full} />;
                    } else if (blocks[key].blockName === 'core/button') {
                        return <Button key={key} innerContent={blocks[key].innerContent} full={full} />;
                    } else if (blocks[key].blockName === 'core/image') {
                        return <Image key={key} innerContent={blocks[key].innerContent} full={full} />;
                    } else if (blocks[key].blockName === 'core/spacer') {
                        return <Spacer key={key} innerContent={blocks[key].innerContent} />;
                    } else if (blocks[key].blockName === 'core-embed/youtube') {
                        return <Youtube key={key} url={blocks[key].attrs.url} full={full} />;
                    } else if (blocks[key].blockName === 'core/table') {
                        return <Table key={key} innerContent={blocks[key].innerContent} full={full} />;
                    } else if (blocks[key].blockName === 'pon-gutenberg-blocks/slideshow') {
                        return (
                            <Slideshow
                                key={key}
                                className={blocks[key].attrs.className}
                                slideshowId={blocks[key].attrs.slideshowId}
                                innerBlocks={blocks[key].innerBlocks}
                            />
                        );
                    } else if (blocks[key].blockName === 'pon-gutenberg-blocks/gallery') {
                        return (
                            <ImageToggle
                                key={key}
                                full={full}
                                firstImgAlt={blocks[key].attrs.firstImgAlt}
                                firstImgURL={blocks[key].attrs.firstImgURL}
                                secondImgAlt={blocks[key].attrs.secondImgAlt}
                                secondImgURL={blocks[key].attrs.secondImgURL}
                                innerBlocks={blocks[key].innerBlocks}
                            />
                        );
                    } else if (blocks[key].blockName === 'pon-gutenberg-blocks/imagecta') {
                        return (
                            <ImageCta
                                key={key}
                                full={full}
                                imgAlt={blocks[key].attrs.imgAlt}
                                imgURL={blocks[key].attrs.imgURL}
                                innerBlocks={blocks[key].innerBlocks}
                            />
                        );
                    } else if (blocks[key].blockName === 'pon-gutenberg-blocks/partner-panels') {
                        return <PartnerPanels language={language} key={key} />;
                    } else if (blocks[key].blockName === 'pon-gutenberg-blocks/maps') {
                        return <Maps key={key} />;
                    } else if (blocks[key].blockName === 'pon-gutenberg-blocks/ticker') {
                        return <TickerBlock key={key} content={blocks[key].attrs.tickerText} attrs={blocks[key].attrs} />;
                    } else if (blocks[key].blockName === 'pon-gutenberg-blocks/exhibition-details') {
                        return <ExhibitionDetails key={key} elements={blocks[key].attrs} language={language} />;
                    }
                    return false;
                })}
            </div>
        </div>
    );
}
