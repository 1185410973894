import React from 'react';
import { Helmet } from 'react-helmet';
import MainLayout from './Layout/MainLayout';
import metaImage from '../Assets/images/meta-image.jpg';
import SliderHomepage from '../Components/SliderHomepage';
import PracticalInfo from './PracticalInfo';
import useFrontPage, { FrontPage } from './Pages/useFrontPage';
import NewsOnHomePage from './Posts/NewsOnHomePage';
import ActivitiesBlock from './Homepage/ActivitiesBlock';
import BuildingHotspots from './Homepage/BuildingHotspots';
import BuyTicketsMobile from './Elements/BuyTicketsMobile';
import InstagramComponent from './InstagramComponent';
// import { useMediaQuery } from 'react-responsive';

interface HomePageProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    menuData: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    cookies: any;
    cookiesModalVisible: boolean;
    handleCookiesModal: Function;
    setCookiesValue: Function;
    language: string;
    updateLanguage: Function;
}

const LanguageStrings: { [index: string]: { [index: string]: string } } = {
    description: {
        en: 'Discover Move. Move The City. An interactive experience - for all',
        nl: 'Ontdek Move. Move The City. Een interactieve experience - voor jong en oud',
    },
    url: {
        en: 'https://move.amsterdam/en/',
        nl: 'https://move.amsterdam/',
    },
};

function getTranslation(string: string, language: string = 'nl'): string {
    return LanguageStrings[string][language];
}

export default function HomePage(props: HomePageProps): JSX.Element {
    const { language } = props;
    const page = useFrontPage(language);

    if (page === null) {
        return <></>;
    }

    const {
        acf: {
            hotspots,
            whats_next: whatsNext,
            slider,
            practical_information: practicalInformation,
            featured_image: featuredImage,
            inside_move: insideMove,
            instagram,
        },
    } = page as FrontPage;

    return (
        <MainLayout type="page" {...props}>
            <Helmet>
                <title>Move Amsterdam</title>
                <meta name="description" content={getTranslation('description', language)} />
                <meta name="og:description" content={getTranslation('description', language)} />
                <meta property="og:url" content={getTranslation('url', language)} />
                <meta property="og:image:secure_url" content={metaImage} />
                <meta property="og:title" content="Move Amsterdam" />
                <meta property="og:site_name" content="Move Amsterdam" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Move Amsterdam" />
                <meta name="twitter:description" content={getTranslation('description', language)} />
            </Helmet>
            <section>
                <SliderHomepage slider={slider} />
            </section>
            <section>
                <BuyTicketsMobile />
            </section>
            <section>
                <ActivitiesBlock {...{ ...props, ...insideMove }} />
            </section>
            <section>
                <BuildingHotspots {...{ hotspots, language }} />
            </section>
            <section>
                <NewsOnHomePage {...{ ...props, ...whatsNext }} />
            </section>
            <section>
                <PracticalInfo {...{ props, practicalInformation, featuredImage }} />
            </section>
            <section>
                <InstagramComponent {...{ instagram, language }} />
            </section>
        </MainLayout>
    );
}
