import React, { useEffect, useState } from 'react';
import GutenbergBlocks from '../Content/GutenbergBlocks';
import usePlanVisit from '../../Components/Pages/usePlanVisit';
import { gsap } from 'gsap';
import scrollToPlugin from 'gsap/ScrollToPlugin';

gsap.registerPlugin(scrollToPlugin);

interface PlanVisitPageProps {
    title: string;
    excerpt: string;
    featuredImage: any;
    blocks: any;
    content: any;
    language: string;
}

interface State {
    visible: boolean;
    menu: string;
}

export default function PlanVisit(props: PlanVisitPageProps): JSX.Element {
    const { title, blocks, language } = props;
    const planVisitItems = usePlanVisit(language);

    const [menu, setMenu] = useState('');
    const [prevMenu, setPrevMenu] = useState('');

    const useReactLocation = (): Location => {
        const [location, setLocation] = React.useState(window.location);
        const listenToPopstate = () => {
            const winLocation = window.location;
            setLocation(winLocation);
        };
        React.useEffect((): any => {
            window.addEventListener('popstate', listenToPopstate);
            return () => {
                window.removeEventListener('popstate', listenToPopstate);
            };
        }, []);
        return location;
    };

    let { hash } = useReactLocation();

    useEffect((): void => {
        setTimeout((): void => {
            if (hash) {
                const newHash = hash.replace('#/', '');
                const id = newHash.replace('#', '');
                const element = document.getElementById(id) as HTMLElement;
                if (element) {
                    const content = document.querySelector(`#${element.id} .gutenberg-blocks`) as HTMLElement;
                    gsap.timeline()
                        .to(window, {
                            duration: 1,
                            scrollTo: { y: element, offsetY: 250 },
                            ease: 'power2.easeOut',
                        })
                        .to(content, {
                            duration: 0.5,
                            autoAlpha: 1,
                            maxHeight: content.scrollHeight,
                        });
                    setMenu('#' + element.id.toString());
                }
            }
        }, 1000);
    }, [hash]);

    const toggleVisible = (event: any): void => {
        setPrevMenu(menu);
        setMenu(`#${event.currentTarget.parentElement.id}`);
        if (menu === prevMenu) {
            setPrevMenu('');
        }
    };

    useEffect((): void => {
        if (menu !== prevMenu) {
            gsap.timeline()
                .to(`${prevMenu} .gutenberg-blocks`, { duration: 0.5, autoAlpha: 0, maxHeight: 0 })
                .to(`${menu} .gutenberg-blocks`, { duration: 0.5, autoAlpha: 1, maxHeight: 1500 });
        } else if (menu === prevMenu) {
            gsap.timeline().to(`${menu} .gutenberg-blocks`, { duration: 0.5, autoAlpha: 0, maxHeight: 0 });
        }
    }, [menu, prevMenu]);

    return (
        <>
            <div className="row center">
                <div className="column large-18 medium-20 small-full">
                    <h1>{title}</h1>
                </div>
            </div>

            <div className="row center">
                <div className="column large-14 medium-20 small-full">
                    <GutenbergBlocks language={language} blocks={blocks} full={true} />
                </div>
            </div>

            <div className="row center">
                <div className="column medium-20 small-full">
                    {typeof planVisitItems !== 'undefined' && (
                        <div className="planvisit-container">
                            <ul>
                                {planVisitItems.map(
                                    (c: any, i: number): JSX.Element => (
                                        <li id={c.slug} className="faq-inner" key={i}>
                                            <i
                                                className={`toggle-btn ${
                                                    prevMenu !== menu && menu === `#${c.slug}` ? 'open' : ''
                                                }`}
                                                onClick={toggleVisible}
                                            ></i>
                                            <h2 dangerouslySetInnerHTML={{ __html: c.title.rendered }} />
                                            <GutenbergBlocks language={c.lang} blocks={c.blocks} full={false} />
                                        </li>
                                    ),
                                )}
                            </ul>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}
