import React from 'react';
import MainLayout from './Layout/MainLayout';
import ContentPostArea from './Content/ContentPostArea';
import { Helmet } from 'react-helmet';

interface Props {
    page: {
        title: {
            rendered: string;
        };
        id: number;
        link: string;
        template?: string;
        translations?: any;
    };
    menuData: any;
    //Cookies props
    cookies: any;
    cookiesModalVisible: boolean;
    handleCookiesModal: Function;
    setCookiesValue: Function;
    language: string;
    updateLanguage: Function;
}

export default function ContentPost(props: Props): JSX.Element {
    const { rendered } = props.page.title;
    const { id, link } = props.page;

    // Replace the backend url with the frontend url for the right canonical
    const newsTranslations = props.language === 'en' ? 'en/news' : 'nieuws';
    const backendUrl = process.env.REACT_APP_BACKEND_URL ? process.env.REACT_APP_BACKEND_URL : '';
    const frontendUrl = process.env.REACT_APP_FRONTEND_URL ? process.env.REACT_APP_FRONTEND_URL : '';
    let canonical = link;
    if (props.language === 'en') {
        canonical = canonical.replace('en/', '');
    }
    canonical = canonical.replace(backendUrl, frontendUrl + '/' + newsTranslations);

    const { language } = props;
    return (
        <MainLayout type="post" {...props}>
            <Helmet>
                <title>{rendered}</title>
                <link rel="canonical" href={canonical} />
                <meta name="twitter:card" content={rendered} />
            </Helmet>
            <ContentPostArea postId={id} link={canonical} language={language} />
        </MainLayout>
    );
}
