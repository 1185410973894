/* eslint-disable flowtype/require-return-type */
/* eslint-disable flowtype/require-parameter-type */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import Ticker from '../Elements/Ticker';
import GutenbergBlocks from '../Content/GutenbergBlocks';
import useExhibitions, { SparseExhibitions } from '../../Components/Pages/useExhibitions';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import ReactPlayer from 'react-player';

interface ExhibitionPageProps {
    title: string;
    excerpt: string;
    featuredImage: any;
    blocks: any;
    content: any;
    acf?: any;
    language: string;
    id: string;
    slug?: any;
}

function Exhibition({ exhibition, language }: { exhibition: SparseExhibitions; language: string }): JSX.Element {
    let path = '';
    if (language === 'en') {
        path = '/en';
    }
    return (
        <div className="column large-12 small-full">
            <div className="exhibition__single">
                <Link to={`${path}/${exhibition.slug}/`} className="exhibition__single__link">
                    <LazyLoadImage
                        src={exhibition.featured_image_large.url}
                        effect="opacity"
                        alt={exhibition.title.rendered}
                    />
                    <div className="exhibition__single__wrapper">
                        <span className="exhibition__single__date">
                            {exhibition.acf.date.start_date} - {exhibition.acf.date.end_date}
                        </span>
                        <h5 className="exhibition__single__title">{exhibition.title.rendered}</h5>
                    </div>
                </Link>
            </div>
        </div>
    );
}

export default function ExhibitionPage(props: ExhibitionPageProps): JSX.Element {
    const { title, excerpt, featuredImage, blocks, acf, language, id } = props;
    const exhibitions = useExhibitions('template-building.php', id, language);

    const lightenDarkenColor = function(col: string, amt: number): any {
        var usePound = false;
        if (col[0] === '#') {
            col = col.slice(1);
            usePound = true;
        }
        var num = parseInt(col, 16);
        var r = (num >> 16) + amt;
        if (r > 255) {
            r = 255;
        } else if (r < 0) {
            r = 0;
        }
        var b = ((num >> 8) & 0x00ff) + amt;
        if (b > 255) {
            b = 255;
        } else if (b < 0) {
            b = 0;
        }
        var g = (num & 0x0000ff) + amt;
        if (g > 255) {
            g = 255;
        } else if (g < 0) {
            g = 0;
        }
        return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
    };

    useEffect((): any => {
        if (acf.exhibition_color) {
            document.documentElement.style.setProperty('--divider-color', lightenDarkenColor(acf.exhibition_color, 60));
        }
        return (): any => {
            document.documentElement.style.setProperty('--divider-color', null);
        };
    }, [acf.exhibition_color]);

    useEffect((): any => {
        if (acf.type_of_page === 'withform' && acf.thank_you_page !== null) {
            const wpcf7Elm = document.querySelector('.wpcf7-form') as any;
            const data = new URLSearchParams();
            wpcf7Elm.addEventListener(
                'submit',
                function(event: any): void {
                    event.preventDefault();
                    // @ts-ignore: Unreachable code error
                    for (const pair of new FormData(wpcf7Elm)) {
                        data.append(pair[0], pair[1]);
                    }
                    let url = event.target.action;
                    fetch(url, { method: 'POST', body: data })
                        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
                        .then(res => res.json())
                        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
                        .then(out => {
                            if (out.status !== 'mail_sent') {
                                for (const field of out.invalid_fields) {
                                    let el = document.querySelector(field.into).querySelector('input');
                                    el.classList.add('error-form');
                                    el.value !== '' && (el.value = '');
                                    el.placeholder = field.message;
                                }
                            } else if (out.status === 'mail_sent') {
                                window.location.pathname = acf.thank_you_page.post_name;
                            }
                        });
                },
                false,
            );
        }
    }, [acf.thank_you_page, acf.type_of_page]);

    const [isVideoPlay, setIsVideoPlay] = useState(false);

    useEffect((): any => {
        const player = document.querySelector('.content-player') as HTMLElement;

        function isAnyPartOfElementInViewport(player: any): boolean {
            const rect = player.getBoundingClientRect();
            const windowHeight = window.innerHeight || document.documentElement.clientHeight;
            const windowWidth = window.innerWidth || document.documentElement.clientWidth;
            const vertInView = rect.top <= windowHeight && rect.top + rect.height >= 0;
            const horInView = rect.left <= windowWidth && rect.left + rect.width >= 0;

            return vertInView && horInView;
        }

        function checkScroll(): void {
            if (isAnyPartOfElementInViewport(player)) {
                setIsVideoPlay(true);
            } else {
                setIsVideoPlay(false);
            }
        }

        if (player) {
            window.removeEventListener('scroll', checkScroll);
            window.addEventListener('scroll', checkScroll, { passive: true });
            return () => window.removeEventListener('scroll', checkScroll);
        }
    }, []);

    return (
        <>
            {acf.date && acf.date.start_date && acf.date.end_date && (
                <div className="row center">
                    <div className="column large-12 medium-14 small-full">
                        <span className="exhibition__date">
                            {acf.date.start_date} - {acf.date.end_date}
                        </span>
                    </div>
                </div>
            )}
            {title !== 'Fast' && (
                <div className="row center">
                    <div
                        className={`column large-18 medium-20 small-full ${acf.type_of_page === 'education' &&
                            'education_wrap'}`}
                    >
                        <h1 className={`${acf.type_of_page === 'education' && 'education_title'}`}>{title}</h1>
                        {acf.exposition_subtitle && (
                            <p
                                className={`exhibition__subtitle ${acf.type_of_page === 'education' &&
                                    'education_subtitle'}`}
                            >
                                {acf.exposition_subtitle}
                            </p>
                        )}
                        {acf.show_intro_cta && (
                            <a className="button education_btn" href={acf.intro_cta_link}>
                                {acf.intro_cta_label}
                            </a>
                        )}
                    </div>
                </div>
            )}
            {acf.video && (acf.video.video_url !== '' || acf.video.poster) && (
                <div className="row width-100 center">
                    {/* {acf.video.video_url !== '' && (
                        <div className="column small-full column-full">
                            <Youtube url={acf.video.video_url} poster={acf.video.poster} full={true} />
                        </div>
                    )} */}
                    {acf.video.video_url === '' && acf.video.poster && (
                        <LazyLoadImage
                            className="full-image"
                            effect="opacity"
                            alt={title}
                            height={acf.video.poster.height}
                            src={acf.video.poster.url}
                            width={acf.video.poster.width}
                        />
                    )}
                </div>
            )}
            {/* TODO: Add static image option until producing new video */}
            {title === 'Fast' && (
                <div className="row width-100 center">
                    <div className="mobility__header">
                        <div className="mobility_header-content">
                            <img className="mobility__logo" src={acf.heading_image} alt="Logo" />
                            <h2 className="mobility__heading">{acf.heading_text}</h2>
                            <a href={acf.heading_btn_url} className="wp-block-button__link mobility__btn">
                                {acf.heading_btn_text}
                            </a>
                        </div>
                        <div className="vimeo-wrapper">
                            <ReactPlayer
                                url={`https://player.vimeo.com/video/${acf.heading_background}?background=1&autoplay=1&loop=0&byline=0&title=0&muted=1&playsinline=1`}
                                playing={true}
                                loop={false}
                                frameBorder="0"
                                allowFullScreen
                                title={acf.heading_background}
                                className="vimeo-iframe"
                                width={'100vw'}
                                height={'56.25vw'}
                                muted={true}
                                playsinline={true}
                            />
                        </div>
                    </div>
                </div>
            )}

            {title !== 'Fast' && acf.type_of_page !== 'education' && excerpt && (
                <div className="row width-100 center">
                    <div className="column medium-12 large-16 small-full">
                        {acf.video && (acf.video.video_url !== '' || acf.video.poster) ? (
                            <div className={`row introduction ${acf.subtitle === 'inventor' && 'inventor_intro'}`}>
                                <div dangerouslySetInnerHTML={{ __html: excerpt }} />
                            </div>
                        ) : (
                            <div className="row introduction no-top-margin">
                                <div dangerouslySetInnerHTML={{ __html: excerpt }} />
                            </div>
                        )}
                    </div>
                </div>
            )}

            {featuredImage && (
                <div className="row width-100 center">
                    <div
                        className={
                            acf.featured_image_full_width ? 'medium-full small-full' : 'column medium-22 small-full'
                        }
                    >
                        <LazyLoadImage
                            className="full-image"
                            effect="opacity"
                            src={featuredImage.url}
                            width={featuredImage.width}
                            height={featuredImage.height}
                            alt={featuredImage.alt}
                        />
                    </div>
                </div>
            )}
            {(acf.ticker ||
                acf.additional_content.heading ||
                acf.additional_content.text ||
                acf.additional_content.image) && (
                <div className="row width-100 center content" style={{ backgroundColor: acf.exhibition_color }}>
                    {acf.ticker && <Ticker content={acf.ticker} />}
                    {(acf.additional_content.heading ||
                        acf.additional_content.text ||
                        acf.additional_content.image) && (
                        <div className="row width-100 center content--additional">
                            <div className="column medium-20 small-full">
                                <div className="gutenberg-blocks row center">
                                    <div className="column medium-10 small-full">
                                        <h2>{acf.additional_content.heading}</h2>
                                        <div dangerouslySetInnerHTML={{ __html: acf.additional_content.text }} />
                                    </div>
                                    <div className="column medium-14 small-full">
                                        {acf.additional_content.image && (
                                            <figure>
                                                <LazyLoadImage
                                                    src={acf.additional_content.image}
                                                    effect="opacity"
                                                    alt={acf.additional_content.heading}
                                                />
                                            </figure>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
            {acf.type_of_page === 'withform' && (
                <div className="row width-100 center content-text inventor-form-wrap">
                    <div className="column medium-20 small-full">
                        <div className="width-100" dangerouslySetInnerHTML={{ __html: acf.contact_form_7_shortcode }} />
                    </div>
                </div>
            )}
            <div className="row width-100 center content-text">
                <div className="column medium-20 small-full">
                    {title !== 'Fast' && (
                        <div className={`row center ${acf.type_of_page === 'education' && 'education_text'}`}>
                            <div className="column small-full page">
                                <GutenbergBlocks language={language} blocks={blocks} full={true} />
                            </div>
                        </div>
                    )}
                    {title === 'Fast' && (
                        <div>
                            <div className="column small-full page">
                                <GutenbergBlocks language={language} blocks={blocks} full={true} />
                            </div>
                        </div>
                    )}
                </div>
            </div>

            {acf.exposition_image && (
                <div className="row center width-100">
                    <div className="column small-full column-full content-text">
                        <LazyLoadImage
                            className="full-image"
                            src={acf.exposition_image.url}
                            effect="opacity"
                            width={acf.exposition_image.width}
                            height={acf.exposition_image.height}
                            alt={title}
                        />
                    </div>
                </div>
            )}

            {title === 'Fast' && (
                <div className="row width-100 center">
                    <div className="mobility__header fast__header">
                        <div className="player-wrapper">
                            <ReactPlayer
                                url={`https://vimeo.com/${acf.video_content}?background=1&autoplay=0&loop=0&byline=0&title=0&muted=1&playsinline=1`}
                                className="react-player content-player"
                                playing={isVideoPlay}
                                loop={false}
                                width="100%"
                                height={'auto'}
                                muted={true}
                                playsinline={true}
                            />
                        </div>
                    </div>
                </div>
            )}

            {!acf.hide_previous_exhibitions && (
                <div className="previous__exhibitions">
                    <div className="row center">
                        <div className="column medium-22 small-full">
                            <div className="column medium-8 small-full">
                                <FormattedMessage
                                    tagName="h3"
                                    id="Exhibitions.previous"
                                    defaultMessage="Previous exhibitions"
                                />
                                <FormattedMessage
                                    tagName="p"
                                    id="Exhibitions.description"
                                    defaultMessage="Take a look at the previous exhibitions in the Mobility Experience:"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="wrapper__inner" style={{ backgroundColor: acf.exhibition_color }}>
                        <div className="row width-100 center">
                            <div className="column medium-22 small-full page">
                                <div className="row width-100">
                                    {typeof exhibitions !== 'undefined' &&
                                        exhibitions.map(
                                            (exhibition: SparseExhibitions, i: number): JSX.Element => (
                                                <Exhibition key={i} {...{ exhibition, language }} />
                                            ),
                                        )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
