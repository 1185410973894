import React from 'react';
import { Cloudinary } from '../../../utils';
import Html from './Html';
import AnimatedImage from './AnimatedImage';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { LazyLoadImage } from 'react-lazy-load-image-component';

interface Props {
    innerContent: any;
    attrs?: any;
    animImgs?: any;
}

export default function Columns(props: Props): JSX.Element {
    const { innerContent, attrs } = props;

    const colData = innerContent.innerBlocks;
    let colClasses: any = [];
    const columnsClass: string = (attrs.className ? attrs.className : '') + ' wp-block-columns';
    let parser = new DOMParser();
    let col,
        colEl,
        colWidth,
        colStyle = '';
    for (let i = 0; i < colData.length; i++) {
        col = parser.parseFromString(colData[i].innerHTML, 'text/xml');
        colEl = col.getElementsByTagName('div')[0];
        colWidth = colEl.getAttribute('style');
        if (colWidth) {
            colStyle = colWidth.replace(/flex-basis/, '');
            colStyle = colStyle.replace(/"/, '');
            colStyle = colStyle.replace(/:/, '');
            colClasses.push(colStyle);
        }
    }

    return (
        <div className="row center">
            <div className="column medium-20 small-full no-padding">
                <div className="row width-100">
                    <div className={columnsClass}>
                        {colData.map(
                            (content: any, index: number): JSX.Element => {
                                return (
                                    <div
                                        key={index}
                                        className={
                                            'wp-block-column ' +
                                            (content.attrs.className ? content.attrs.className : '')
                                        }
                                        style={{ flex: colClasses[index] }}
                                    >
                                        {content.innerBlocks.map(
                                            (element: any, index: number, key: number): JSX.Element => {
                                                if (element.blockName === 'core/buttons') {
                                                    if (element.innerBlocks.length > 1) {
                                                        return (
                                                            <div
                                                                key={index}
                                                                className={
                                                                    element.attrs.className
                                                                        ? element.attrs.className
                                                                        : ''
                                                                }
                                                            >
                                                                <div
                                                                    key={key}
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: element.innerBlocks[0].innerContent,
                                                                    }}
                                                                />
                                                                <div
                                                                    key={key + index}
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: element.innerBlocks[1].innerContent,
                                                                    }}
                                                                />
                                                            </div>
                                                        );
                                                    } else if (element.innerBlocks.length === 1) {
                                                        return (
                                                            <div
                                                                key={index}
                                                                dangerouslySetInnerHTML={{
                                                                    __html: element.innerBlocks[0].innerContent,
                                                                }}
                                                            />
                                                        );
                                                    }
                                                }
                                                if (element.blockName === 'core/html') {
                                                    return <Html key={index} innerContent={element.innerHTML} />;
                                                }

                                                if (
                                                    element.blockName === 'core/image' &&
                                                    element.attrs.className === 'animated-image'
                                                ) {
                                                    return (
                                                        <AnimatedImage key={index} innerContent={element.innerHTML} />
                                                    );
                                                }

                                                if (
                                                    element.blockName === 'core/image' &&
                                                    element.attrs.className === 'edu-animated-image'
                                                ) {
                                                    return (
                                                        <AnimationOnScroll
                                                            key={index}
                                                            animateIn="animate__fadeout"
                                                            duration={0.2}
                                                            style={{ opacity: 'unset' }}
                                                            offset={250}
                                                            animateOnce={true}
                                                        >
                                                            <div
                                                                dangerouslySetInnerHTML={{
                                                                    __html: Cloudinary(element.innerHTML),
                                                                }}
                                                            />
                                                        </AnimationOnScroll>
                                                    );
                                                }
                                                if (
                                                    element.blockName === 'core/image' &&
                                                    element.attrs.sizeSlug === 'large'
                                                ) {
                                                    return (
                                                        <figure key={index}>
                                                            <LazyLoadImage
                                                                src={element.innerContent
                                                                    .toString()
                                                                    .slice(
                                                                        element.innerContent.toString().indexOf('http'),
                                                                        element.innerContent
                                                                            .toString()
                                                                            .indexOf('" alt='),
                                                                    )}
                                                                effect="opacity"
                                                                alt={element.innerContent
                                                                    .toString()
                                                                    .slice(
                                                                        element.innerContent
                                                                            .toString()
                                                                            .indexOf('uploads/'),
                                                                        element.innerContent.toString().indexOf('.jpg'),
                                                                    )}
                                                            />
                                                        </figure>
                                                    );
                                                }

                                                if (element.blockName === 'core/image') {
                                                    return (
                                                        <div
                                                            key={index}
                                                            dangerouslySetInnerHTML={{
                                                                __html: Cloudinary(element.innerHTML),
                                                            }}
                                                        />
                                                    );
                                                }
                                                return (
                                                    <div
                                                        key={index}
                                                        dangerouslySetInnerHTML={{
                                                            __html: Cloudinary(element.innerHTML),
                                                        }}
                                                    />
                                                );
                                            },
                                        )}
                                    </div>
                                );
                            },
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
