import React from 'react';
import ReactPlayer from 'react-player';

interface Props {
    full?: boolean;
    url: string;
    poster?: string;
}

const playIcon = (
    <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1" y="1" width="118" height="118" stroke="#FDA729" strokeWidth="2" />
        <path d="M76 59.5L51.25 77.2535L51.25 41.7465L76 59.5Z" fill="#FDA729" />
    </svg>
);

export default function Youtube(props: Props): JSX.Element {
    const { full, url, poster } = props;
    let openingClasses = '';

    if (full) {
        openingClasses = 'column small-full video__player';
    } else {
        openingClasses = 'column large-12 medium-16 small-full';
    }

    return (
        <div className="row center">
            <div className={openingClasses}>
                <div className="player-wrapper">
                    <ReactPlayer
                        url={url}
                        light={poster}
                        controls
                        width="100%"
                        height="100%"
                        className="react-player"
                        playIcon={playIcon}
                        config={{
                            youtube: {
                                playerVars: {
                                    showinfo: 0,
                                    controls: 0,
                                    modestbranding: 1,
                                    rel: 0,
                                },
                                embedOptions: {
                                    host: 'https://www.youtube-nocookie.com',
                                },
                            },
                        }}
                    />
                </div>
            </div>
        </div>
    );
}
