import React from 'react';

import Ticker from '../Elements/Ticker';
import GutenbergBlocks from '../Content/GutenbergBlocks';
import ActivityInformation from './ActivityInformation';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

interface ToDoInMoveProps {
    title: string;
    excerpt: string;
    featuredImage: any;
    blocks: any;
    content: any;
    acf?: any;
    language: string;
}

export default function ToDoInMove(props: ToDoInMoveProps): JSX.Element {
    const { title, excerpt, featuredImage, blocks, acf, language } = props;
    return (
        <>
            <div className="row center">
                <div className="column large-18 medium-20 small-full">
                    <h1>{title}</h1>
                    {acf.longsubtitle && (
                        <p className="subtitle" dangerouslySetInnerHTML={{ __html: acf.longsubtitle }}></p>
                    )}
                </div>
            </div>

            <ActivityInformation {...acf} language={language} />

            {featuredImage !== '' && (
                <div className="row width-100 center">
                    <div className="column medium-22 small-full">
                        <LazyLoadImage
                            className="width-100"
                            src={featuredImage.url}
                            effect="opacity"
                            width={featuredImage.width}
                            height={featuredImage.height}
                            alt={featuredImage.alt}
                        />
                    </div>
                </div>
            )}

            <div className="row width-100 center content">
                <div className="column medium-12 large-16 small-full">
                    <div className="row introduction">
                        <div dangerouslySetInnerHTML={{ __html: excerpt }} />
                    </div>
                </div>
                <GutenbergBlocks language={language} blocks={blocks} full={true} />
            </div>

            {acf.ticker && <Ticker content={acf.ticker} />}
            {acf.additional_content !== null && (
                <div className="row width-100 center content--additional">
                    <div className="column medium-20 small-full">
                        <div className="gutenberg-blocks row center">
                            {acf.additional_content && (
                                <>
                                    <div className="column medium-14 small-full">
                                        {acf.additional_content.image && (
                                            <figure>
                                                <LazyLoadImage
                                                    src={acf.additional_content.image}
                                                    effect="opacity"
                                                    alt={acf.additional_content.heading}
                                                />
                                            </figure>
                                        )}
                                    </div>
                                    <div className="column medium-10 small-full">
                                        <h2>{acf.additional_content.heading}</h2>
                                        <div dangerouslySetInnerHTML={{ __html: acf.additional_content.text }} />
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
