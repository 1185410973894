import React, { Component } from 'react';
import { GoogleMapProvider, MapBox, Marker } from '@googlemap-react/core';
import markerImage from '../../../Assets/images/marker.png';

export default class Maps extends Component {
    public render(): JSX.Element {
        const lat = 52.3432209;
        const lng = 4.8563899;
        const zoom = 16;

        return (
            <div className="row center">
                <div className="column large-12 medium-16 small-full" id="moveMap">
                    <GoogleMapProvider>
                        <MapBox
                            apiKey="AIzaSyD58og65Kzn5JTgXnE4LGj5L88GWDIq6EY"
                            opts={{
                                center: { lat: lat, lng: lng },
                                zoom: zoom,
                                mapTypeControl: false,
                                styles: [
                                    {
                                        featureType: 'all',
                                        elementType: 'labels.text.fill',
                                        stylers: [
                                            {
                                                saturation: 36,
                                            },
                                            {
                                                color: '#000000',
                                            },
                                            {
                                                lightness: 40,
                                            },
                                        ],
                                    },
                                    {
                                        featureType: 'all',
                                        elementType: 'labels.text.stroke',
                                        stylers: [
                                            {
                                                visibility: 'on',
                                            },
                                            {
                                                color: '#000000',
                                            },
                                            {
                                                lightness: 16,
                                            },
                                        ],
                                    },
                                    {
                                        featureType: 'all',
                                        elementType: 'labels.icon',
                                        stylers: [
                                            {
                                                visibility: 'off',
                                            },
                                        ],
                                    },
                                    {
                                        featureType: 'administrative',
                                        elementType: 'geometry.fill',
                                        stylers: [
                                            {
                                                color: '#000000',
                                            },
                                            {
                                                lightness: 20,
                                            },
                                        ],
                                    },
                                    {
                                        featureType: 'administrative',
                                        elementType: 'geometry.stroke',
                                        stylers: [
                                            {
                                                color: '#000000',
                                            },
                                            {
                                                lightness: 17,
                                            },
                                            {
                                                weight: 1.2,
                                            },
                                        ],
                                    },
                                    {
                                        featureType: 'landscape',
                                        elementType: 'geometry',
                                        stylers: [
                                            {
                                                color: '#000000',
                                            },
                                            {
                                                lightness: 20,
                                            },
                                        ],
                                    },
                                    {
                                        featureType: 'poi',
                                        elementType: 'geometry',
                                        stylers: [
                                            {
                                                color: '#000000',
                                            },
                                            {
                                                lightness: 21,
                                            },
                                        ],
                                    },
                                    {
                                        featureType: 'road.highway',
                                        elementType: 'geometry.fill',
                                        stylers: [
                                            {
                                                color: '#ffffff',
                                            },
                                            {
                                                lightness: -40,
                                            },
                                        ],
                                    },
                                    {
                                        featureType: 'road.highway',
                                        elementType: 'geometry.stroke',
                                        stylers: [
                                            {
                                                color: '#ffffff',
                                            },
                                            {
                                                lightness: -40,
                                            },
                                            {
                                                weight: 0.2,
                                            },
                                        ],
                                    },
                                    {
                                        featureType: 'road.arterial',
                                        elementType: 'geometry',
                                        stylers: [
                                            {
                                                color: '#ffffff',
                                            },
                                            {
                                                lightness: -70,
                                            },
                                        ],
                                    },
                                    {
                                        featureType: 'road.local',
                                        elementType: 'geometry',
                                        stylers: [
                                            {
                                                color: '#ffffff',
                                            },
                                            {
                                                lightness: -70,
                                            },
                                        ],
                                    },
                                    {
                                        featureType: 'transit',
                                        elementType: 'geometry',
                                        stylers: [
                                            {
                                                color: '#000000',
                                            },
                                            {
                                                lightness: 19,
                                            },
                                        ],
                                    },
                                    {
                                        featureType: 'water',
                                        elementType: 'geometry',
                                        stylers: [
                                            {
                                                color: '#000000',
                                            },
                                            {
                                                lightness: 17,
                                            },
                                        ],
                                    },
                                ],
                            }}
                            style={{
                                height: '500px',
                                width: '100%',
                                marginBottom: '30px',
                            }}
                        />
                        <Marker
                            id="marker"
                            opts={{
                                position: { lat: lat, lng: lng },
                                icon: markerImage,
                            }}
                        />
                    </GoogleMapProvider>
                </div>
            </div>
        );
    }
}
