import React from 'react';
import NewParallax from '../Components/Elements/NewParallax';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

interface Props {
    practicalInformation: {
        image_1: string;
        image_2: string;
        textblock_1: string;
        textblock_2: string;
        title_1: string;
        title_2: string;
    };
    featuredImage: string;
}

export default function PracticalInfo(props: Props): JSX.Element {
    const {
        practicalInformation: {
            image_1: image1,
            image_2: image2,
            textblock_1: textblock1,
            textblock_2: textblock2,
            title_1: title1,
            title_2: title2,
        },
        featuredImage,
    } = props;

    return (
        <>
            <div className="practical-info-block">
                <div className="practical-info-block_first">
                    <div className="practical-info-block_first_image1_container">
                        <LazyLoadImage src={image1} effect="opacity" alt={`${title1}, Move building`} />
                    </div>

                    <div className="practical-info-block_first_text1">
                        <h3>{title1}</h3>
                        <div dangerouslySetInnerHTML={{ __html: textblock1 }} />
                    </div>
                </div>
                <div className="practical-info-block_second">
                    <div className="practical-info-block_second_text2">
                        <h3>{title2}</h3>
                        <div dangerouslySetInnerHTML={{ __html: textblock2 }} />
                    </div>
                    <div className="practical-info-block_second_image2_container">
                        <LazyLoadImage src={image2} effect="opacity" alt={title2} />
                    </div>
                </div>
            </div>
            <div className="building-picture-container">
                <NewParallax className="building-picture" image={featuredImage} alt={title2} />
            </div>
        </>
    );
}
