import { Component } from 'react';
import axios from 'axios';
require('dotenv').config();

interface Props {}

interface State {
    sitemap: string;
}

export default class SiteMap extends Component<Props, State> {
    public componentDidMount = async (): Promise<void> => {
        const baseURL = process.env.REACT_APP_API_URL;
        const apiURL = `${baseURL}/sitemap/v1/sitemap`;
        let res = await axios.get(apiURL);
        let { data } = await res;

        const toXml = (data: any): void => {
            return data.reduce((result: any, el: any): any => {
                return (
                    result +
                    `<url><loc>${el.url.loc}</loc><lastmod>${el.url.lastmod}</lastmod><changefreq>${el.url.changefreq}</changefreq></url>\n`
                );
            }, '');
        };

        const xmlData = toXml(data);

        const pre =
            '<?xml version="1.0" encoding="UTF-8"?><urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://www.sitemaps.org/schemas/sitemap/0.9 http://www.sitemaps.org/schemas/sitemap/0.9/sitemap.xsd">';
        const post = '</urlset>';

        const sitemap = pre + xmlData + post;

        this.setState({
            sitemap: sitemap,
        });

        const blob = new Blob([sitemap], { type: 'application/xml' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.download = 'sitemap.xml';
        link.href = url;
        link.click();
    };

    public render(): null {
        return null;
    }
}
