import React, { useEffect, useState } from 'react';
import * as Sentry from '@sentry/browser';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import Swiper from 'swiper';
import 'swiper/css/swiper.min.css';
import { useMediaQuery } from 'react-responsive';

enum InstagramMediaType {
    IMAGE = 'IMAGE',
    VIDEO = 'VIDEO',
}
interface InstagramMediaList {
    media: InstagramMedia[];
    mobile?: boolean;
}
interface InstagramMedia {
    caption: string;
    id: string;
    media_type: string;
    media_url: string;
    permalink: string;
    thumbnail_url: string;
}
interface MediaListProps {
    media: string;
}
interface ImageProps {
    src: string;
    permalink: string;
    showPlayIcon: boolean;
    alt: string;
    mobile?: boolean;
}
interface InstagramProps {
    instagram: {
        description: string;
        title: string;
    };
    language: string;
}

function PostImage(props: ImageProps): JSX.Element {
    const { src, permalink, showPlayIcon, alt, mobile } = props;
    return (
        <a
            href={permalink}
            target={'_blank'}
            rel={'noopener noreferrer'}
            className={`media-item ${mobile ? 'swiper-slide' : ''}`}
        >
            {showPlayIcon && (
                <FontAwesomeIcon className="insta-media-play-icon" icon={faPlay} color="white" size="lg" />
            )}
            <img className="insta-media" src={src} alt={alt} />
        </a>
    );
}

function CreateMediaList(props: InstagramMediaList): JSX.Element {
    const { media } = props;

    return (
        <div className="media-wrapper">
            {media.map(
                (m: InstagramMedia): JSX.Element => (
                    <PostImage
                        key={`elemtKey-${m.permalink}`}
                        permalink={m.permalink}
                        src={m.media_type === InstagramMediaType.VIDEO ? m.thumbnail_url : m.media_url}
                        showPlayIcon={m.media_type === InstagramMediaType.VIDEO}
                        alt={m.caption}
                    />
                ),
            )}
        </div>
    );
}

function CreateMediaSlider(props: InstagramMediaList): JSX.Element {
    const { media } = props;

    return (
        <div className="swiper-wrapper">
            {media.map(
                (m: InstagramMedia): JSX.Element => (
                    <PostImage
                        mobile={true}
                        key={`elemtKey-${m.permalink}`}
                        permalink={m.permalink}
                        src={m.media_type === InstagramMediaType.VIDEO ? m.thumbnail_url : m.media_url}
                        showPlayIcon={m.media_type === InstagramMediaType.VIDEO}
                        alt={m.caption}
                    />
                ),
            )}
        </div>
    );
}

export default function InstagramContainer(props: InstagramProps): JSX.Element {
    const {
        instagram: { title, description },
    } = props;

    const [instaFeed, setInstaFeed] = useState([]);
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 991px)' });

    async function getInstagramFeed(): Promise<void> {
        const baseURL = process.env.REACT_APP_API_URL;
        try {
            let res = await axios.get(`${baseURL}/instagram/v1/get_images`);
            let { data } = await res;
            setInstaFeed(data);
        } catch (error) {
            Sentry.setExtra('errorData', error);
            Sentry.captureException(error);
        }
    }

    useEffect((): void => {
        getInstagramFeed();
        
        // breakpoint where swiper will be destroyed
        // and switches to a dual-column layout
        const breakpoint = window.matchMedia('(max-width:991px)');

        // keep track of swiper instances to destroy later
        let instaSlider: any;

        const enableSwiper = function(): void {
            instaSlider = new Swiper('.swiper-container-instagram', {
                spaceBetween: 6,
                loop: true,
                slidesPerView: 3,
                allowTouchMove: true,
                direction: 'horizontal',
                grabCursor: true,
                centeredSlides: true,
                loopFillGroupWithBlank: false,
                lazy: {
                    loadPrevNext: true,
                    loadPrevNextAmount: 3,
                },
            });
        };

        const breakpointChecker = function(): void {
            // else if a small viewport and single column layout needed
            if (breakpoint.matches === true) {
                // fire small viewport version of swiper
                return enableSwiper();

                // if larger viewport and multi-row layout needed
            } else if (breakpoint.matches === false) {
                // clean up old instances and inline styles when available
                if (instaSlider !== undefined) instaSlider.destroy(true, true);

                // or/and do nothing
                return;
            }
        };

        // keep an eye on viewport size changes
        breakpoint.addListener(breakpointChecker);

        // kickstart
        breakpointChecker();
    }, []);

    const mediaTrioLeftList = instaFeed.slice(0, 3);
    const mediaTrioRightList = instaFeed.slice(3, 6);

    return (
        <>
            <div className="instagram-container">
                {isTabletOrMobile ? (
                    <>
                        <div className="follow-us">
                            <h4 dangerouslySetInnerHTML={{ __html: title }} />
                            <span dangerouslySetInnerHTML={{ __html: description }} />
                        </div>
                        <div className="swiper-container-instagram">
                            <CreateMediaSlider media={instaFeed} />
                        </div>
                    </>
                ) : (
                    <>
                        <div className="row media-trio-left">
                            <CreateMediaList media={mediaTrioLeftList} />
                            <div className="follow-us">
                                <h4 dangerouslySetInnerHTML={{ __html: title }} />
                                <span dangerouslySetInnerHTML={{ __html: description }} />
                            </div>
                        </div>
                        <div className="row media-trio-right hide-for-medium-down">
                            <CreateMediaList media={mediaTrioRightList} />
                        </div>
                        <div className="row media-trio-right hide-for-large-up">
                            <CreateMediaList media={mediaTrioLeftList} />
                        </div>
                    </>
                )}
            </div>
        </>
    );
}
