import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import './Assets/styles/app.scss';
import Root from './Root';
import { fbq } from 'react-facebook-pixel';

if ('move.amsterdam' === window.location.hostname) {
    Sentry.init({ dsn: 'https://7a1120916f2244ebbce00dcc48d2fff0@sentry.io/1512365' });
}

setTimeout(():void => {
    fbq('track', 'QualityVisit60');  
}, 60000)

ReactDOM.render(<Root />, document.getElementById('root'));
