import React from 'react';
import Malarquee from 'react-malarquee';

interface TickerProps {
    content: string;
}

export default function Ticker(props: TickerProps): JSX.Element {
    const { content } = props;

    return (
        <>
            <div className="row width-100 center">
                <div className="ticker">
                    <Malarquee>{content}</Malarquee>
                </div>
            </div>
        </>
    );
}
