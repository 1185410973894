import React, { Component, Fragment } from 'react';
import Helmet from 'react-helmet';
import * as Sentry from '@sentry/browser';

import axios from 'axios';

import ExhibitionPage from '../Content/ExhibitionPage';
import MobilityPage from '../Content/MobilityPage';
import ToDoInMove from '../Content/ToDoInMovePage';
import PlanVisit from '../Content/PlanVisitPage';
import GutenbergBlocks from '../Content/GutenbergBlocks';
import Faq from '../Faq/Faq';
import NewsPage from '../Content/NewsPage';

interface Props {
    pageId: number;
    link: string;
    language: string;
    slug?: any;
}

/*
 * template is only used for pages not using the default page template
 */

interface State {
    title: string;
    excerpt: string;
    featuredImageFull: any;
    blocks: any;
    content: string;
    yoastMeta: string[];
    ogDescription?: string;
    template?: string;
    acf?: any;
    id: string;
}

export default class ContentArea extends Component<Props, State> {
    private constructor(props: Props) {
        super(props);
        this.state = {
            title: '',
            excerpt: '',
            featuredImageFull: '',
            blocks: '',
            content: '',
            yoastMeta: [],
            template: '',
            acf: [],
            id: '',
        };
    }

    private getPageData = async (): Promise<void> => {
        const baseURL = process.env.REACT_APP_API_URL;
        const apiURL = `${baseURL}/wp/v2/pages/${this.props.pageId}`;
        try {
            let res = await axios.get(apiURL);
            let { data } = await res;
            this.setState({
                title: data.title.rendered,
                excerpt: data.excerpt.rendered,
                featuredImageFull: data.featured_image_full,
                blocks: data.blocks ? data.blocks : '',
                content: data.content,
                yoastMeta: data.yoast_meta,
                template: data.template,
                acf: data.acf,
                id: data.id,
            });
        } catch (error) {
            Sentry.setExtra('errorData', error);
            Sentry.captureException(error);
        }
    };

    public componentDidUpdate = async (): Promise<void> => {
        // Check URL if it has a anchor tag
        if (window.location.hash) {
            let el = '';
            if (window.location.hash.endsWith('/')) {
                el = window.location.hash.substring(1, window.location.hash.length - 1);
            } else {
                el = window.location.hash.substring(1);
            }
            const anchorId = document.getElementById(el) as HTMLElement;
            if (anchorId) {
                window.scrollTo(0, anchorId.offsetTop - 100);
            }
        }
    };

    public componentDidMount = async (): Promise<void> => {
        await this.getPageData();
        window.scrollTo(0, 0);

        // Check URL if it has a anchor tag
        if (window.location.hash) {
            let el = '';
            if (window.location.hash.endsWith('/')) {
                el = window.location.hash.substring(1, window.location.hash.length - 1);
            } else {
                el = window.location.hash.substring(1);
            }
            const anchorId = document.getElementById(el) as HTMLElement;
            if (anchorId) {
                window.scrollTo(0, anchorId.offsetTop - 150);
            }
        }
    };

    private createContentArea(): JSX.Element {
        const { title, excerpt, featuredImageFull, blocks, content, yoastMeta, template, acf, id }: State = this.state;
        const { link, language, slug }: Props = this.props;

        const yoastMetaValues: any = [];
        yoastMeta.forEach(function(element: any): void {
            if ('description' === element.name || 'description' === element.property) {
                yoastMetaValues['description'] = element.content;
            } else if ('og:title' === element.name || 'og:title' === element.property) {
                yoastMetaValues['ogTitle'] = element.content;
            } else if ('og:site_name' === element.name || 'og:site_name' === element.property) {
                yoastMetaValues['ogSiteName'] = element.content;
            } else if ('twitter:card' === element.name || 'twitter:card' === element.property) {
                yoastMetaValues['twitterCard'] = element.content;
            } else if ('twitter:description' === element.name || 'twitter:description' === element.property) {
                yoastMetaValues['twitterDescription'] = element.content;
            } else if ('twitter:title' === element.name || 'twitter:title' === element.property) {
                yoastMetaValues['twitterTitle'] = element.content;
            } else if ('og:image' === element.name || 'og:image' === element.property) {
                yoastMetaValues['ogImage'] = element.content;
            } else if ('og:image:width' === element.name || 'og:image:width' === element.property) {
                yoastMetaValues['ogImageWidth'] = element.content;
            } else if ('og:image:height' === element.name || 'og:image:height' === element.property) {
                yoastMetaValues['ogImageHeight'] = element.content;
            }
        });

        // Add the connected WordPress template to the content area.
        let templateClass = template ? template.replace('.php', '') : '';

        return (
            <Fragment>
                <Helmet>
                    <title>{yoastMetaValues.ogTitle}</title>
                    {yoastMetaValues.description && <meta name="description" content={yoastMetaValues.description} />}
                    {yoastMetaValues.ogTitle && <meta property="og:title" content={yoastMetaValues.ogTitle} />}
                    {yoastMetaValues.description && (
                        <meta property="og:description" content={yoastMetaValues.description} />
                    )}
                    <meta property="og:url" content={link} />
                    {yoastMetaValues.ogSiteName && (
                        <meta property="og:site_name" content={yoastMetaValues.ogSiteName} />
                    )}
                    {yoastMetaValues.ogImage && <meta property="og:image" content={yoastMetaValues.ogImage} />}
                    {yoastMetaValues.ogImageWidth && (
                        <meta property="og:image:width" content={yoastMetaValues.ogImageWidth} />
                    )}
                    {yoastMetaValues.ogImageHeight && (
                        <meta property="og:image:height" content={yoastMetaValues.ogImageHeight} />
                    )}
                    {yoastMetaValues.twitterCard && <meta name="twitter:card" content={yoastMetaValues.twitterCard} />}
                    {yoastMetaValues.twitterDescription && (
                        <meta name="twitter:description" content={yoastMetaValues.twitterDescription} />
                    )}
                    {yoastMetaValues.twitterTitle && (
                        <meta name="twitter:title" content={yoastMetaValues.twitterTitle} />
                    )}
                    <meta name="twitter:card" content="summary_large_image" />
                    {yoastMetaValues.ogImage && <meta name="twitter:image" content={yoastMetaValues.ogImage} />}
                </Helmet>
                <div className={'content-page ' + templateClass}>
                    {template === 'template-mobility.php' && (
                        <MobilityPage
                            title={title}
                            excerpt={excerpt}
                            featuredImage={featuredImageFull}
                            blocks={blocks}
                            content={content}
                            acf={acf}
                            language={language}
                            id={id}
                        />
                    )}

                    {template === 'template-building.php' && (
                        <ExhibitionPage
                            title={title}
                            excerpt={excerpt}
                            featuredImage={featuredImageFull}
                            blocks={blocks}
                            content={content}
                            acf={acf}
                            language={language}
                            id={id}
                            slug={slug}
                        />
                    )}

                    {template === 'template-inside-building.php' && (
                        <ToDoInMove
                            content={content}
                            title={title}
                            excerpt={excerpt}
                            featuredImage={featuredImageFull}
                            blocks={blocks}
                            acf={acf}
                            language={language}
                        />
                    )}

                    {template === 'template-plan-visit.php' && (
                        <PlanVisit
                            title={title}
                            excerpt={excerpt}
                            featuredImage={featuredImageFull}
                            blocks={blocks}
                            content={content}
                            language={language}
                        />
                    )}

                    {template === 'template-news.php' && (
                        <NewsPage content={content} title={title} acf={acf} language={language} blocks={blocks} />
                    )}

                    {template !== 'template-news.php' &&
                        template !== 'template-inside-building.php' &&
                        template !== 'template-building.php' &&
                        template !== 'template-plan-visit.php' &&
                        template !== 'template-mobility.php' && (
                            <>
                                <div className="row center">
                                    <div className="column medium-14 small-full">
                                        <h1>{title}</h1>
                                    </div>
                                </div>
                                <div className="row center">
                                    <div className="column medium-20 small-full">
                                        <GutenbergBlocks language={language} blocks={blocks} full={false} />
                                    </div>
                                </div>
                            </>
                        )}

                    {template === 'template-faq.php' && <Faq language={language} />}
                </div>
            </Fragment>
        );
    }

    public render(): JSX.Element {
        const contentArea = this.createContentArea();
        return contentArea;
    }
}
