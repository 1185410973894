import React from 'react';
import { fbq } from 'react-facebook-pixel';

interface Props {
    language: string;
    elements: any;
}

export default function ExhibitionDetails(props: Props): JSX.Element {
    let { language, elements } = props;

    return (
        <div className="row center details">
            <div className="column medium-20">
                <div className="row inner-row">
                    {(elements.location || elements.duration || elements.date || elements.time) && (
                        <div className="column small-full medium-9">
                            <h5 className="details__heading">{language === 'nl' ? 'Informatie' : 'Information'}</h5>
                            {elements.date && (
                                <div className="details__line">
                                    <span className="details__title">{language === 'nl' ? 'Datum' : 'Date'}:</span>
                                    <span>{elements.date}</span>
                                </div>
                            )}
                            {elements.time && (
                                <div className="details__line">
                                    <span className="details__title">{language === 'nl' ? 'Tijd' : 'Time'}:</span>
                                    <span>{elements.time}</span>
                                </div>
                            )}
                            {elements.location && (
                                <div className="details__line">
                                    <span className="details__title">{language === 'nl' ? 'Plaats' : 'Location'}:</span>
                                    <span>{elements.location}</span>
                                </div>
                            )}
                            {elements.duration && (
                                <div className="details__line">
                                    <span className="details__title">
                                        {language === 'nl' ? 'Looptijd' : 'Duration'}:
                                    </span>
                                    <span>{elements.duration}</span>
                                </div>
                            )}
                            {elements.url && (
                                <div className="details__line hide-for-medium-down">
                                    <a
                                        href={elements.url}
                                        className="wp-block-button__link details__btn"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        onClick={(): void => {
                                            fbq('track', 'ButtonBoekTickets');  
                                        }}
                                    >
                                        {language === 'nl' ? 'Boek tickets' : 'Book tickets'}
                                    </a>
                                </div>
                            )}
                        </div>
                    )}
                    {elements.price && (
                        <div className="column small-full medium-9">
                            <h5 className="details__heading">{language === 'nl' ? 'Prijs' : 'Price'}</h5>
                            <p className="details__line">{elements.price}</p>
                        </div>
                    )}

                    {elements.capacity && (
                        <div className="column small-full medium-6">
                            <h5 className="details__heading">{language === 'nl' ? 'Capaciteit' : 'Capacity'}</h5>
                            <p className="details__line">{elements.capacity}</p>
                            {elements.url && (
                                <div className="details__line hide-for-medium-up">
                                    <a
                                        href={elements.url}
                                        className="wp-block-button__link details__btn"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        onClick={(): void => {
                                            fbq('track', 'ButtonBoekTickets');  
                                        }}
                                    >
                                        {language === 'nl' ? 'Boek tickets' : 'Book tickets'}
                                    </a>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
