import React, { Component } from 'react';
import axios from 'axios';
import history from '../../History';

//fortawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import gsap from 'gsap/all';

interface Props {
    language: string;
    location: string;
    value?: string;
}

interface State {
    query: string;
    results: any[];
    posts: any[];
    pages: any[];
    faq: any[];
    visible: boolean;
    loading: boolean;
}

export default class Search extends Component<Props, State> {
    private constructor(props: Props) {
        super(props);
        this.state = {
            query: this.props.value ? this.props.value : '',
            results: [],
            posts: [],
            pages: [],
            faq: [],
            visible: false,
            loading: false,
        };
        this.handleOnInputChange = this.handleOnInputChange.bind(this);
    }

    public toggleSearch = async (): Promise<void> => {
        const nav = document.getElementById('header') as HTMLElement;
        const searchContainer = document.querySelector('.search-container') as HTMLElement;
        this.setState({ visible: !this.state.visible }, (): void => {
            if (this.state.visible) {
                const searchInput = document.getElementById('search-input') as HTMLElement;
                searchInput.focus();
                nav.classList.add('search-active');
                if(!searchContainer.classList.contains('mobile')) {
                    gsap.timeline()
                        .to('.search-container', { duration: 0.3, autoAlpha: 1 })
                        .to('.search-inner', { duration: 0.3, delay: -0.3, autoAlpha: 1 })
                        .to('.search-fields', { duration: 0.3, delay: 0.1, y: 0, autoAlpha: 1 });
                }
            } else {
                nav.classList.remove('search-active');
                if(!searchContainer.classList.contains('mobile')) {
                    gsap.timeline()
                        .to('.search-fields', { duration: 0.3, y: 30, autoAlpha: 0 })
                        .to('.search-inner', { duration: 0.3, delay: 0.1, autoAlpha: 0 })
                        .to('.search-container', { duration: 0.3, delay: -0.3, autoAlpha: 0 });
                }
            }
        });
    };

    public handleOnInputChange = (event: any): void => {
        const query = event.target.value;
        this.setState({ query });
    };

    public toggleLoading = (): void => {
        this.setState({ loading: !this.state.loading }, (): void => {
            if (this.props.location === 'searchpage') {
                const searchResults = document.getElementById('search-results') as HTMLElement;
                if (this.state.loading) {
                    searchResults.classList.add('loading');
                } else {
                    searchResults.classList.remove('loading');
                }
            }
        });
    };

    public submit = async (event: any): Promise<void> => {
        event.preventDefault();

        const query = this.state.query;

        if (query) {
            this.toggleLoading();

            await this.searchPosts(query);
            await this.searchPages(query);
            await this.searchFaq(query);

            // Combine posts and pages result
            const res: any[] = [...this.state.pages, ...this.state.posts, ...this.state.faq];
            // Save result to state
            this.setState({ results: res });

            this.toggleSearch();

            this.toggleLoading();

            // Compose path depending on language
            let path = '/s/';
            if (this.props.language === 'en') {
                path = '/en/s/';
            }

            // Redirect to searchresults page
            history.push({
                pathname: path,
                state: { results: this.state.results, query: query },
            });
        }
    };

    public fetchSearchResults = async (
        type: string,
        apiURL: string,
        totalPages: number,
        totalResults: number,
    ): Promise<void> => {
        const content: any = [];

        // in case of zero results, send empty JSON object to component
        if (totalResults === 0) {
            if (type === 'pages') {
                this.setState({ pages: [] });
            } else if (type === 'faq') {
                this.setState({ faq: [] });
            } else {
                this.setState({ posts: [] });
            }
        } else {
            for (let i = 1; i <= totalPages; i++) {
                let res = await axios.get(apiURL + '&page=' + i);
                let { data } = await res;

                content.push(...data);

                if (content.length === totalResults) {
                    if (type === 'pages') {
                        this.setState({ pages: content });
                    } else if (type === 'faq') {
                        this.setState({ faq: content });
                    } else {
                        this.setState({ posts: content });
                    }
                }
            }
        }
    };

    public searchPosts = async (query: string): Promise<void> => {
        const baseURL = process.env.REACT_APP_API_URL;
        const apiURL = `${baseURL}/wp/v2/posts/?search=${query}&lang=${this.props.language}`;
        let res = await axios.get(apiURL);

        const totalPages = parseInt(res.headers['x-wp-totalpages']);
        const totalResults = parseInt(res.headers['x-wp-total']);

        await this.fetchSearchResults('posts', apiURL, totalPages, totalResults);
    };

    public searchPages = async (query: string): Promise<void> => {
        const baseURL = process.env.REACT_APP_API_URL;
        const apiURL = `${baseURL}/wp/v2/pages/?search=${query}&lang=${this.props.language}`;
        let res = await axios.get(apiURL);

        const totalPages = parseInt(res.headers['x-wp-totalpages']);
        const totalResults = parseInt(res.headers['x-wp-total']);

        await this.fetchSearchResults('pages', apiURL, totalPages, totalResults);
    };

    public searchFaq = async (query: string): Promise<void> => {
        const baseURL = process.env.REACT_APP_API_URL;
        const apiURL = `${baseURL}/wp/v2/move-faq/?search=${query}&lang=${this.props.language}`;
        let res = await axios.get(apiURL);

        const totalPages = parseInt(res.headers['x-wp-totalpages']);
        const totalResults = parseInt(res.headers['x-wp-total']);

        await this.fetchSearchResults('faq', apiURL, totalPages, totalResults);
    };

    public closeSearchOnOutsideClick = (event: any): void => {
        // Assign popup element to variable
        let searchEl = document.getElementById('search-container') as HTMLElement;
        let header = document.getElementsByTagName('header');
        if (searchEl) {
            // Assign target to variable
            let target = event.target as HTMLElement;
            // If the popup is visible, the variable will be set to true
            let visible = header[0].classList.contains('search-active');
            if (visible) {
                // When the user clicks outside the popup
                if (target.classList.contains('search-container')) {
                    // Toggle the show/hide function of the popup
                    this.toggleSearch();
                }
            }
        }
    };

    public componentDidMount = (): void => {
        window.addEventListener('click', this.closeSearchOnOutsideClick);
    };

    public render(): JSX.Element {
        const { query, visible } = this.state;
        const { location } = this.props;

        let isMobile =  window.innerWidth <= 960 ? true : false;

        window.addEventListener('resize', (): void => {
            isMobile = window.innerWidth <= 960 ? true : false;
        });

        const language = this.props.language;
        let placeholder = language === 'nl' ? 'Zoeken' : 'Search';

        if (location === 'header') {
            return (
                <>
                    <div className="search-toggle" id="search-icon" onClick={this.toggleSearch}>
                        <svg
                            width="0.9rem"
                            height="0.9rem"
                            viewBox="0 0 13 13"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M9.78069 5.29787C9.78069 7.77367 7.77366 9.78069 5.29787 9.78069C2.82208 9.78069 0.815058 7.77367 0.815058 5.29787C0.815058 2.82208 2.82208 0.815058 5.29787 0.815058C7.77366 0.815058 9.78069 2.82208 9.78069 5.29787ZM9.16148 8.92282C8.19499 9.95253 6.82155 10.5957 5.29787 10.5957C2.37194 10.5957 0 8.22381 0 5.29787C0 2.37194 2.37194 0 5.29787 0C8.22381 0 10.5957 2.37194 10.5957 5.29787C10.5957 6.40533 10.2559 7.43343 9.67491 8.28358L12.9999 11.6086L12.4235 12.1849L9.16148 8.92282Z"
                                fill="black"
                            />
                        </svg>
                    </div>

                    <div
                        className={`search-container search-container-header 
                        ${visible && `show`} ${!visible && `hide`} ${isMobile && `mobile`}`}
                        id="search-container"
                    >
                        <div className="row">
                            <div className="large-full">
                                <div className="search-inner">
                                    <div className="search-fields">
                                        <form onSubmit={this.submit}>
                                            <input
                                                type="text"
                                                id="search-input"
                                                placeholder={placeholder}
                                                value={query}
                                                onChange={this.handleOnInputChange}
                                            />
                                            <button type="submit" className="search-submit">
                                                {this.state.loading ? (
                                                    <FontAwesomeIcon
                                                        className="fa-fw search-icon fa-spin"
                                                        icon={faSpinner}
                                                    />
                                                ) : (
                                                    <FontAwesomeIcon className="fa-fw search-icon" icon={faSearch} />
                                                )}
                                            </button>
                                            <button className="search-exit" onClick={this.toggleSearch}>
                                                <FontAwesomeIcon icon={faTimes} className="fas fa-times" />
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            );
        } else if (location === 'hamburger') {
            return (
                <div className={`search-container search-container-hamburger ${isMobile && `mobile`}`}>
                    <div className="search-inner">
                        <div className="search-fields">
                            <form onSubmit={this.submit}>
                                <button type="submit" className="search-submit">
                                    {this.state.loading ? (
                                        <FontAwesomeIcon className="fa-fw search-icon fa-spin" icon={faSpinner} />
                                    ) : (
                                        <FontAwesomeIcon className="fa-fw search-icon" icon={faSearch} />
                                    )}
                                </button>
                                <input
                                    type="text"
                                    id="search-input"
                                    placeholder={placeholder}
                                    value={query}
                                    onChange={this.handleOnInputChange}
                                />
                            </form>
                        </div>
                    </div>
                </div>
            );
        } else if (location === 'searchpage') {
            return (
                <div className={`search-container search-container-page  ${isMobile && `mobile`}`}>
                    <div className="row">
                        <div className="column">
                            <div className="search-inner">
                                <div className="search-fields">
                                    <form onSubmit={this.submit}>
                                        <div className="form-inner">
                                            <button type="submit" className="search-submit">
                                                {this.state.loading ? (
                                                    <FontAwesomeIcon
                                                        className="fa-fw search-icon fa-spin"
                                                        icon={faSpinner}
                                                    />
                                                ) : (
                                                    <svg
                                                        viewBox="0 0 13 13"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M9.78069 5.29787C9.78069 7.77367 7.77366 9.78069 5.29787 9.78069C2.82208 9.78069 0.815058 7.77367 0.815058 5.29787C0.815058 2.82208 2.82208 0.815058 5.29787 0.815058C7.77366 0.815058 9.78069 2.82208 9.78069 5.29787ZM9.16148 8.92282C8.19499 9.95253 6.82155 10.5957 5.29787 10.5957C2.37194 10.5957 0 8.22381 0 5.29787C0 2.37194 2.37194 0 5.29787 0C8.22381 0 10.5957 2.37194 10.5957 5.29787C10.5957 6.40533 10.2559 7.43343 9.67491 8.28358L12.9999 11.6086L12.4235 12.1849L9.16148 8.92282Z"
                                                            fill="black"
                                                        />
                                                    </svg>
                                                )}
                                            </button>
                                            <input
                                                type="text"
                                                id="search-input"
                                                placeholder={placeholder}
                                                value={query}
                                                onChange={this.handleOnInputChange}
                                            />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return <div>No location specified</div>;
        }
    }
}
