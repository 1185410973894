import React from 'react';
import logoAdamMuseum from '../../../Assets/images/logo-adam-museum.jpg';
import logoSolar from '../../../Assets/images/logo-solar.jpg';
import logoAwair from '../../../Assets/images/logo-awair.png';
import logoEvo from '../../../Assets/images/logo-evo.png';
import logoDatalab from '../../../Assets/images/logo-datalab.png';
import logoSedric from '../../../Assets/images/logo-sedric.jpg';
import logoUitvinders from '../../../Assets/images/logo-uitvinders.png';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

interface Props {
    language: string;
}

export default function GutenbergBlocks(props: Props): JSX.Element {
    let { language } = props;

    const partners = [
        {
            logo: logoAdamMuseum,
            textNL: 'De Witkar in de tentoonstelling Move the City werd aan ons uitgeleend door het Amsterdam Museum.',
            textEN: 'The Witkar in the exhibition Move the City was lent to us by the Amsterdam Museum.',
            alt: 'Amsterdam Museum',
        },
        {
            logo: logoSolar,
            textNL:
                'De Stella Solar car in de tentoonstelling Move the City werd ter beschikking gesteld door het Solar Team van de Technische Universiteit Eindhoven.',
            textEN:
                'The Stella Solar car in the exhibition Move the City was made available by the Solar Team of the Eindhoven University of Technology.',
            alt: 'Tu/e',
        },
        {
            logo: logoAwair,
            textNL:
                'De Awair in de tentoonstelling Move the City werd ter beschikking gesteld door José Gallegos en Miguel Cabral Guerra van de Technische Universiteit Eindhoven.',
            textEN:
                'The Awair in the exhibition Move the City was made available by José Gallegos and Miguel Cabral Guerra of the Eindhoven University of Technology.',
            alt: 'Awair',
        },
        {
            logo: logoEvo,
            textNL:
                'De Evo Pod in de tentoonstelling Move the City werd ter beschikking gesteld door de Franse start-up Evo Pods.',
            textEN: 'The Evo Pod in the exhibition Move the City was made available by the French start-up Evo Pods.',
            alt: 'Evo Pods',
        },
        {
            logo: logoDatalab,
            textNL:
                'De Deepracer in de tentoonstelling Move the City werd ter beschikking gesteld door het Pon Datalab.',
            textEN: 'The DeepRacer in the exhibition Move the City was made available by the Pon Datalab.',
            alt: 'Datalab',
        },
        {
            logo: logoSedric,
            textNL:
                'De Sedric in de tentoonstelling Move the City werd ter beschikking gesteld door de Volkswagen Group.',
            textEN: 'The Sedric in the exhibition Move the City was made available by the Volkswagen Group.',
            alt: 'Sedric',
        },
        {
            logo: logoUitvinders,
            textNL:
                'In de schoolvakanties organiseren we samen met de Uitvinders activiteiten speciaal voor kinderen. Bijvoorbeeld met een 3D pen een vliegtuig ontwerpen.',
            textEN:
                'During the school holidays we organize activities together with the Inventors especially for children. For example, design an airplane with a 3D pen.',
            alt: 'Uitvinders',
        },
    ];

    return (
        <div className="row center">
            <div className="partner-panels-wrapper column small-full large-12 medium-16">
                <div className="row center">
                    {partners.map(function(partner: any, i: number): JSX.Element {
                        return (
                            <div className="partner-panel column large-8 medium-16 small-24" key={i}>
                                <div className="panel-logo">
                                    <LazyLoadImage src={partner.logo} effect="opacity" alt={partner.alt} />
                                </div>
                                <div className="panel-text">
                                    {language === 'nl' ? <p>{partner.textNL}</p> : <p>{partner.textEN}</p>}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}
