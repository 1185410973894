/**
 * @todo why does this file has its own "Cookies" directory, it is a component (modal)
 */
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import MoveLogo from '../../Assets/images/move-logo.svg';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

interface Props {
    visible: boolean;
    handleCookiesModal: Function;
    setCookiesValue: Function;
    language: string;
    cookies: any;
}

interface State {
    showDetails: boolean;
    acceptStatisticsCookies: boolean;
    acceptMarketingCookies: boolean;
}

export default class CookiesModal extends Component<Props, State> {
    private constructor(props: Props) {
        super(props);
        this.state = {
            showDetails: false,
            acceptStatisticsCookies: true,
            acceptMarketingCookies: true,
        };
    }

    public componentDidMount = async (): Promise<void> => {};

    private showDetailsHandler(): void {
        this.setState({ showDetails: !this.state.showDetails });
        this.setState({
            acceptStatisticsCookies: this.props.cookies.get('acceptStatisticsCookies')
                ? JSON.parse(this.props.cookies.get('acceptStatisticsCookies'))
                : true,
        });
        this.setState({
            acceptMarketingCookies: this.props.cookies.get('acceptMarketingCookies')
                ? JSON.parse(this.props.cookies.get('acceptMarketingCookies'))
                : false,
        });
    }

    private changeStatisticsCookies = (): void => {
        this.setState({ acceptStatisticsCookies: !this.state.acceptStatisticsCookies });
    };

    private changeMarketingCookies = (): void => {
        this.setState({ acceptMarketingCookies: !this.state.acceptMarketingCookies });
    };

    public render(): JSX.Element {
        const { visible, handleCookiesModal, setCookiesValue, language } = this.props;
        const { showDetails, acceptStatisticsCookies, acceptMarketingCookies } = this.state;
        return (
            <>
                {visible && (
                    <div className="cookies">
                        <div className="cookies-modal">
                            <div className="row">
                                <div className="column large-12 medium-12 small-12">
                                    <h2>Cookies</h2>
                                </div>
                                <div className="column large-12 medium-12 small-12 align-right">
                                    <LazyLoadImage src={MoveLogo} effect="opacity" alt="Logo Move" width="86" />
                                </div>
                            </div>
                            {!showDetails ? (
                                <div className="modal-content">
                                    {language === 'en' ? (
                                        <p>
                                            This website of Move BV uses cookies for example to improve and analyze the
                                            website, for social media and to ensure that you see relevant ads. To learn
                                            more about cookies, click here for the{' '}
                                            <Link to="/en/cookie-policy/">Cookie Policy</Link>. When you click on
                                            accept, you authorize Move BV to use marketing, statistical and essential
                                            cookies on our website. Click on Cookie Preferences to adjust your Cookie
                                            Preferences. If you want to know more about how we deal with your personal
                                            data, please read our{' '}
                                            <Link to="/en/privacy-statement/">privacy statement</Link>.
                                        </p>
                                    ) : (
                                        <p>
                                            Deze website van Move BV gebruikt cookies om bijvoorbeeld de website te
                                            verbeteren en te analyseren, voor sociale media en om ervoor te zorgen dat
                                            je relevante advertenties te zien krijgt. Als je meer wilt weten over deze
                                            cookies, klik dan hier voor het <Link to="/cookies/">Cookiebeleid</Link>.
                                            Bij akkoord geef je Move BV toestemming voor het gebruik van marketing,
                                            statistische en essentiële cookies op onze website. Klik op Cookie
                                            instellingen om je voorkeuren te wijzigen. Als je meer wilt weten over hoe
                                            wij omgaan met je persoonsgegevens, raadpleeg onze{' '}
                                            <Link to="/privacyverklaring/">privacyverklaring</Link>.
                                        </p>
                                    )}
                                    <div className="modal-content__buttons">
                                        <button
                                            className="button-modal"
                                            id="button-cookies-allowed"
                                            onClick={(): void => {
                                                setCookiesValue();
                                                handleCookiesModal(acceptStatisticsCookies, acceptMarketingCookies);
                                            }}
                                        >
                                            {language === 'en' ? `Accept` : `Akkoord`}
                                        </button>
                                        <span
                                            onClick={(): void => {
                                                this.showDetailsHandler();
                                            }}
                                            className="cookies-modal-link"
                                        >
                                            {language === 'en' ? `Cookie Preferences` : `Cookie instellingen`}
                                        </span>
                                    </div>
                                </div>
                            ) : (
                                <div className="modal-content">
                                    {language === 'en' ? (
                                        <p>
                                            Move uses the following cookies to improve and personalize your experience
                                            on this website.
                                        </p>
                                    ) : (
                                        <p>
                                            Move gebruikt onderstaande cookies om het bezoek aan de website te
                                            vergemakkelijken en persoonlijk te maken.
                                        </p>
                                    )}
                                    <div className="model-content__form">
                                        <div>
                                            <input
                                                type="checkbox"
                                                name="cookies"
                                                id="essentialCookies"
                                                value="essential"
                                                checked
                                                disabled
                                            />
                                            <label htmlFor="essentialCookies">
                                                {language === 'en'
                                                    ? `Essential cookies (required)`
                                                    : `Essentiele cookies (noodzakelijk)`}
                                            </label>
                                        </div>
                                        <div>
                                            {language === 'en' ? (
                                                <p>
                                                    These cookies are necessary to make it possible to surf our website
                                                    and use the website&apos;s functions, such as accessing protected
                                                    areas of the website.<Link to="/en/cookie-policy/">Read more</Link>.
                                                </p>
                                            ) : (
                                                <p>
                                                    Deze cookies zijn noodzakelijk om het surfen op onze website
                                                    mogelijk te maken en de functies te kunnen gebruiken, waaronder het
                                                    toegang krijgen tot beschermde onderdelen van de website.{' '}
                                                    <Link to="/cookies/">
                                                        <FormattedMessage
                                                            tagName="span"
                                                            id="News.readmore"
                                                            defaultMessage="Read More"
                                                        />
                                                    </Link>
                                                    .
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="model-content__form">
                                        <div>
                                            <input
                                                type="checkbox"
                                                name="cookies"
                                                id="statisticsCookie"
                                                value="STATISTICS"
                                                onChange={this.changeStatisticsCookies}
                                                checked={acceptStatisticsCookies}
                                            />
                                            <label htmlFor="statisticsCookie">
                                                {language === 'en' ? `Statistics cookies` : `Statistische cookies`}
                                            </label>
                                        </div>
                                        <div>
                                            {language === 'en' ? (
                                                <p>
                                                    These cookies are necessary to make it possible to surf our website
                                                    and use the website&apos;s functions, such as accessing protected
                                                    areas of the website. Anonymized analytical cookies are also
                                                    included. <Link to="/en/cookie-policy/">Read more</Link>.
                                                </p>
                                            ) : (
                                                <p>
                                                    Deze cookies verzamelen informatie over het surfgedrag van de
                                                    bezoekers op onze website. Dit stelt ons in staat de structuur,
                                                    navigatie en inhoud van de website zo gebruiksvriendelijk mogelijk
                                                    voor u te maken. <Link to="/cookies/">Lees meer</Link>.
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="model-content__form">
                                        <div>
                                            <input
                                                type="checkbox"
                                                name="cookies"
                                                id="marketingCookies"
                                                value="MARKETING"
                                                onChange={this.changeMarketingCookies}
                                                checked={acceptMarketingCookies}
                                            />
                                            <label htmlFor="marketingCookies">
                                                {language === 'en' ? `Marketing cookies` : `Marketing cookies`}
                                            </label>
                                        </div>
                                        <div>
                                            {language === 'en' ? (
                                                <p>
                                                    These cookies are used to provide advertisements that are as
                                                    relevant as possible to the individual visitors to our website. We
                                                    try to get an idea of your likely interests.{' '}
                                                    <Link to="/en/cookie-policy/">Read more</Link>.
                                                </p>
                                            ) : (
                                                <p>
                                                    Deze cookies worden gebruikt voor het aanbieden van advertenties die
                                                    zo relevant mogelijk zijn voor de individuele bezoekers van onze
                                                    website. We proberen hiervoor een beeld te krijgen van je
                                                    waarschijnlijke interesses. <Link to="/cookies/">Lees meer</Link>.
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                    <button
                                        className="button-modal"
                                        onClick={(): void => {
                                            handleCookiesModal(acceptStatisticsCookies, acceptMarketingCookies);
                                            this.setState({ showDetails: false });
                                        }}
                                    >
                                        {language === 'en' ? `Accept` : `Akkoord`}
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </>
        );
    }
}
