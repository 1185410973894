import React, { Component } from 'react';

interface Props {
    innerContent: any;
    noWrap?: boolean;
    full?: boolean;
}

export default class Button extends Component<Props> {
    public componentDidMount(): void {
        // Get the current hostname to check the href of the element against
        const currentHostname = new RegExp(window.location.hostname, 'i');
        // Loop through all the a tags of the element
        document.querySelectorAll<HTMLLinkElement>('.gutenberg-blocks a').forEach((a: HTMLLinkElement): void => {
            // Check if the href string matches the current hostname
            if (currentHostname.test(a.href)) return /* internal link */;
            // If not, add attributes to the element
            a.setAttribute('target', '_blank');
            a.setAttribute('rel', 'noopener');
        });
    }

    public render(): JSX.Element {
        const { innerContent, noWrap, full } = this.props;
        let buttonContent: any = [];

        if (innerContent.innerBlocks) {
            innerContent.innerBlocks.forEach(function(block: any) {
                buttonContent.push(block);
            });
        } else {
            buttonContent = innerContent;
        }
        if (noWrap) {
            return (
                <div className="gutenberg-button gutenberg-button-group">
                    {Object.keys(buttonContent).map((key: any): any => {
                        return <div key={key} dangerouslySetInnerHTML={{ __html: buttonContent[key].innerHTML }} />;
                    })}
                </div>
            );
        } else {
            let openingClasses = '';
            if (full) {
                openingClasses = 'column medium-20 small-full button-group';
            } else {
                openingClasses = 'column large-12 medium-16 small-full button-group';
            }

            return (
                <div className="row center">
                    <div
                        className={
                            openingClasses + ` ${innerContent.attrs.className ? innerContent.attrs.className : ''}`
                        }
                    >
                        <div className="gutenberg-button gutenberg-button-group">
                            {Object.keys(buttonContent).map((key: any): any => {
                                return (
                                    <div key={key} dangerouslySetInnerHTML={{ __html: buttonContent[key].innerHTML }} />
                                );
                            })}
                        </div>
                    </div>
                </div>
            );
        }
    }
}
