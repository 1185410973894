import { useEffect, useState } from 'react';
import axios from 'axios';
// import { useMediaQuery } from 'react-responsive';

const baseURL = process.env.REACT_APP_API_URL;
const today = ((now: Date): string => {
    const pad = (n: number): string => '000'.slice(0, 2 - String(n).length) + n;
    return `${now
        .getUTCFullYear()
        .toString()
        .slice(2)}${pad(now.getUTCMonth() + 1)}${pad(now.getUTCDate())}`;
})(new Date());

interface I18nProperty {
    rendered: string;
}

interface ExhibitionReference {
    ID: number;
    post_name: string;
}

interface FeaturedImage {
    url: string;
    width: number | null;
    height: number | null;
    alt: string;
}

export interface SparseExhibitions {
    id: number;
    title: I18nProperty;
    date: string;
    slug: string;
    excerpt: I18nProperty;
    featured_image_large: FeaturedImage;
    lang: string;
    acf?: any;
}

function getTemplateUrl(type?: string, id?: string): string {
    const params: URLSearchParams = new URLSearchParams();

    // The amount 6 is considdered 'safe' to get a set of at least 2 unique exhibitions that
    // aren't currently running.
    params.append('per_page', '6');
    if (typeof type !== 'undefined') {
        params.append('type', `${type}`);
    }
    if (typeof id !== 'undefined') {
        params.append('id', `${id}`);
    }

    return `${baseURL}/wp/v2/templates?${params}`;
}

function toProgrammerDate(nlDate: string): string {
    if (!nlDate) return '999999';
    const [day, month, year] = nlDate.split(/\D/gi);
    return `${year}${month}${day}`;
}

function getUrl(id?: number): string {
    return `${baseURL}/wp/v2/pages/${id}`;
}

function getExhibitionDate(exhibition: SparseExhibitions, dateType: string): string {
    return exhibition.acf.date ? exhibition.acf.date[dateType] : exhibition.acf[dateType];
}

function filterPastExhibitions(exhibition: SparseExhibitions): boolean {
    const endDate = toProgrammerDate(getExhibitionDate(exhibition, 'end_date'));
    return endDate < today;
}

function filterExhibitionsByLanguage(language: string, exhibit: SparseExhibitions): boolean {
    return exhibit.lang === language;
}

function sortByStartDate(exhibitionA: SparseExhibitions, exhibitionB: SparseExhibitions): number {
    const startDateA = toProgrammerDate(getExhibitionDate(exhibitionA, 'start_date'));
    const startDateB = toProgrammerDate(getExhibitionDate(exhibitionB, 'start_date'));
    return startDateA < startDateB ? -1 : startDateA > startDateB ? 1 : 0;
}

async function fetchAndFilterPosts(type: string, id: string, language: string): Promise<SparseExhibitions[]> {
    const { data: allExhibitions }: { data: ExhibitionReference[] } = await axios.get(getTemplateUrl(type, id));
    const allPages = await Promise.all(
        allExhibitions.map(
            async (exhibition: ExhibitionReference): Promise<SparseExhibitions> =>
                (await axios.get(getUrl(exhibition.ID))).data,
        ),
    );

    return allPages
        .filter(filterExhibitionsByLanguage.bind(null, language))
        .filter(filterPastExhibitions)
        .sort(sortByStartDate);
}

export default function useExhibitions(type: string, id: string, language: string): SparseExhibitions[] {

    const [posts, setPosts]: [SparseExhibitions[], Function] = useState([]);

    useEffect(
        function fetchPosts(): void {
            fetchAndFilterPosts(type, id, language).then(
                (results: SparseExhibitions[]): Promise<void> => setPosts(results.slice(results.length - 2)),
            );
        },
        [type, id, language],
    );

    return posts;
}
