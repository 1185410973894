import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import { ReactNode } from 'react';

const subscriptionUrl = '//amsterdam.us3.list-manage.com/subscribe/post?u=e55dcce17683afea58a273eea&amp;id=5a0eb6a403';

interface Props {
    language: string;
}

interface FormFields {
    EMAIL: string;
    NAME: string;
}

const CustomForm = ({
    status,
    message,
    onValidated,
    language,
    updateError,
    error,
}: {
    status: string | null;
    message?: string | null;
    onValidated: any;
    language: string;
    updateError: any;
    error: string;
}): any => {
    let email: HTMLInputElement | null, name: HTMLInputElement | null;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let errorMessage: string | null = '';

    const handleError = (email: any, name: any, errorcode: string): void => {
        onValidated({
            EMAIL: email,
            NAME: name,
        });

        updateError(errorcode);
    };

    const submit = (): void => {
        const mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.w{2,3})+$/;

        if (name && name.value === '') {
            handleError(name.value, '', 'name');
        } else if (email && email.value === '') {
            handleError('', email.value, 'email');
        } else if (email && email.value.indexOf('@') === -1) {
            handleError(email.value, email.value, 'email');
        } else if (email && !email.value.match(mailformat)) {
            handleError(email.value, email.value, 'email');
        } else if (name && email && email.value.indexOf('@') > -1) {
            onValidated({
                EMAIL: email.value,
                NAME: name.value,
            });
        }
    };

    return (
        <>
            <FormattedMessage
                tagName="h3"
                id="Newsletter.heading"
                defaultMessage="Receive our newsletter once a month and you will be the first to know about new exhibitions, lectures and events."
            />

            <div className={'subscribe-form-footer ' + (status === 'error' ? 'has-error' : '')}>
                <div className="subscribe-form-wrap">
                    <input
                        className={status === 'error' && error !== 'email' ? 'error-notice' : ''}
                        ref={(node: HTMLInputElement): HTMLInputElement => (name = node)}
                        type="text"
                        placeholder={language === 'en' ? 'Your name *' : 'Voornaam *'}
                        required
                    />
                </div>
                <div className="subscribe-form-wrap">
                    <input
                        className={status === 'error' && error === 'email' ? 'error-notice' : ''}
                        ref={(node: HTMLInputElement): HTMLInputElement => (email = node)}
                        type="email"
                        placeholder={language === 'en' ? 'Email address *' : 'E-mailadres *'}
                        required
                    />
                </div>

                <span>
                    <button className="button_subscribe" onClick={submit}>
                        {language === 'en' ? 'Send' : 'Verstuur'}
                    </button>
                </span>
            </div>
            <div className={status ? 'message-wrap' : ''}>
                {status === 'error' && error === 'email' && (
                    <span className="error-notice error-message">
                        {message &&
                            message.indexOf('Please enter a value') > -1 &&
                            (language === 'en'
                                ? (errorMessage = 'E-mail is empty')
                                : (errorMessage = 'E-mail is leeg'))}
                        {message &&
                            (message.indexOf('email') > -1 ||
                                message.indexOf('invalid') ||
                                message.indexOf('different email address')) > -1 &&
                            (language === 'en'
                                ? (errorMessage = 'Fill in a valid e-mail address')
                                : (errorMessage = 'Vul een geldig e-mailadres in'))}
                    </span>
                )}
                {status === 'error' && error === 'name' && (
                    <span className="error-notice error-message">
                        {message &&
                            message.indexOf('enter') > -1 &&
                            (language === 'en'
                                ? (errorMessage = 'Fill in a name')
                                : (errorMessage = 'Vul een naam in'))}
                    </span>
                )}
                {status === 'success' && (
                    <span className="success-notice">
                        {language === 'en'
                            ? (errorMessage =
                                  'Almost finished! We need to confirm your email address. Please click the link in the email we just sent you.')
                            : (errorMessage =
                                  'Bijna klaar! We moeten je e-mailadres bevestigen. Klik op de link in de e-mail die we je zojuist hebben gestuurd.')}
                    </span>
                )}
                {status === 'sending' && (
                    <span className="info-notice">{language === 'en' ? 'Sending...' : 'Bezig met verzenden...'}</span>
                )}
            </div>
        </>
    );
};

export default class Newsletter extends React.Component<Props> {
    private constructor(props: Props) {
        super(props);
    }

    public render(): JSX.Element {
        const { language }: Props = this.props;
        let error = '';
        const updateError = (e: string): void => {
            error = e;
        };

        return (
            <div className="newsletter">
                <MailchimpSubscribe
                    url={subscriptionUrl}
                    render={({
                        subscribe,
                        status,
                        message,
                    }: {
                        subscribe: any;
                        status: any;
                        message: any;
                    }): ReactNode => (
                        <CustomForm
                            status={status}
                            message={message}
                            language={language}
                            onValidated={(formData: FormFields): void => subscribe(formData)}
                            updateError={updateError}
                            error={error}
                        />
                    )}
                />
            </div>
        );
    }
}
