import { useEffect, useState } from 'react';
import axios, { AxiosResponse } from 'axios';
//Interfaces
import { Post } from '../../types/post';
const baseURL = process.env.REACT_APP_API_URL;

interface I18nProperty {
    rendered: string;
}

function getUrl(language: string): string {
    return `${baseURL}/wp/v2/posts?lang=${language}`;
}

export default function useNews(language: string): Post[] {
    const [posts, setPosts]: [Post[], Function] = useState([]);

    useEffect(
        function fetchPosts(): void {
            axios.get(getUrl(language)).then(function parseResults({ data }: AxiosResponse): void {
                setPosts(data as Post[]);
            });
        },
        [language],
    );

    return posts;
}
