import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Swiper from 'swiper';
import 'swiper/css/swiper.min.css';
import { Slide } from './Pages/useFrontPage';
import { refactorUrl } from '../utils';
import { FormattedMessage } from 'react-intl';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

interface Props {
    slider: Slide[];
}

const arrowIcon = (
    <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.2413 6.26539L12.7338 1.75789L14.4917 2.36128e-06L22 7.50833L14.4917 15.0167L12.7338 13.2588L17.2412 8.75141L-1.64194e-06 8.7514L-9.58117e-07 6.26538L17.2413 6.26539Z"
            fill="#FDA729"
        />
    </svg>
);

export default function SliderHomepage(props: Props): JSX.Element {
    const { slider: slides } = props;
    const [init, setInit] = useState(true);
    const [activeSlide, setActiveSlide] = useState(slides[0]);
    const homepageSlider = new Swiper('.swiper-container-homepage', {
        slidesPerView: 1,
        watchSlidesVisibility: true,
        spaceBetween: 0,
        allowTouchMove: true,
        direction: 'horizontal',
        navigation: {
            nextEl: '.custom-swiper-button-next',
            prevEl: '.custom-swiper-button-prev',
        },
    });

    useEffect((): void => {
        if (!init) {
            homepageSlider.update();
            setActiveSlide(slides[0]);
        }
        setInit(false);
        // REASON: Only need to run this effect on slides changing
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [slides]);

    homepageSlider.on('slideChange', function(): void {
        const activeSlideIndex = homepageSlider.realIndex;
        setActiveSlide(slides[activeSlideIndex]);
    });

    const {
        slider_title: sliderTitle,
        slider_subtitle: sliderSubtitle,
        slider_subtitle2: sliderSubtitle2,
        slider_link: sliderLink,
    } = activeSlide;

    return (
        <div className="slider-block">
            <div className="swiper-container-homepage">
                <div className="swiper-wrapper">
                    {slides.map(
                        (slide: any, index: number): JSX.Element => {
                            const {
                                slider_title: sliderTitle,
                                slider_subtitle: sliderSubtitle,
                                slider_subtitle2: sliderSubtitle2,
                                slider_image: sliderImage,
                            } = slide;
                            return (
                                <div key={`${index},${sliderTitle}`} className="swiper-slide">
                                    <LazyLoadImage
                                        src={sliderImage}
                                        effect="opacity"
                                        alt={`${sliderSubtitle},${sliderTitle},${sliderSubtitle2}`}
                                    />
                                </div>
                            );
                        },
                    )}
                </div>
            </div>
            <div className={'slider_content_navigation'}>
                <div className={`rectangle active${slides.indexOf(activeSlide)}`}>
                    <div className="rectangle_content">
                        <p className="rectangle_content-caption">
                            {sliderSubtitle}
                            <br />
                            {sliderSubtitle2}
                        </p>
                        <h4 className="rectangle_content-title">{sliderTitle}</h4>
                        <p className="rectangle_content-link">
                            <Link to={refactorUrl(sliderLink)}>
                                <FormattedMessage tagName="span" id="News.readmore" defaultMessage="Read More" />{' '}
                                {arrowIcon}
                            </Link>
                        </p>
                    </div>
                </div>
                <div
                    className="swiper-button custom-swiper-button-prev"
                    style={{
                        opacity: slides.indexOf(activeSlide) === 0 ? 0.3 : 1,
                        backgroundImage: slides.indexOf(activeSlide) === 0 ? 'none' : '',
                    }}
                >
                    {arrowIcon}
                </div>
                <div
                    className="swiper-button custom-swiper-button-next"
                    style={{
                        opacity: slides.length - 1 !== slides.indexOf(activeSlide) ? 1 : 0.3,
                        backgroundImage: slides.indexOf(activeSlide) === 0 ? '' : 'none',
                    }}
                >
                    {arrowIcon}
                </div>
            </div>
        </div>
    );
}
