import React from 'react';
import { Link } from 'react-router-dom';
import usePosts, { SparsePost, CategoryName } from '../Posts/usePosts';
import placeholderImage from '../../Assets/images/placeholderImage.png';
import Swiper from 'swiper';
import 'swiper/css/swiper.min.css';

const arrowIcon = (
    <svg width="50" height="48" viewBox="0 0 50 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M30.635 24.4872H15.7408V22.6436H30.6351L25.2713 17.3036L26.5807 16L33.254 22.6436H33.3334V22.7227L34.1799 23.5654L33.3334 24.4081V24.4872H33.254L26.5807 31.1308L25.2713 29.8272L30.635 24.4872Z"
            fill="white"
        />
    </svg>
);

function Post({ post, language }: { post: SparsePost; language: string }): JSX.Element {
    const category = post.category_name.map((cat: CategoryName): string => cat.name);
    let path = '';
    if (language === 'en') {
        path = '/en';
    }
    const newsLink = language === 'en' ? 'news' : 'nieuws';
    const backgroundImage = post.featured_image_large.url || placeholderImage;
    const date = new Date(post.date);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    let locale = 'en' === language ? 'en-EN' : 'nl-NL';
    const dateString = date.toLocaleDateString(locale, options);
    const newsItemStyle = {
        backgroundImage: `url('${backgroundImage}`,
    };

    return (
        <Link to={`${path}/${newsLink}/${post.slug}`} className="news-on-homepage-item swiper-slide" role="article">
            <div className="news-on-homepage-item__background" style={newsItemStyle}></div>
            <div className="news-on-homepage-item__link">
                <span className="news-on-homepage-item__blurb">{category}</span>
                <span className="news-on-homepage-item__date">{`${dateString}`}</span>
                <h5 className="news-on-homepage-item__title">{post.title.rendered}</h5>
            </div>
        </Link>
    );
}

export default function NewsOnHomePage({
    language,
    title,
    description,
}: {
    language: string;
    title: string;
    description: string;
}): JSX.Element {
    const posts = usePosts(language);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let newsSlider;
    newsSlider = new Swiper('.swiper-container-news', {
        slidesPerView: 1.2,
        spaceBetween: 30,
        initialSlide: 0,
        centeredSlides: true,
        allowTouchMove: true,
        direction: 'horizontal',
        navigation: {
            nextEl: '.news-on-homepage-next',
            prevEl: '.news-on-homepage-prev',
        },
        breakpoints: {
            991: {
                initialSlide: 0,
                slidesPerView: 2.5,
                centeredSlides: false,
            },
            1440: {
                initialSlide: 0,
                slidesPerView: 3.5,
                centeredSlides: false,
            },
        },
    });

    return (
        <article className="news-on-homepage">
            <div className="row width-100">
                <div className="column medium-6 small-full"></div>
                <div className="column medium-18 small-full">
                    <h3 className="news-on-homepage__title">{title}</h3>

                    <div className="news-on-homepage__container">
                        <p className="news-on-homepage__description">{description}</p>
                        <div className="news-on-homepage__buttons">
                            <div className="swiper-button news-on-homepage-prev" id="slideLeft">
                                {arrowIcon}
                            </div>
                            <div className="swiper-button news-on-homepage-next" id="slideRight">
                                {arrowIcon}
                            </div>
                        </div>
                    </div>

                    <div className="swiper-container-news">
                        <div className="swiper-wrapper">
                            {posts.map(
                                (post: SparsePost): JSX.Element => (
                                    <Post key={post.id} {...{ language, post }} />
                                ),
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </article>
    );
}
