export function refactorUrl(url: string): string {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let strippedUrl;
    const backendUrl = process.env.REACT_APP_BACKEND_URL ? process.env.REACT_APP_BACKEND_URL : 'undefined-NotUndefined';
    const frontendUrl = process.env.REACT_APP_FRONTEND_URL
        ? process.env.REACT_APP_FRONTEND_URL
        : 'undefined-NotUndefined';

    if (url && url.includes(backendUrl)) {
        return (strippedUrl = url.replace(backendUrl, ''));
    }
    if (url && url.includes(frontendUrl)) {
        return (strippedUrl = url.replace(frontendUrl, ''));
    }
    return url;
}

export function Cloudinary(content: any): string {
    const backendUrl = process.env.REACT_APP_BACKEND_URL
        ? process.env.REACT_APP_BACKEND_URL
        : 'https://wp.move.amsterdam';
    let formattedInnerContent = content.replaceAll(
        `${backendUrl}/wp-content/uploads`,
        'https://cloudinary.pondigital.solutions/pon-digital-solutions/image/upload/move/wp-content/uploads',
    );

    return formattedInnerContent;
}
