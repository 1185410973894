import React from 'react';

interface SpacerProps {
    innerContent: string;
}

export default function Spacer(props: SpacerProps): JSX.Element {
    const { innerContent } = props;

    return (
        <div className="row width-100 center">
            <div dangerouslySetInnerHTML={{ __html: innerContent }} />
        </div>
    );
}
