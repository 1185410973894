import React from 'react';
export const arrowIcon = (
    <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.2413 6.26539L12.7338 1.75789L14.4917 2.36128e-06L22 7.50833L14.4917 15.0167L12.7338 13.2588L17.2412 8.75141L-1.64194e-06 8.7514L-9.58117e-07 6.26538L17.2413 6.26539Z"
            fill="#ffffff"
        />
    </svg>
);
