import React, { useEffect, useState } from 'react';
import Swiper from 'swiper';
import 'swiper/css/swiper.min.css';
import { VideoSlide } from './Pages/useFrontPage';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import Youtube from './Content/Gutenberg/Youtube';

interface Props {
    content: VideoSlide[];
}

const arrowIcon = (
    <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.2413 6.26539L12.7338 1.75789L14.4917 2.36128e-06L22 7.50833L14.4917 15.0167L12.7338 13.2588L17.2412 8.75141L-1.64194e-06 8.7514L-9.58117e-07 6.26538L17.2413 6.26539Z"
            fill="#FDA729"
        />
    </svg>
);

export default function SliderMobility(props: Props): JSX.Element {
    const { content: videos } = props;
    const [init, setInit] = useState(true);
    const [activeSlide, setActiveSlide] = useState(videos[0]);
    const { innerWidth: windowWidth } = window;
    const mobilitySlider = new Swiper('.swiper-container-mobility', {
        slidesPerView: 1,
        watchSlidesVisibility: true,
        spaceBetween: 0,
        initialSlide: 0,
        allowTouchMove: true,
        direction: 'horizontal',
        navigation: {
            nextEl: '.custom-swiper-button-next',
            prevEl: '.custom-swiper-button-prev',
        },
        breakpoints: {
            1440: {
                slidesPerView: 2,
                spaceBetween: 20,
            },
        },
    });

    useEffect((): void => {
        if (!init) {
            mobilitySlider.update();
            setActiveSlide(videos[0]);
        }
        setInit(false);
        // REASON: Only need to run this effect on slides changing
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [videos]);

    mobilitySlider.on('slideChange', function(): void {
        const activeSlideIndex = mobilitySlider.realIndex;
        setActiveSlide(videos[activeSlideIndex]);
    });

    return (
        <div className="slider-block">
            <div className="swiper-container-mobility">
                <div className="swiper-wrapper">
                    {videos.map(
                        (slide: any, index: number): JSX.Element => {
                            const { title: sliderTitle, video: sliderVideo } = slide;
                            return (
                                <div className="swiper-slide video-slide" key={`${index}`}>
                                    <Youtube url={sliderVideo} full={true} />
                                    <p className="video-slide__title">{sliderTitle}</p>
                                </div>
                            );
                        },
                    )}
                </div>
                <div className={'slider_content_navigation'}>
                    <div
                        className="swiper-button custom-swiper-button-prev"
                        style={{
                            opacity: videos.indexOf(activeSlide) === 0 ? 0.3 : 1,
                            pointerEvents: videos.indexOf(activeSlide) === 0 ? 'none' : 'auto',
                        }}
                    >
                        {arrowIcon}
                    </div>
                    <div
                        className="swiper-button custom-swiper-button-next"
                        style={{
                            opacity:
                                videos.length - (windowWidth > 1440 ? 2 : 1) !== videos.indexOf(activeSlide) ? 1 : 0.3,
                            pointerEvents:
                                videos.length - (windowWidth > 1440 ? 2 : 1) !== videos.indexOf(activeSlide)
                                    ? 'auto'
                                    : 'none',
                        }}
                    >
                        {arrowIcon}
                    </div>
                </div>
            </div>
        </div>
    );
}
