import React from 'react';
import GutenbergBlocks from './GutenbergBlocks';
import useNews from '../Pages/useNews';
import { Link } from 'react-router-dom';
import { Post } from '../../types/post';
import placeholderImage from '../../Assets/images/Zandvoort1.png';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

interface NewsPageProps {
    language: string;
    blocks: any;
    content: any;
    title: string;
    acf: any;
}

export default function NewPage(props: NewsPageProps): JSX.Element {
    const { language, blocks, title, acf } = props;
    const newsPosts = useNews(language);

    const firstRowDuo = newsPosts.slice(0, 2);
    const secondRowDuo = newsPosts.slice(2);

    return (
        <>
            <div className="news_header">
                <div className="row center">
                    <div className="column medium-14 small-full">
                        <h1>{title}</h1>
                    </div>
                </div>

                <div className="row center">
                    <div className="column large-10 medium-20 small-full">
                        <GutenbergBlocks language={language} blocks={blocks} full={true} />
                    </div>
                </div>
            </div>

            <div className="row center small-full news-content">
                <div className="row center medium-8 small-full news-content-header">
                    <span dangerouslySetInnerHTML={{ __html: acf.tagline }} />
                </div>
                <div className="row center news-content-first-row">
                    {firstRowDuo.map(function(post: Post, i: number): JSX.Element {
                        const date = new Date(post.date);
                        const options = { year: 'numeric', month: 'long', day: 'numeric' };
                        let locale = 'en' === language ? 'en-EN' : 'nl-NL';
                        const dateString = date.toLocaleDateString(locale, options);

                        return (
                            <div className="column large-12 medium-12 small-full" key={i}>
                                <div className="news-element">
                                    <Link to={`${post.slug}`}>
                                        <div className="image-container">
                                            <LazyLoadImage
                                                src={post.featured_image_thumbnail.url || placeholderImage}
                                                effect="opacity"
                                                alt={post.featured_image_thumbnail.alt}
                                            />
                                        </div>
                                        <span className="post-date">{dateString}</span>
                                        <h6 className="post-title">{post.title.rendered}</h6>
                                    </Link>
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className="row news-content-second-row">
                    {secondRowDuo.map(function(post: Post, i: number): JSX.Element {
                        const date = new Date(post.date);
                        const options = { year: 'numeric', month: 'long', day: 'numeric' };
                        let locale = 'en' === language ? 'en-EN' : 'nl-NL';
                        const dateString = date.toLocaleDateString(locale, options);

                        return (
                            <div className="column large-8 medium-12 small-full" key={i}>
                                <div className="news-element">
                                    <Link to={`${post.slug}`}>
                                        <LazyLoadImage
                                            src={post.featured_image_thumbnail.url || placeholderImage}
                                            effect="opacity"
                                            alt={post.featured_image_thumbnail.alt}
                                        />
                                        <span className="post-date">{dateString}</span>
                                        <h6 className="post-title">{post.title.rendered}</h6>
                                    </Link>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
}
