import React, { Component, Fragment } from 'react';
// @ts-ignore
import { Link } from 'react-router-dom';

import Header from '../Header';
import Footer from '../../Components/Footer/Footer';
import { Helmet } from 'react-helmet';
import CookiesModal from '../Cookies/CookiesModal';
import Search from './Search';
import { FormattedMessage } from 'react-intl';
import placeholderImage from '../../Assets/images/meta-image.jpg';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

interface Props {
    location: any;
    menuData: any;
    //Cookies props
    cookies: any;
    cookiesModalVisible: boolean;
    handleCookiesModal: Function;
    setCookiesValue: Function;
    language: string;
    updateLanguage: Function;
}

interface State {
    results: any[];
    query: string;
}

export default class SearchResults extends Component<Props, State> {
    private constructor(props: Props) {
        super(props);
    }

    public render(): JSX.Element {
        window.scrollTo(0, 0);
        let results, query;

        if (this.props.location.state !== undefined) {
            results = this.props.location.state.results;
            query = this.props.location.state.query;
        } else {
            results = '';
            query = '';
        }

        let noOfResults = '';
        if (results) {
            // If the first object in results is not empty
            if (results !== undefined) {
                noOfResults = results.length;
            } else {
                noOfResults = '0';
            }
        } else {
            noOfResults = '0';
        }

        const {
            menuData,
            cookies,
            cookiesModalVisible,
            handleCookiesModal,
            setCookiesValue,
            language,
            updateLanguage,
        } = this.props;

        let path = '';
        if (language === 'en') {
            path = '/en';
        }

        let pageTitle = '';
        if (language === 'nl') {
            pageTitle = 'Zoekresultaten voor ' + query + ' - Move Amsterdam';
        } else {
            pageTitle = 'Searchresults for ' + query + ' - Move Amsterdam';
        }

        let faqLink = '';
        if (language === 'nl') {
            faqLink = '/veelgestelde-vragen';
        } else {
            faqLink = '/faq';
        }

        return (
            <Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{pageTitle}</title>
                </Helmet>
                <Header
                    {...{
                        ...this.props,
                        menu: menuData,
                    }}
                />
                <CookiesModal
                    cookies={cookies}
                    handleCookiesModal={handleCookiesModal}
                    visible={cookiesModalVisible}
                    setCookiesValue={setCookiesValue}
                    language={language}
                />
                <div className="content-wrapper search-page">
                    <div className="content-page">
                        <div className="row center">
                            <div className="column large-16 medium-20 small-full">
                                {/* Zoekresultaten */}
                                <Search language={this.props.language} location="searchpage" value={query} />
                                <div id="search-results" className="search-results">
                                    <p className="number-results">{noOfResults}</p>
                                    <p className="number-results-description">
                                        <FormattedMessage
                                            tagName="span"
                                            id="Search.searchresults"
                                            defaultMessage="results found"
                                        />
                                    </p>
                                    <div className="row post-content">
                                        {results !== '' &&
                                            results.map(
                                                (result: any, i: number): JSX.Element => {
                                                    const limitedExcerpt =
                                                        (result.excerpt !== undefined
                                                            ? result.excerpt.rendered
                                                            : result.content.rendered
                                                        )
                                                            .split(' ')
                                                            .splice(0, 15)
                                                            .join(' ') + '...';

                                                    const newsTranslations =
                                                        this.props.language === 'en' ? 'news' : 'nieuws';
                                                    let pageParent = result.parent_page_slug
                                                        ? '/' + result.parent_page_slug
                                                        : '';

                                                    if (result.type === 'post') {
                                                        pageParent = '/' + newsTranslations;
                                                    }

                                                    const image =
                                                        result.featured_image_thumbnail !== undefined
                                                            ? result.featured_image_thumbnail.url
                                                            : placeholderImage;
                                                    let imgElement;

                                                    if (image) {
                                                        imgElement = (
                                                            <LazyLoadImage
                                                                src={image}
                                                                effect="opacity"
                                                                alt={
                                                                    result.featured_image_thumbnail !== undefined
                                                                        ? result.featured_image_thumbnail.alt
                                                                        : result.title.rendered
                                                                }
                                                            />
                                                        );
                                                    } else {
                                                        imgElement = <div className="img-placeholder"></div>;
                                                    }

                                                    return (
                                                        <div className="column large-8 medium-12 small-full" key={i}>
                                                            <div className="post-element">
                                                                {result.type === 'move-faq' ? (
                                                                    <>
                                                                        <Link to={`${path}${faqLink}/#${result.slug}/`}>
                                                                            {imgElement}
                                                                        </Link>

                                                                        <Link to={`${path}${faqLink}/#${result.slug}/`}>
                                                                            <p className="post-title">
                                                                                {result.title.rendered}
                                                                            </p>
                                                                        </Link>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <Link
                                                                            to={`${path}${
                                                                                pageParent ? pageParent : ''
                                                                            }/${result.slug}/`}
                                                                        >
                                                                            {imgElement}
                                                                        </Link>
                                                                        <Link
                                                                            to={`${path}${
                                                                                pageParent ? pageParent : ''
                                                                            }/${result.slug}/`}
                                                                        >
                                                                            <p className="post-title">
                                                                                {result.title.rendered}
                                                                            </p>
                                                                        </Link>
                                                                    </>
                                                                )}

                                                                <div
                                                                    className="post-excerpt"
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: limitedExcerpt,
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    );
                                                },
                                            )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer
                        handleCookiesModal={handleCookiesModal}
                        language={language}
                        updateLanguage={updateLanguage}
                    />
                </div>
            </Fragment>
        );
    }
}
