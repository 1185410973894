import React, { Component, Fragment } from 'react';
import { Link, NavLink } from 'react-router-dom';
import Search from '../Components/Search/Search';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import MoveLogo from '../Assets/images/move-logo-white.svg';
import { refactorUrl } from '../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown } from '@fortawesome/free-solid-svg-icons';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FormattedMessage } from 'react-intl';
import { gsap } from 'gsap/all';
import { fbq } from 'react-facebook-pixel';

//Interfaces
import { Menu } from '../types/menu';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

interface Props {
    language: string;
    updateLanguage: Function;
    menu: Menu[];
    stateLanguage: string;
    parent: string;
}

interface State {
    MenuOpen: boolean;
}

export default class HamburgerMenu extends Component<Props, State> {
    private constructor(props: Props) {
        super(props);
        this.state = {
            MenuOpen: false,
        };
        this.MenuToggle = this.MenuToggle.bind(this);
    }

    public componentWillUnmount = async (): Promise<void> => {
        const targetElement = document.querySelector('#navigation') as HTMLElement;
        enableBodyScroll(targetElement);
    };

    private MenuToggle = (): void => {
        const targetElement = document.querySelector('#navigation') as HTMLElement;
        this.setState({ MenuOpen: !this.state.MenuOpen }, (): void => {
            if (this.state.MenuOpen) {
                gsap.to('.menu__wrap', { duration: 0.1, autoAlpha: 1 });
            } else {
                enableBodyScroll(targetElement);
                gsap.to('.menu__wrap', { duration: 0.1, autoAlpha: 0 });
            }
        });
    };

    private SubmenuToggle = (event: any): void => {
        const el = event.target;
        const clickedSubmenu = el.parentElement.closest('.submenu');

        const targetElement = document.querySelector('#navigation') as HTMLElement;
        const submenus = document.getElementsByClassName('submenu');

        disableBodyScroll(targetElement);

        if (clickedSubmenu.classList.contains('open')) {
            clickedSubmenu.classList.remove('open');
        } else {
            for (let i = 0; i < submenus.length; i++) {
                submenus[i].classList.remove('open');
            }
            clickedSubmenu.classList.add('open');
        }

        enableBodyScroll(targetElement);
    };

    public handleMenuItemClick = (event: any): void => {
        const currentPage = window.location.pathname;
        const el = event.target as HTMLElement;

        if (el.getAttribute('href') === currentPage) {
            event.preventDefault();
            this.setState({ MenuOpen: !this.state.MenuOpen });
        }
    };

    public render(): JSX.Element {
        const { MenuOpen }: State = this.state;
        const { language, menu, stateLanguage, parent }: Props = this.props;

        return (
            <div id="hamburger-menu" className="hide-for-large-up">
                {MenuOpen ? (
                    <button className="menu-close" onClick={this.MenuToggle}>
                        <i></i>
                    </button>
                ) : (
                    <button className="menu-open" onClick={this.MenuToggle}>
                        <FontAwesomeIcon icon={faBars} />
                    </button>
                )}

                <nav
                    className={`menu__wrap 
            ${MenuOpen && `show`} ${!MenuOpen && `hide`}`}
                >
                    <Link className="logo" to="/">
                        <LazyLoadImage src={MoveLogo} effect="opacity" alt="Logo Move" width="105" />
                    </Link>
                    <div id="navigation">
                        <ul>
                            {menu.map(
                                (item: Menu): JSX.Element => {
                                    return (
                                        <Fragment key={item.id}>
                                            {item.children === undefined && (
                                                <li>
                                                    <NavLink
                                                        onClick={this.handleMenuItemClick}
                                                        to={refactorUrl(item.url)}
                                                    >{`${item.title}`}</NavLink>
                                                </li>
                                            )}

                                            {item.children !== undefined && (
                                                <li className="submenu submenu-mobile">
                                                    <span onClick={this.SubmenuToggle}>
                                                        {`${item.title}`}
                                                        <FontAwesomeIcon icon={faSortDown} />
                                                    </span>
                                                    <div className="submenu-wrapper">
                                                        <ul>
                                                            {item.children.map(
                                                                (child: Menu): JSX.Element => {
                                                                    return (
                                                                        <li key={child.id}>
                                                                            <NavLink
                                                                                onClick={this.handleMenuItemClick}
                                                                                to={refactorUrl(child.url)}
                                                                            >
                                                                                {`${child.title}`}
                                                                            </NavLink>
                                                                        </li>
                                                                    );
                                                                },
                                                            )}
                                                        </ul>
                                                    </div>
                                                </li>
                                            )}
                                        </Fragment>
                                    );
                                },
                            )}
                        </ul>
                        <ul id="language-switch" className="language-switch__mobile">
                            {language === 'nl' ? (
                                <>
                                    <li>
                                        <a
                                            href={window.location.href}
                                            className="active"
                                            onClick={(): void => {
                                                this.props.updateLanguage('nl');
                                            }}
                                        >
                                            NL
                                        </a>
                                    </li>
                                    <li>/</li>
                                    <li>
                                        <Link
                                            id="language-symbol"
                                            to={`/en/${parent ? parent + '/' : ''}${
                                                stateLanguage ? stateLanguage + '/' : ''
                                            }`}
                                            onClick={(): void => {
                                                this.props.updateLanguage(
                                                    'en',
                                                    `/en/${parent ? parent + '/' : ''}${
                                                        stateLanguage ? stateLanguage + '/' : ''
                                                    }`,
                                                );
                                            }}
                                        >
                                            EN
                                        </Link>
                                    </li>
                                </>
                            ) : (
                                <>
                                    <li>
                                        <Link
                                            to={`/${parent ? parent + '/' : ''}${
                                                stateLanguage ? stateLanguage + '/' : ''
                                            }`}
                                            className={language === 'nl' ? 'active' : ''}
                                            onClick={(): void => {
                                                this.props.updateLanguage(
                                                    'nl',
                                                    `/${parent ? parent + '/' : ''}${
                                                        stateLanguage ? stateLanguage + '/' : ''
                                                    }`,
                                                );
                                            }}
                                        >
                                            NL
                                        </Link>
                                    </li>
                                    <li>/</li>
                                    <li>
                                        <a
                                            id="language-symbol"
                                            href={window.location.href}
                                            className="active"
                                            onClick={(): void => {
                                                this.props.updateLanguage('en');
                                            }}
                                        >
                                            EN
                                        </a>
                                    </li>
                                </>
                            )}
                        </ul>
                        {language === 'nl' ? (
                            <a
                                className="button buyticketsmobile__button"
                                href="https://tickets.move.amsterdam/nl/tickets"
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={(): void => {
                                    fbq('track', 'ButtonBoekTickets');  
                                }}
                            >
                                <FormattedMessage id="Header.OrderTickets" defaultMessage="Order Tickets" />
                            </a>
                        ) : (
                            <a
                                className="button buyticketsmobile__button"
                                href="https://tickets.move.amsterdam/en/tickets"
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={(): void => {
                                    fbq('track', 'ButtonBoekTickets');  
                                }}
                            >
                                <FormattedMessage id="Header.OrderTickets" defaultMessage="Order Tickets" />
                            </a>
                        )}
                        <Search language={language} location="hamburger" />
                    </div>
                </nav>
            </div>
        );
    }
}
