import React from 'react';
import { Link } from 'react-router-dom';
import { Highlight } from '../Pages/useFrontPage';
import { arrowIcon } from '../../Assets/icon/arrowIcon';
import { refactorUrl } from '../../utils';
import { FormattedMessage } from 'react-intl';

function Card({ highlight }: { highlight: Highlight }): JSX.Element {
    const {
        highlight_category: highlightCategory,
        highlight_image: highlightImage,
        highlight_link: highlightLink,
        highlight_title: highlightTitle,
    } = highlight;
    const newsItemStyle = {
        backgroundImage: `url('${highlightImage}`,
    };

    return (
        <div className="column large-6 medium-12 small-full">
            <Link to={refactorUrl(highlightLink)} className="activities-item" role="article" style={newsItemStyle}>
                <span className="activities-item__link">
                    <span className="activities-item__blurb">{highlightCategory}</span>
                    <h5 className="activities-item__title">{highlightTitle}</h5>
                </span>
            </Link>
        </div>
    );
}

export default function ActivitiesBlock({
    title,
    highlights,
    link_url: linkUrl,
    textblock_1: textblock1,
    textblock_2: textblock2,
    language,
}: {
    highlights: any;
    link_url: string;
    textblock_1: string;
    textblock_2: string;
    title: string;
    language: string;
}): JSX.Element {
    return (
        <div className="activities">
            <div className="activities__content__container">
                <div className="activities__content">
                    <h3 className="activities__content__title">{title}</h3>
                    <p className="activities__content__textblock">{textblock1}</p>
                    <p className="activities__content__link">
                        <a href={refactorUrl(linkUrl)}>
                            <FormattedMessage tagName="span" id="News.readmore" defaultMessage="Read More" />{' '}
                            {arrowIcon}
                        </a>
                    </p>
                </div>
            </div>
            <ul className="activities__list">
                {highlights.highlight.map(
                    (highlight: Highlight): JSX.Element => (
                        <Card key={highlight.highlight_category} {...{ language, highlight }} />
                    ),
                )}
            </ul>
            <div className="activities__content__container">
                <div className="activities__content">
                    <p className="activities__content__textblock2">{textblock2}</p>
                </div>
            </div>
        </div>
    );
}
