import React, { Component } from 'react';

//fortawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';

interface Props {
    description: string;
    opening_hours: string;
    language: string;
    telephone_number?: string;
    email_address?: string;
    url?: string;
    facebook?: string;
    instagram?: string;
    youtube?: string;
    twitter?: string;
}

interface State {
    socials: boolean;
}

const LanguageStrings: { [index: string]: { [index: string]: string } } = {
    address: {
        en: 'Address',
        nl: 'Adres',
    },
    openingHours: {
        en: 'Opening Hours',
        nl: 'Openingstijden',
    },
    visitWebsite: {
        en: 'Visit website',
        nl: 'Naar de website',
    },
};

function getTranslation(string: string, language: string = 'nl'): string {
    return LanguageStrings[string][language];
}

export default class GutenbergBlocks extends Component<Props, State> {
    private constructor(props: Props) {
        super(props);
        this.state = {
            socials: false,
        };
    }

    public componentDidMount(): void {
        const { facebook, instagram, youtube, twitter } = this.props;

        if (facebook || instagram || youtube || twitter) {
            this.setState({
                socials: true,
            });
        }
    }

    public render(): JSX.Element {
        const {
            description,
            opening_hours: openingHours,
            telephone_number: telephoneNumber,
            email_address: email,
            url,
            facebook,
            instagram,
            youtube,
            twitter,
            language,
        } = this.props;

        const { socials } = this.state;

        return (
            <div className="activity__information">
                <div className="row center">
                    <div className="column medium-5 small-full animated-a">
                        <span>{getTranslation('address', language)}</span>
                        {description !== '' && description !== undefined && (
                            <div dangerouslySetInnerHTML={{ __html: description }} />
                        )}
                    </div>
                    <div className="column medium-8 small-full animated-a">
                        <span>{getTranslation('openingHours', language)}</span>
                        {openingHours !== '' && openingHours !== undefined && (
                            <div dangerouslySetInnerHTML={{ __html: openingHours }} />
                        )}
                    </div>
                    <div className="column medium-5 small-full">
                        <span>Contact</span>
                        <div className="activity__information--contact">
                            {telephoneNumber !== '' && telephoneNumber !== undefined && (
                                <div>
                                    <a className="activity__a" href={`tel:${telephoneNumber}`}>
                                        {telephoneNumber}
                                    </a>
                                </div>
                            )}
                            {email !== '' && email !== undefined && (
                                <div>
                                    <a className="activity__a" href={`mailto:${email}`}>
                                        {email}
                                    </a>
                                </div>
                            )}
                            {url !== '' && url !== undefined && (
                                <div>
                                    <a className="activity__a" target="_blank" rel="noopener noreferrer" href={url}>
                                        {getTranslation('visitWebsite', language)}
                                    </a>
                                </div>
                            )}
                        </div>
                        {socials && (
                            <span>
                                {youtube !== '' && youtube !== undefined && (
                                    <a target="_blank" rel="noopener noreferrer" className="youtube" href={youtube}>
                                        <FontAwesomeIcon className="fa-fw fa-lg" icon={faYoutube} />
                                    </a>
                                )}
                                {facebook !== '' && facebook !== undefined && (
                                    <a target="_blank" rel="noopener noreferrer" className="facebook" href={facebook}>
                                        <svg
                                            width="13"
                                            height="27"
                                            viewBox="0 0 13 27"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M3.25 9H0V13.5H3.25V27H8.66667V13.5H12.6122L13 9H8.66667V7.12463C8.66667 6.05025 8.87467 5.625 9.87458 5.625H13V0H8.87467C4.979 0 3.25 1.78087 3.25 5.19187V9Z"
                                                fill="#FDA729"
                                            />
                                        </svg>
                                    </a>
                                )}
                                {instagram !== '' && instagram !== undefined && (
                                    <a target="_blank" rel="noopener noreferrer" className="instagram" href={instagram}>
                                        <FontAwesomeIcon className="fa-fw fa-lg" icon={faInstagram} />
                                    </a>
                                )}
                                {twitter !== '' && twitter !== undefined && (
                                    <a target="_blank" rel="noopener noreferrer" className="twitter" href={twitter}>
                                        <FontAwesomeIcon className="fa-fw fa-lg" icon={faTwitter} />
                                    </a>
                                )}
                            </span>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
