import * as React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import Newsletter from '../Newsletter/Newsletter';

interface Props {
    handleCookiesModal: Function;
    language: string;
    updateLanguage: Function;
    slug?: string;
}

interface State {
    isMobile: boolean;
}

export default function Footer({
    language,
}: {
    handleCookiesModal: Function;
    language: string;
    updateLanguage: Function;
    slug?: string;
}): JSX.Element {
    function updateDimensions(): void {
        const footer: HTMLElement | null = document.querySelector('footer');
        const footerContainer: HTMLElement | null = document.querySelector('.footer-container');
        if (footer !== null && footerContainer !== null) {
            const footerHeight: number | null = footerContainer.offsetHeight;
            if (footerHeight !== null) {
                footer.style.height = footerHeight + 'px';
            }
        }
    }

    const [isMobile, setIsMobile] = React.useState(false);

    React.useEffect((): void => {
        window.innerWidth <= 990 ? setIsMobile(true) : setIsMobile(false);
        updateDimensions();
    }, []);

    updateDimensions();
    window.addEventListener('resize', (): void => {
        updateDimensions();
        window.innerWidth <= 990 ? setIsMobile(true) : setIsMobile(false);
    });

    const facebook = 'https://www.facebook.com/mobilityexperienceamsterdam/';
    const instagram = 'https://www.instagram.com/mobilityexperience/';
    const todayDate = new Date();

    return (
        <footer className="footer">
            <div className="footer-container">
                <div className="row center">
                    <div className="column large-22 medium-full small-full">
                        <div className="row first-row">
                            <div className="column xlarge-8 large-6 medium-full">
                                <Newsletter language={language} />
                            </div>
                            <div className="column xlarge-6 large-7 small-16">
                                <FormattedMessage tagName="h3" id="Footer.addressHeading" defaultMessage="Address" />
                                <FormattedHTMLMessage
                                    tagName="p"
                                    id="Footer.addressContent"
                                    defaultMessage="The Mobility Experience<br />Stadionplein 28<br />1076 CM Amsterdam<br />"
                                />
                                {language === 'en' ? (
                                    <Link to="/en/contact-eng/">
                                        <p>Contact us</p>
                                    </Link>
                                ) : (
                                    <Link to="/contact/">
                                        <p>Contact</p>
                                    </Link>
                                )}
                            </div>
                            <div className="column small-8 social hide-for-large-up">
                                <FormattedHTMLMessage tagName="h3" id="Footer.social" defaultMessage="Follow Us" />
                                <a target="_blank" rel="noopener noreferrer" className="facebook" href={facebook}>
                                    <p>Facebook</p>
                                </a>
                                <a target="_blank" rel="noopener noreferrer" className="instagram" href={instagram}>
                                    <p>Instagram</p>
                                </a>
                            </div>
                            <div className="column xlarge-7 large-8 small-full opening-hours">
                                <FormattedHTMLMessage
                                    tagName="h3"
                                    id="Footer.openingHoursHeading"
                                    defaultMessage="Opening hours"
                                />
                                <FormattedHTMLMessage
                                    tagName="p"
                                    id="Footer.openingHoursContent"
                                    defaultMessage="<table><tbody><tr><td>Wednesday:</td><td>13.30 – 17.00</td></tr><tr><td>Saturday:</td><td>10.00 – 17.00</td></tr><tr><td>Sunday:</td><td>10.00 – 17.00</td></tr></tbody></table>"
                                />
                            </div>
                            <div className="column large-3 social hide-for-medium-down">
                                <FormattedHTMLMessage tagName="h3" id="Footer.social" defaultMessage="Follow Us" />
                                <a target="_blank" rel="noopener noreferrer" className="facebook" href={facebook}>
                                    <p>Facebook</p>
                                </a>
                                <a target="_blank" rel="noopener noreferrer" className="instagram" href={instagram}>
                                    <p>Instagram</p>
                                </a>
                            </div>
                        </div>
                        <div className="row center">
                            <div className="column small-full medium-full large-full no-padding footer-wrap">
                                <div className="footer-menu side-left column medium-10 small-full">
                                    <ul>
                                        <li>
                                            <a
                                                href="https://www.pon.com/"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                title="Pon.com"
                                            >
                                                &#169;
                                                {todayDate.getFullYear()} Move Amsterdam
                                            </a>
                                        </li>
                                        {!isMobile && <li>|</li>}
                                        <li>All rights reserved</li>
                                    </ul>
                                </div>
                                <nav className="footer-menu side-right column medium-14 small-full">
                                    <ul className="footer-menu-wrap">
                                        {isMobile && <li>Company</li>}
                                        <li>
                                            <a
                                                href="https://www.pon.com/"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                title="Pon.com"
                                            >
                                                Pon
                                            </a>
                                        </li>
                                        <li>
                                            {language === 'en' ? (
                                                <Link to="/en/terms-of-use/">Terms of use</Link>
                                            ) : (
                                                <Link to="/gebruiksvoorwaarden/">Gebruiksvoorwaarden</Link>
                                            )}
                                        </li>
                                        <li>
                                            {language === 'en' ? (
                                                <Link to="/en/visitor-conditions/">Visitor conditions</Link>
                                            ) : (
                                                <Link to="/bezoekers-voorwaarden/">Bezoekersvoorwaarden</Link>
                                            )}
                                        </li>
                                        <li>
                                            {language === 'en' ? (
                                                <Link to="/en/privacy-statement/">Privacy Statement</Link>
                                            ) : (
                                                <Link to="/privacyverklaring/">Privacyverklaring</Link>
                                            )}
                                        </li>
                                        <li>
                                            {language === 'en' ? (
                                                <Link to="/en/cookie-policy/">Cookies</Link>
                                            ) : (
                                                <Link to="/cookies/">Cookies</Link>
                                            )}
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}
