import React, { useRef } from 'react';
import { useInViewport } from 'react-in-viewport';
import { Cloudinary } from '../../../utils';

interface Props {
    innerContent: any;
}

export default function AnimatedImage(props: Props): JSX.Element {
    const { innerContent } = props;
    let options: any;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let config: any;

    const myRef = useRef();
    const { enterCount } = useInViewport(myRef, options, (config = { disconnectOnLeave: false }), props);

    return (
        // @ts-ignore
        <section ref={myRef} className={enterCount > 0 ? 'show-anim' : ''}>
            <div
                dangerouslySetInnerHTML={{
                    __html: Cloudinary(innerContent),
                }}
            />
        </section>
    );
}
