import { useEffect, useState } from 'react';
import axios, { AxiosResponse } from 'axios';

const baseURL = process.env.REACT_APP_API_URL;

interface I18nProperty {
    rendered: string;
}

export interface NewsPost {
    title: string;
    excerpt: string;
    category?: string;
    featuredImageFull: any;
    blocks: any;
    content: any;
    paddingBottomPage: string;
    yoastMeta: string[];
    ogDescription?: string;
    acf?: any;
    postdate: any;
    author: any;
    dateGmt: string;
    modifiedGmt: string;
}

function getUrl(id: number, language: string): string {
    return `${baseURL}/wp/v2/posts/${id}?lang=${language}`;
}

export default function useNewsPost(id: number, language: string): NewsPost {
    const [post, setPost]: [NewsPost, Function] = useState({
        title: '',
        excerpt: '',
        category: '',
        featuredImageFull: '',
        blocks: '',
        content: '',
        paddingBottomPage: 'true',
        yoastMeta: [],
        acf: [],
        postdate: '',
        author: [],
        dateGmt: '',
        modifiedGmt: '',
    });

    useEffect(
        function fetchPosts(): void {
            axios.get(getUrl(id, language)).then(function parseResults({ data }: AxiosResponse): void {
                const renameData = {
                    title: data.title.rendered,
                    excerpt: data.excerpt.rendered,
                    category: data.category_name[0].name,
                    featuredImageFull: data.featured_image_full,
                    blocks: data.blocks ? data.blocks : '',
                    content: data.content,
                    paddingBottomPage: data.acf.padding_bottom_page,
                    yoastMeta: data.yoast_meta,
                    acf: data.acf,
                    postdate: data.date,
                    author: data.author_details,
                    dateGmt: data.date_gmt,
                    modifiedGmt: data.modified_gmt,
                };
                setPost(renameData as NewsPost);
            });
        },
        [id, language],
    );

    return post;
}
