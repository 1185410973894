import { useEffect, useState } from 'react';
import axios, { AxiosResponse } from 'axios';

const baseURL = process.env.REACT_APP_API_URL;

interface I18nProperty {
    rendered: string;
}

export interface SparsePlanVisits {
    ID: number;
    title: I18nProperty;
    date: string;
    slug: string;
    lang: string;
    language: string;
    blocks: any[];
    acf?: any;
}

function getUrl(language: string): string {
    return `${baseURL}/wp/v2/move-plan-visit?lang=${language}`;
}

export default function usePlanVisits(language: string): SparsePlanVisits[] {
    const [posts, setPosts]: [SparsePlanVisits[], Function] = useState([]);

    useEffect(
        function fetchPosts(): void {
            axios.get(getUrl(language)).then(function parseResults({ data }: AxiosResponse): void {
                setPosts(data as SparsePlanVisits[]);
            });
        },
        [language],
    );

    return posts;
}
