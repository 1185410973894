import React from 'react';
import Header from '../Header';
import CookiesModal from '../Cookies/CookiesModal';
import Footer from '../Footer/Footer';

interface MainLayoutProps {
    menuData: any;
    cookies: any;
    cookiesModalVisible: boolean;
    handleCookiesModal: Function;
    setCookiesValue: Function;
    language: string;
    updateLanguage: Function;
    children: any;
    type: string;
}

export default function MainLayout(props: MainLayoutProps): JSX.Element {
    const { menuData, cookiesModalVisible } = props;
    return (
        <>
            <Header
                {...{
                    ...props,
                    menu: menuData,
                }}
            />
            <CookiesModal
                {...{
                    ...props,
                    visible: cookiesModalVisible,
                }}
            />
            {props.children}
            <Footer {...props} />
        </>
    );
}
