import React, { Component } from 'react';
import Heading from '../../Content/Gutenberg/Heading';
import Paragraph from '../../Content/Gutenberg/Paragraph';
import Button from '../../Content/Gutenberg/Button';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

interface Props {
    full?: boolean;
    firstImgAlt: string;
    firstImgURL: string;
    secondImgAlt: string;
    secondImgURL: string;
    innerBlocks: any;
}

export default class ImageToggle extends Component<Props> {
    private constructor(props: Props) {
        super(props);
    }

    private switchImage(): void {
        const smallImage: HTMLImageElement | null = document.querySelector('.img-container__left .overlay');
        const largeImage: HTMLImageElement | null = document.querySelector('.img-container__right .overlay');

        if (smallImage !== null && largeImage !== null) {
            if (!smallImage.classList.contains('slide-to-the-left')) {
                largeImage.classList.add('slide-to-the-left');
                largeImage.classList.remove('slide-to-the-right');
                setTimeout(function(): void {
                    smallImage.classList.add('slide-to-the-left');
                    smallImage.classList.remove('slide-to-the-right');
                }, 400);
            } else {
                smallImage.classList.add('slide-to-the-right');
                smallImage.classList.remove('slide-to-the-left');
                setTimeout(function(): void {
                    largeImage.classList.add('slide-to-the-right');
                    largeImage.classList.remove('slide-to-the-left');
                }, 400);
            }
        }
    }

    public componentDidMount(): void {
        let interval: any;
        const duration: number = process.env.REACT_APP_SLIDER_DURATION as any;
        const imageToggle: HTMLElement | null = document.querySelector('.imagetoggle-container');
        const observer = new IntersectionObserver(
            (entries: IntersectionObserverEntry[]): void => {
                entries.forEach((entry: any): void => {
                    if (0.5 <= entry.intersectionRatio) {
                        interval = setInterval((): void => {
                            this.switchImage();
                        }, duration);
                    } else {
                        clearInterval(interval);
                    }
                });
            },
            {
                threshold: 0.5,
            },
        );

        if (imageToggle) {
            observer.observe(imageToggle);
        }
    }

    public render(): JSX.Element {
        const { full, firstImgAlt, firstImgURL, secondImgAlt, secondImgURL, innerBlocks }: Props = this.props;

        let innerBlocksContent;

        if (innerBlocks.length > 0) {
            innerBlocksContent = JSON.stringify(innerBlocks[0].innerHTML);
            innerBlocksContent = innerBlocksContent.replace(/\\n/g, '');
            innerBlocksContent = innerBlocksContent.replace(/"/g, '');
        }

        let openingClasses = '';
        if (typeof innerBlocksContent !== 'undefined' && innerBlocksContent !== '<p></p>') {
            openingClasses = 'column large-14 medium-full small-full';
        } else {
            if (full) {
                openingClasses = 'column medium-20 small-full';
            } else {
                openingClasses = 'column large-12 medium-16 small-full';
            }
        }

        const content = (
            <div className={openingClasses}>
                <div className="imagetoggle-container">
                    <div className="img-container img-container__left">
                        <LazyLoadImage
                            onClick={(): void => this.switchImage()}
                            className="img"
                            src={firstImgURL}
                            effect="opacity"
                            alt={firstImgAlt}
                        />
                        <LazyLoadImage
                            onClick={(): void => this.switchImage()}
                            className="overlay"
                            src={secondImgURL}
                            effect="opacity"
                            alt={secondImgAlt}
                        />
                    </div>
                    <div className="img-container img-container__right">
                        <LazyLoadImage
                            onClick={(): void => this.switchImage()}
                            className="img"
                            src={secondImgURL}
                            effect="opacity"
                            alt={secondImgAlt}
                        />
                        <LazyLoadImage
                            onClick={(): void => this.switchImage()}
                            className="overlay"
                            src={firstImgURL}
                            effect="opacity"
                            alt={firstImgAlt}
                        />
                    </div>
                </div>
            </div>
        );

        const gutenberg = (
            <div className="column large-10 medium-full small-full relative">
                <div className="imagetoggle-innerblocks">
                    {/* BEGIN: Gutenberg Blocks */}
                    {Object.keys(innerBlocks).map(function(key: string): any {
                        if (innerBlocks[key].blockName === 'core/heading') {
                            return <Heading key={key} innerContent={innerBlocks[key].innerContent} noWrap={true} />;
                        } else if (innerBlocks[key].blockName === 'core/paragraph') {
                            return <Paragraph key={key} innerContent={innerBlocks[key].innerContent} noWrap={true} />;
                        } else if (innerBlocks[key].blockName === 'core/button') {
                            return <Button key={key} innerContent={innerBlocks[key].innerContent} />;
                        }
                        return '';
                    })}
                    {/* END: Gutenberg Blocks */}
                </div>
            </div>
        );

        // Wrap content with div for styling purposes if the innerBlocks array has content
        return (
            <div className="imagetoggle-wrapper">
                <div className="row center">
                    {(typeof innerBlocksContent === 'undefined' || innerBlocksContent === '<p></p>') && <>{content}</>}
                    {typeof innerBlocksContent !== 'undefined' && innerBlocksContent !== '<p></p>' && (
                        <>
                            <div className="column large-20 medium-16 small-full">
                                <div className="row center innerrow">
                                    {content}
                                    {gutenberg}
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        );
    }
}
