import React from 'react';
import Ticker from '../../Elements/Ticker';

interface Props {
    content: string;
    attrs?: any;
}

export default function TickerBlock(props: Props): JSX.Element {
    const { content, attrs } = props;

    const tickerClass: string = (attrs.className ? attrs.className : '') + ' ticker-container';

    return (
        <div className={tickerClass}>
            <Ticker content={content} />
        </div>
    );
}
