import React from 'react';

interface Props {
    innerContent: string;
    noWrap?: boolean;
    full?: boolean;
}

export default function Paragraph(props: Props): JSX.Element {
    const { innerContent, noWrap, full } = props;

    if (noWrap) {
        return <div dangerouslySetInnerHTML={{ __html: innerContent }} />;
    } else {
        let openingClasses = 'column large-12 medium-16 small-full';
        if (full) {
            openingClasses = 'column medium-20 small-full';
        }

        return (
            <div className="row center">
                <div className={openingClasses}>
                    <div dangerouslySetInnerHTML={{ __html: innerContent }} />
                </div>
            </div>
        );
    }
}
