import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Hotspot as IHotspot } from '../Pages/useFrontPage';
import placeholderImage from '../../Assets/images/placeholderImage.png';
import buildingImage from '../../Assets/images/building-model.svg';
import { arrowIcon } from '../../Assets/icon/arrowIcon';
import { refactorUrl } from '../../utils';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
const LanguageStrings: { [index: string]: { [index: string]: string } } = {
    cta: {
        en: 'Read more',
        nl: 'Lees meer',
    },
    description: {
        en: 'Discover Move. Move The City. An interactive experience - for all',
        nl: 'Ontdek Move. Move The City. Een interactieve experience - voor jong en oud',
    },
    url: {
        en: 'https://move.amsterdam/en/',
        nl: 'https://move.amsterdam/',
    },
};

function getTranslation(string: string, language: string = 'nl'): string {
    return LanguageStrings[string][language];
}

function Hotspot({
    hotspot,
    language,
    setCard,
    hovered,
    setHovered,
}: {
    hotspot: IHotspot;
    language: string;
    setCard: Function;
    hovered: boolean;
    setHovered: Function;
}): JSX.Element {
    const mediaImage = hotspot.image || placeholderImage;
    const mediaStyle = {
        backgroundImage: `url('${mediaImage}`,
    };
    const toggleHover = (): void => setHovered(true);
    const enhancedClass =
        !hovered && hotspot.location === 'mobility-experience'
            ? `building-hotspot building-hotspot--${hotspot.location} active`
            : `building-hotspot building-hotspot--${hotspot.location}`;

    return (
        <li id={hotspot.location} className={enhancedClass} role="article" onMouseEnter={toggleHover}>
            <div className="building-hotspot__icon" onClick={(): void => setCard(hotspot)}>
                {typeof hotspot.icon == 'string' && (
                    <img src={hotspot.icon} alt={`${hotspot.location} icon`} />
                )}
            </div>
            <div className="building-hotspot__card">
                <span className="building-hotspot__media" style={mediaStyle} />
                <div className="building-hotspot__content">
                    <h5 className="building-hotspot__content__title">{hotspot.title}</h5>
                    <span className="building-hotspot__content__blurb">{hotspot.description}</span>
                    <Link to={refactorUrl(hotspot.link)} className="building-hotspot__content__cta">
                        <span>{getTranslation('cta', language)}</span> {arrowIcon}
                    </Link>
                </div>
            </div>
        </li>
    );
}

function filterMouseLeave(e: React.MouseEvent): boolean {
    // REASON: Typescript is adamant that relatedTarget can never be Window, browser disagrees
    // @ts-ignore
    if (e.relatedTarget === document.defaultView) return true; //Weird bug prevention
    return !!e.relatedTarget && !e.currentTarget.contains(e.relatedTarget as Node);
}

export default function NewsOnHomePage({
    hotspots,
    language,
}: {
    hotspots: IHotspot[];
    language: string;
}): JSX.Element {
    const [hovered, setHovered] = useState(false);
    const buildingStyle = {
        backgroundImage: `url('${buildingImage}')`,
    };
    const initialLocationToDisplay = hotspots.filter(
        (hotspot: IHotspot): boolean => hotspot.location === 'mobility-experience',
    )[0];

    const [card, setCard] = useState(initialLocationToDisplay);

    return (
        <>
            <div
                className="building-hotspots"
                style={buildingStyle}
                onMouseLeave={(e: React.MouseEvent): boolean | void => filterMouseLeave(e) && setHovered(false)}
            >
                <ol className="building-hotspots__list">
                    {hotspots.map(
                        (hotspot: IHotspot): JSX.Element => (
                            <Hotspot key={hotspot.location} {...{ hotspot, language, setCard, hovered, setHovered }} />
                        ),
                    )}
                </ol>
            </div>
            <div className="building-hotspots__cards_container_mobile">
                {card && (
                    <div className="building-hotspot__card_mobile">
                        <LazyLoadImage
                            className="building-hotspot__media"
                            effect="opacity"
                            style={{ backgroundImage: `url('${card.image || placeholderImage}` }}
                        />
                        <div className="building-hotspot__content">
                            <h5 className="building-hotspot__content__title">{card.title}</h5>
                            <span className="building-hotspot__content__blurb">{card.description}</span>
                            <Link to={refactorUrl(card.link)} className="building-hotspot__content__cta">
                                {getTranslation('cta', language)} {arrowIcon}
                            </Link>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}
