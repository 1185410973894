import React, { Component } from 'react';
import Heading from '../../Content/Gutenberg/Heading';
import Paragraph from '../../Content/Gutenberg/Paragraph';
import Button from '../../Content/Gutenberg/Button';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

interface Props {
    full?: boolean;
    imgAlt: string;
    imgURL: string;
    innerBlocks: any;
}

interface State {}

export default class ImageCta extends Component<Props, State> {
    private constructor(props: Props) {
        super(props);
        this.state = {};
    }

    public render(): JSX.Element {
        const { full, imgAlt, imgURL, innerBlocks }: Props = this.props;

        let openingClasses = '';

        if (full) {
            openingClasses = 'column medium-20 small-full';
        } else {
            openingClasses = 'column large-8 medium-16 small-full';
        }

        return (
            <div className="imagecta-wrapper">
                <div className="row center">
                    <div className="column large-full">
                        <div className="row center innerrow">
                            <div className={openingClasses}>
                                <div className="imagecta-container">
                                    <div className="img-container">
                                        <LazyLoadImage className="img" effect="opacity" src={imgURL} alt={imgAlt} />
                                    </div>
                                </div>
                            </div>
                            <div className="column large-8 medium-16 small-full relative">
                                <div className="imagecta-innerblocks">
                                    {/* BEGIN: Gutenberg Blocks */}
                                    {Object.keys(innerBlocks).map(function(key: string): any {
                                        if (innerBlocks[key].blockName === 'core/heading') {
                                            return (
                                                <Heading
                                                    key={key}
                                                    innerContent={innerBlocks[key].innerContent}
                                                    noWrap={true}
                                                />
                                            );
                                        } else if (innerBlocks[key].blockName === 'core/paragraph') {
                                            return (
                                                <Paragraph
                                                    key={key}
                                                    innerContent={innerBlocks[key].innerContent}
                                                    noWrap={true}
                                                />
                                            );
                                        } else if (innerBlocks[key].blockName === 'core/button') {
                                            return <Button key={key} innerContent={innerBlocks[key].innerContent} />;
                                        }
                                        return '';
                                    })}
                                    {/* END: Gutenberg Blocks */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
