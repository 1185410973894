import React from 'react';
import { FormattedMessage } from 'react-intl';
import { fbq } from 'react-facebook-pixel';

export default function BuyTicketsMobile(): JSX.Element {
    const button: HTMLElement | null = document.querySelector('#buyticketsmobile__button');

    let scrollpos = 0;
    if (button) {
        let rect = button.getBoundingClientRect().top;
        let offset = button.offsetTop;
        const addButtonClass = (): void => button.classList.add('sticky');
        const removeButtonClass = (): void => button.classList.remove('sticky');

        window.addEventListener('scroll', function(): void {
            scrollpos = window.scrollY;
            if (scrollpos >= offset - rect) {
                addButtonClass();
            } else {
                removeButtonClass();
            }
        });
    }

    return (
        <div className="buyticketsmobile hide-for-medium-up clamped--bottom" id="buyticketsmobile__button">
            <a
                className="button buyticketsmobile__button"
                href="https://tickets.move.amsterdam/nl/tickets"
                target="_blank"
                rel="noopener noreferrer"
                onClick={(): void => {
                    fbq('track', 'ButtonBoekTickets');  
                }}
            >
                <FormattedMessage id="Header.OrderTickets" defaultMessage="Order Tickets" />
            </a>
        </div>
    );
}
