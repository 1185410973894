import React from 'react';
import App from './App';
import { CookiesProvider } from 'react-cookie';
// @ts-ignore
import 'intersection-observer';
import 'nodelist-foreach-polyfill';

export default function Root(): JSX.Element {
    return (
        <CookiesProvider>
            <App />
        </CookiesProvider>
    );
}
