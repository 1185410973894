import React, { Component } from 'react';
import { Cloudinary } from '../../../utils';

interface Props {
    full?: boolean;
    innerContent: any;
}

interface State {}

export default class Image extends Component<Props, State> {
    private constructor(props: Props) {
        super(props);
        this.state = {};
    }

    public render(): JSX.Element {
        const { full, innerContent }: Props = this.props;

        let openingClasses = '';

        if (full) {
            openingClasses = 'column medium-20 small-full';
        } else {
            openingClasses = 'column large-12 medium-16 small-full';
        }

        let formattedInnerContent = Cloudinary(innerContent);

        return (
            <div className="row center">
                <div className={openingClasses}>
                    <div dangerouslySetInnerHTML={{ __html: formattedInnerContent }} />
                </div>
            </div>
        );
    }
}
