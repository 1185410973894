import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Heading from '../../Content/Gutenberg/Heading';
import Paragraph from '../../Content/Gutenberg/Paragraph';
import Swiper from 'swiper';
import 'swiper/css/swiper.min.css';
import { FormattedMessage } from 'react-intl';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
require('dotenv').config();

interface Props {
    className: string;
    slideshowId: number;
    innerBlocks: any;
}

interface Slides {
    move_image: string;
    move_image_id: number;
    move_title: string;
    move_subtitle_before_dash: string;
    move_subtitle_after_dash: string;
    move_button_title: string;
    move_button_url: string;
    move_enable_popup: string;
    move_popup_image: string;
    move_popup_title: string;
    move_popup_text: string;
}

interface State {
    slides: Slides[];
    showModal: boolean;
}

export default class Slideshow extends Component<Props, State> {
    private constructor(props: Props) {
        super(props);
        this.state = {
            slides: [],
            showModal: false,
        };
    }

    private getAllSlides = async (): Promise<void> => {
        if (this.props.slideshowId) {
            const baseURL = process.env.REACT_APP_API_URL;
            let res = await axios.get(`${baseURL}/wp/v2/move-slideshow/${this.props.slideshowId}`);
            let { data } = await res;
            this.setState({ slides: data.slides[0] });
        }
    };

    private toggleModal = async (): Promise<void> => {
        // assign element to targetElement
        const targetElement = document.querySelector('#slidePopup-container') as HTMLElement;

        // in the callback of the setState function we check whether the popup is showing or not, if so, prevent scrolling on body, if not, enable body scrolling again
        this.setState(
            {
                showModal: !this.state.showModal,
            },
            (): void => {
                if (this.state.showModal) {
                    disableBodyScroll(targetElement);
                } else {
                    enableBodyScroll(targetElement);
                }
            },
        );
    };

    public closePopupOnOutsideClick = (event: any): void => {
        // Assign popup element to variable
        let popup = document.getElementById('slidePopup') as HTMLElement;
        if (popup) {
            // Assign target to variable
            let target = event.target as HTMLElement;
            // If the popup is visible, the variable will be set to true
            let visible = popup.classList.contains('display-block');
            if (visible) {
                // When the user clicks outside the popup
                if (target.classList.contains('modal')) {
                    // Toggle the show/hide function of the popup
                    this.toggleModal();
                }
            }
        }
    };

    public componentDidMount = async (): Promise<void> => {
        const $this = this;
        let interval: any;

        await this.getAllSlides();

        let slides = document.querySelectorAll(`#slideshow-${$this.props.slideshowId} .swiper-slide`) as any;
        const sliderEl: HTMLElement | null = document.querySelector(`#slideshow-${$this.props.slideshowId}`);

        if (slides.length > 1) {
            const sliderSwiper = new Swiper(`#slideshow-${this.props.slideshowId}`, {
                slidesPerView: 1,
                direction: 'vertical',
                speed: 1000,
                loop: true,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                    renderBullet: function(index: number, className: string): string {
                        return '<span class="' + className + '">' + (index + 1) + '</span>';
                    },
                },
            });

            const duration: number = process.env.REACT_APP_SLIDER_DURATION as any;
            const observer = new IntersectionObserver(
                (entries: IntersectionObserverEntry[]): void => {
                    entries.forEach((entry: any): void => {
                        if (0.5 <= entry.intersectionRatio) {
                            interval = setInterval((): void => {
                                sliderSwiper.slideNext();
                            }, duration);
                        } else {
                            clearInterval(interval);
                        }
                    });
                },
                {
                    threshold: 0.5,
                },
            );

            if (sliderEl) {
                observer.observe(sliderEl);
            }
        }
        window.addEventListener('click', this.closePopupOnOutsideClick);
        window.addEventListener('touchstart', this.closePopupOnOutsideClick);
    };

    public render(): JSX.Element {
        const { slides }: State = this.state;
        const { innerBlocks }: Props = this.props;

        return (
            <div>
                <div
                    className={'row width-100 slideshow-content ' + (this.props.className ? this.props.className : '')}
                >
                    <div className="column large-15 medium-full small-full">
                        <div className="swiper-container-slideshow" id={`slideshow-${this.props.slideshowId}`}>
                            <div className="swiper-wrapper">
                                {slides.map(
                                    (slide: Slides, index: number): JSX.Element => {
                                        const enablePopup = slide.move_enable_popup;
                                        return (
                                            <div className="swiper-slide" key={index}>
                                                <LazyLoadImage
                                                    src={slide.move_image}
                                                    effect="opacity"
                                                    alt={slide.move_title}
                                                />
                                                <div>
                                                    <div>
                                                        <h2>{slide.move_title}</h2>
                                                    </div>
                                                    <div className="row slide-description-pop">
                                                        <div className="column large-16 small-full align-left">
                                                            {slide.move_subtitle_before_dash &&
                                                                (slide.move_subtitle_after_dash && (
                                                                    <div className="description">
                                                                        {slide.move_subtitle_before_dash} —
                                                                        <span> {slide.move_subtitle_after_dash}</span>
                                                                    </div>
                                                                ))}
                                                        </div>
                                                        <div className="column large-8 small-full align-right">
                                                            {enablePopup === 'on' && (
                                                                <button onClick={this.toggleModal} className="button">
                                                                    {slide.move_button_title}
                                                                </button>
                                                            )}
                                                            {enablePopup === 'off' &&
                                                                (slide.move_button_url && slide.move_button_title && (
                                                                    <Link
                                                                        to={slide.move_button_url}
                                                                        className={
                                                                            'button ' +
                                                                            (window.location.pathname !== '/'
                                                                                ? 'button-white'
                                                                                : '')
                                                                        }
                                                                    >
                                                                        {slide.move_button_title}
                                                                    </Link>
                                                                ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    },
                                )}
                            </div>
                            <div className="swiper-pagination"></div>
                        </div>
                    </div>
                    <div className="column large-9 medium-full small-full">
                        <div className="row center">
                            <div className="column large-full medium-18 small-full">
                                {/* BEGIN: Gutenberg Blocks */}
                                {Object.keys(innerBlocks).map(function(key: string): any {
                                    if (innerBlocks[key].blockName === 'core/heading') {
                                        return (
                                            <Heading
                                                key={key}
                                                innerContent={innerBlocks[key].innerContent}
                                                noWrap={true}
                                            />
                                        );
                                    } else if (innerBlocks[key].blockName === 'core/paragraph') {
                                        return (
                                            <Paragraph
                                                key={key}
                                                innerContent={innerBlocks[key].innerContent}
                                                noWrap={true}
                                            />
                                        );
                                    }
                                    return '';
                                })}
                                {/* END: Gutenberg Blocks */}
                            </div>
                        </div>
                    </div>
                </div>
                {slides.map(
                    (slide: Slides, index: number): JSX.Element => {
                        const enablePopup = slide.move_enable_popup;
                        return (
                            <Fragment key={index}>
                                {enablePopup === 'on' && (
                                    <div
                                        id="slidePopup"
                                        className={`modal ${this.state.showModal ? 'display-block' : 'display-none'}`}
                                    >
                                        <div className="modal-wrapper">
                                            <div className="modal-container" id="slidePopup-container">
                                                <div className="modal-header">
                                                    <span className="modal-close" onClick={this.toggleModal}>
                                                        &#10005;
                                                    </span>
                                                    <LazyLoadImage
                                                        src={slide.move_popup_image}
                                                        effect="opacity"
                                                        alt={slide.move_popup_title}
                                                    />
                                                </div>
                                                <div className="modal-content">
                                                    <div className="row">
                                                        <div className="column small-full">
                                                            <h3>{slide.move_popup_title}</h3>
                                                        </div>
                                                        <div className="column large-16 medium-full small-full">
                                                            <div
                                                                dangerouslySetInnerHTML={{
                                                                    __html: slide.move_popup_text,
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="column large-8 medium-full small-full">
                                                            <a
                                                                className="button"
                                                                href="https://tickets.move.amsterdam/nl/tickets"
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                <FormattedMessage
                                                                    id="Header.OrderTickets"
                                                                    defaultMessage="Order Tickets"
                                                                />
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </Fragment>
                        );
                    },
                )}
            </div>
        );
    }
}
