import React from 'react';

import { FormattedMessage } from 'react-intl';
import Helmet from 'react-helmet';

//fortawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';

import useNewsPost from '../Pages/useNewsPost';
import GutenbergBlocks from '../Content/GutenbergBlocks';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

interface Props {
    postId: number;
    link: string;
    language: string;
}

export default function NewPage(props: Props): JSX.Element {
    const { postId, link, language } = props;
    const newsPost = useNewsPost(postId, language);

    if (!newsPost) {
        return <></>;
    }

    const {
        title,
        excerpt,
        category,
        featuredImageFull,
        blocks,
        yoastMeta,
        acf,
        postdate,
        author,
        dateGmt,
        modifiedGmt,
    } = newsPost;
    const date = new Date(postdate);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    let locale = 'en' === language ? 'en-EN' : 'nl-NL';
    const dateString = date.toLocaleDateString(locale, options);

    const yoastMetaValues: any = [];
    yoastMeta.forEach(function(element: any): void {
        if ('description' === element.name || 'description' === element.property) {
            yoastMetaValues['description'] = element.content;
        } else if ('og:title' === element.name || 'og:title' === element.property) {
            yoastMetaValues['ogTitle'] = element.content;
        } else if ('og:site_name' === element.name || 'og:site_name' === element.property) {
            yoastMetaValues['ogSiteName'] = element.content;
        } else if ('twitter:card' === element.name || 'twitter:card' === element.property) {
            yoastMetaValues['twitterCard'] = element.content;
        } else if ('twitter:description' === element.name || 'twitter:description' === element.property) {
            yoastMetaValues['twitterDescription'] = element.content;
        } else if ('twitter:title' === element.name || 'twitter:title' === element.property) {
            yoastMetaValues['twitterTitle'] = element.content;
        } else if ('og:image' === element.name || 'og:image' === element.property) {
            yoastMetaValues['ogImage'] = element.content;
        } else if ('og:image:width' === element.name || 'og:image:width' === element.property) {
            yoastMetaValues['ogImageWidth'] = element.content;
        } else if ('og:image:height' === element.name || 'og:image:height' === element.property) {
            yoastMetaValues['ogImageHeight'] = element.content;
        }
    });

    const facebook = 'https://www.facebook.com/movemobilityexperience/';
    const instagram = 'https://www.instagram.com/movemobilityexperience/';

    let structuredDescription = '';
    if (typeof yoastMetaValues['description'] !== 'undefined') {
        structuredDescription = yoastMetaValues['description'];
    }

    const structuredDataArticle = `{
            "@context": "https://schema.org",
            "@type": "Article",
            "mainEntityOfPage": {
                "@type": "WebPage",
                "@id": "${link}"
            },
            "headline": "${yoastMetaValues.ogTitle}",
            "description": "${structuredDescription}",
            "image": {
                "@type": "ImageObject",
                "url": "${featuredImageFull.url}",
                "width": "${featuredImageFull.width}",
                "height": "${featuredImageFull.height}"
            },
            "author": {
                "@type": "Organization",
                "name": "${author.first_name} ${author.last_name}"
            },
            "publisher": {
                "@type": "Organization",
                "name": "Move",
                "logo": {
                    "@type": "ImageObject",
                    "image": "https://move.amsterdam/assets/images/move.png",
                    "width": 825,
                    "height": 227
              }
            },
            "datePublished": "${dateGmt}",
            "dateModified": "${modifiedGmt}"
        }`;

    return (
        <>
            <Helmet>
                <title>{yoastMetaValues.ogTitle}</title>
                {yoastMetaValues.description && <meta name="description" content={yoastMetaValues.description} />}
                {yoastMetaValues.ogTitle && <meta property="og:title" content={yoastMetaValues.ogTitle} />}
                {yoastMetaValues.description && (
                    <meta property="og:description" content={yoastMetaValues.description} />
                )}
                <meta property="og:url" content={link} />
                {yoastMetaValues.ogSiteName && <meta property="og:site_name" content={yoastMetaValues.ogSiteName} />}
                {yoastMetaValues.ogImage && <meta property="og:image" content={yoastMetaValues.ogImage} />}
                {yoastMetaValues.ogImageWidth && (
                    <meta property="og:image:width" content={yoastMetaValues.ogImageWidth} />
                )}
                {yoastMetaValues.ogImageHeight && (
                    <meta property="og:image:height" content={yoastMetaValues.ogImageHeight} />
                )}
                {yoastMetaValues.twitterCard && <meta name="twitter:card" content={yoastMetaValues.twitterCard} />}
                {yoastMetaValues.twitterDescription && (
                    <meta name="twitter:description" content={yoastMetaValues.twitterDescription} />
                )}
                {yoastMetaValues.twitterTitle && <meta name="twitter:title" content={yoastMetaValues.twitterTitle} />}
                {yoastMetaValues.ogImage && <meta name="twitter:image" content={yoastMetaValues.ogImage} />}
                <meta name="twitter:card" content="summary_large_image" />
                <script type="application/ld+json">{structuredDataArticle}</script>
            </Helmet>
            <div className="content-page single-post">
                <div className="row">
                    <div className="column content-page-header-container">
                        <div className="column medium-12 small-full title-container">
                            <h1>{title}</h1>
                        </div>
                        <div className="column medium-12 small-full introduction-container">
                            <h5 className="subheading">{acf.subtitle}</h5>
                            <div className="introduction" dangerouslySetInnerHTML={{ __html: excerpt }} />
                        </div>
                    </div>
                </div>

                <div className="row width-100 center">
                    <div className="column medium-22 small-full stretched-image">
                        <LazyLoadImage
                            src={featuredImageFull.url}
                            effect="opacity"
                            width={featuredImageFull.width}
                            height={featuredImageFull.height}
                            alt={featuredImageFull.alt}
                        />
                    </div>
                </div>

                <div className="row width-100 center">
                    <div className="column medium-22 small-full">
                        <div className="row width-100">
                            <div className="column large-6 medium-full small-full">
                                <div className="post-sidebar">
                                    <div className="row">
                                        <p className="column large-full small-12">
                                            <FormattedMessage
                                                tagName="strong"
                                                id="News.author"
                                                defaultMessage="Author"
                                            />
                                            <br />
                                            {author.first_name} {author.last_name}
                                        </p>
                                        <p className="column large-full small-12">
                                            <FormattedMessage
                                                tagName="strong"
                                                id="News.category"
                                                defaultMessage="Category"
                                            />
                                            <br />
                                            {category}
                                        </p>
                                        <p className="column large-full small-12">
                                            <FormattedMessage tagName="strong" id="News.date" defaultMessage="Date" />
                                            <br />
                                            {dateString}
                                        </p>
                                        <p className="column large-full small-12 socials">
                                            <FormattedMessage tagName="strong" id="News.share" defaultMessage="Share" />
                                            <br />
                                            <a
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="facebook"
                                                href={facebook}
                                            >
                                                <FontAwesomeIcon className="fa-fw" icon={faFacebookF} />
                                            </a>
                                            <a
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="instagram"
                                                href={instagram}
                                            >
                                                <FontAwesomeIcon className="fa-fw" icon={faInstagram} />
                                            </a>
                                        </p>
                                        {acf.download && (
                                            <div className="column large-full small-12">
                                                <div className="button button-black">
                                                    <a href={acf.download}>Download Media Kit</a>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="column large-18  medium-full small-full" id="content">
                                <GutenbergBlocks language={language} blocks={blocks} full={true} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
